var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((Object.keys(_vm.myCompany.identity.socialNetworks)),function(social){return _c('v-card',{key:social.objectId,staticClass:"my-5 pa-3"},[_c('v-row',{attrs:{"align":"stretch"}},[_c('v-col',{attrs:{"cols":"9","md":"9 h4-content mt-0"}},[_c('h4',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-3",attrs:{"size":"40","color":_vm.colors[social]}},[_vm._v(" mdi-"+_vm._s(social.toLowerCase())+" ")]),_vm._v(" "+_vm._s(social[0].toUpperCase() + social.slice(1))+" ")],1),_c('span')]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"3","md":"3","align-self":"center"}},[_c('v-switch',{attrs:{"label":_vm.$vuetify.breakpoint.mdAndUp
              ? _vm.$t('settings.connectionSettings.activate')
              : ''},model:{value:(_vm.myCompany.identity.socialNetworks[social].active),callback:function ($$v) {_vm.$set(_vm.myCompany.identity.socialNetworks[social], "active", $$v)},expression:"myCompany.identity.socialNetworks[social].active"}})],1),_c('v-col',{attrs:{"cols":"12","align-self":"center"}},[_c('v-text-field',{class:{
            empty:
              _vm.myCompany.identity.socialNetworks[social].active &&
              !_vm.myCompany.identity.socialNetworks[social].url
          },attrs:{"label":"Url","outlined":""},model:{value:(_vm.myCompany.identity.socialNetworks[social].url),callback:function ($$v) {_vm.$set(_vm.myCompany.identity.socialNetworks[social], "url", $$v)},expression:"myCompany.identity.socialNetworks[social].url"}})],1)],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }