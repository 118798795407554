export const PREMIUM_MODULES = {
  VIP: 'vip',
}

export function havePremiumModule(company, moduleName) {
  if (!company.booker.modules) {
    return false
  }

  if (!company.booker.modules[moduleName]) {
    return false
  }

  return true
}
