import { render, staticRenderFns } from "./linkShopsToGeo.vue?vue&type=template&id=fdc68a92&scoped=true&"
import script from "./linkShopsToGeo.vue?vue&type=script&lang=js&"
export * from "./linkShopsToGeo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdc68a92",
  null
  
)

export default component.exports