<template>
  <div>
    <v-tabs v-model="legalTab">
      <v-tab
        v-for="(value, index) in indexTabLegal"
        :key="'legal-index-' + index"
      >
        <h3 class="legal-index-h3" :class="{ 'red--text': value.isEmpty }">
          {{ value.title }}
        </h3>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="legalTab">
      <v-tab-item>
        <multilang-editor
          v-model="myCompany.booker.textLegals.rgpd"
          height="65vh"
        />

        <v-btn text @click="myCompany.booker.textLegals.rgpd = ourLegals.rgpd">
          {{ $t('settings.useOurText') }}
        </v-btn>
      </v-tab-item>
      <v-tab-item>
        <multilang-editor
          v-model="myCompany.booker.textLegals.mentions"
          height="65vh"
        />
      </v-tab-item>
      <v-tab-item>
        <multilang-editor
          v-model="myCompany.booker.textLegals.cgv"
          height="65vh"
        />
        <v-btn text @click="myCompany.booker.textLegals.cgv = ourLegals.cgv">
          {{ $t('settings.useOurText') }}
        </v-btn>
      </v-tab-item>
      <v-tab-item>
        <multilang-editor
          v-model="myCompany.booker.textLegals.cgu"
          height="65vh"
        />
        <v-btn text @click="myCompany.booker.textLegals.cgu = ourLegals.cgu">
          {{ $t('settings.useOurText') }}
        </v-btn>
      </v-tab-item>
      <v-tab-item>
        <multilang-editor
          v-model="myCompany.booker.textLegals.cookies"
          height="65vh"
        />

        <v-btn
          text
          @click="myCompany.booker.textLegals.cookies = ourLegals.cookies"
        >
          {{ $t('settings.useOurText') }}
        </v-btn>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Vue from 'vue'
import MultilangEditor from '../helpers/multilangEditor'
import legals from '@/helpers/legals'

export default {
  name: 'legals',
  components: { MultilangEditor },
  props: {
    myCompany: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      legalTab: 0,
      ourLegals: {}
    }
  },
  computed: {
    indexTabLegal() {
      return [
        {
          title: this.$t('other.privacy'),
          isEmpty: !this.myCompany.booker.textLegals.rgpd[this.$i18n.locale]
        },
        {
          title: this.$t('other.legalNotice'),
          isEmpty: !this.myCompany.booker.textLegals.mentions[this.$i18n.locale]
        },
        {
          title: this.$t('other.gsc'),
          isEmpty: !this.myCompany.booker.textLegals.cgv[this.$i18n.locale]
        },
        {
          title: this.$t('other.gcu'),
          isEmpty: !this.myCompany.booker.textLegals.cgu[this.$i18n.locale]
        },
        {
          title: this.$t('other.cookies'),
          isEmpty: !this.myCompany.booker.textLegals.cookies[this.$i18n.locale]
        }
      ]
    }
  },
  created() {
    this.initLegals()
  },
  mounted() {
    this.ourLegals = legals.getLegals()
  },
  methods: {
    initLegals() {
      if (!this.myCompany.booker) {
        Vue.set(this.myCompany, 'booker', {})
      }

      if (!this.myCompany.booker.textLegals) {
        Vue.set(this.myCompany.booker, 'textLegals', {})
      }

      if (!this.myCompany.booker.textLegals.rgpd) {
        Vue.set(this.myCompany.booker.textLegals, 'rgpd', {})
      }
      if (!this.myCompany.booker.textLegals.mentions) {
        Vue.set(this.myCompany.booker.textLegals, 'mentions', {})
      }
      if (!this.myCompany.booker.textLegals.cgv) {
        Vue.set(this.myCompany.booker.textLegals, 'cgv', {})
      }
      if (!this.myCompany.booker.textLegals.cgu) {
        Vue.set(this.myCompany.booker.textLegals, 'cgu', {})
      }
      if (!this.myCompany.booker.textLegals.cookies) {
        Vue.set(this.myCompany.booker.textLegals, 'cookies', {})
      }
    }
  }
}
</script>

<style scoped>
.legal-index-h3 {
  text-transform: none;
  letter-spacing: normal;
}
</style>
