<template>
  <div>
    <v-row class="ma-0 mb-10" align="center">
      <v-col cols="1" md="3">
        <v-icon color="dark" size="40" @click="$emit('back')">
          mdi-keyboard-backspace
        </v-icon>
      </v-col>
      <v-col
        cols="11"
        md="6"
        class="text-uppercase text-center font-weight-bold"
        style="font-size: 1.2rem"
      >
        {{ $t('shops.manageGeoGroups') }}
      </v-col>
      <v-col cols="12" md="3" class="text-end">
        <v-btn color="primary" @click="createGeo">{{ $t('icons.new') }}</v-btn>
      </v-col>
    </v-row>

    <template v-if="geoGroupments.length">
      <v-row class="mt-5 mx-auto" style="max-width: 700px">
        <v-col cols="6">{{ $t('other.name') }}</v-col>
        <v-col cols="4" class="text-center">
          {{ $t('geoGroup.numberShop') }}
        </v-col>
      </v-row>

      <v-divider class="mx-auto" style="max-width: 700px" />

      <div class="mt-2 py-3" style="width: 700px; margin: auto">
        <v-row
          v-for="geoGroupment in geoGroupments"
          :key="geoGroupment.id"
          class="ma-4 line elevation-1"
          style="max-width: 700px"
        >
          <v-col cols="6" class="font-weight-bold" align-self="center">
            {{ controli18n($i18n.locale, geoGroupment.name) }}
          </v-col>
          <v-col
            cols="4"
            class="text-center d-none d-md-inline-block"
            align-self="center"
          >
            {{ geoGroupment.shops ? geoGroupment.shops.length : 0 }}
          </v-col>
          <v-col
            cols="6"
            md="2"
            class="d-flex justify-space-around text-right"
            align-self="center"
          >
            <v-icon color="blue" @click="linkShops(geoGroupment)"
              >mdi-store</v-icon
            >
            <v-icon color="green" @click="editGeo(geoGroupment)"
              >mdi-pencil</v-icon
            >
            <v-icon color="red darken-4" @click="deleteGeo(geoGroupment)">
              mdi-delete
            </v-icon>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-else>
      <div class="text-center">
        <v-alert outlined>
          {{ $t('geoGroup.haveNotCreatedYet') }}
        </v-alert>
      </div>
    </template>

    <!--  DIALOG LINK SHOPS  -->
    <v-dialog v-model="linkShopsDialog" width="950">
      <link-shops-to-geo
        :link-shops-geo="geoGroupment"
        :online-shops="onlineShops"
        @close="linkShopsDialog = false"
        @update="$emit('update')"
      />
    </v-dialog>

    <!--  DIALOG EDIT GEOGROUPMENT  -->
    <v-dialog v-model="editGeoDialog" width="950">
      <v-card v-if="editGeoDialog">
        <div>
          <v-card-title>{{ $t('geoGroup.editGeoGroup') }}</v-card-title>
          <v-divider />
        </div>

        <v-card-text class="px-10">
          <v-form>
            <div>
              <div>
                <v-switch
                  v-model="edit.useColor"
                  label="Utiliser une couleur de fond"
                />
              </div>
              <div v-if="!edit.useColor">
                <images-selector
                  v-model="edit.image"
                  single
                  :size="size"
                  :updateData="updateData"
                  :categories="images"
                  :imageSizes="sizes"
                />
                <file-explorer
                  :images="images"
                  :images-already-selected="edit.image"
                  :loading="loadingBanque"
                  class="mt-5"
                  single
                  @uploadImages="uploadImages"
                />
              </div>
              <div v-else>
                <v-color-picker
                  v-model="edit.backgroundColor"
                  :swatches="swatches"
                  show-swatches
                  mode="hexa"
                  class="ma-auto"
                />
              </div>
            </div>
            <div class="mt-8 mb-6">
              <strong>{{ $t('geoGroup.generalInformation') }}</strong>
            </div>
            <v-divider />
            <multilang-text-field
              v-model="edit.name"
              :placeholder="$t('geoGroup.geoGroupName')"
              class="mb-6"
            />
            <v-divider />
            <multilang-textarea
              v-model="edit.description.short"
              :placeholder="$t('geoGroup.shortDescription')"
              class="mb-6"
            />
          </v-form>

          <v-overlay
            class="d-flex justify-center align-center"
            v-if="saveLoading"
            absolute
            color="grey"
          >
            <v-progress-circular indeterminate />
          </v-overlay>
        </v-card-text>

        <div>
          <v-divider />
          <v-card-actions>
            <v-btn text @click="editGeoDialog = false">
              {{ $t('icons.close') }}
            </v-btn>
            <v-spacer />
            <v-btn type="button" @click="save" color="success" tile>
              <v-icon>mdi-check-bold</v-icon>
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <!--  DIALOG CREATE GEOGROUPMENT  -->
    <v-dialog v-model="createGeoDialog" width="950">
      <v-card v-if="createGeoDialog">
        <div>
          <v-card-title>{{ $t('geoGroup.createGeoGroup') }}</v-card-title>
          <v-divider />
        </div>

        <v-card-text class="px-10">
          <v-form>
            <div>
              <div>
                <v-switch
                  v-model="edit.useColor"
                  label="Utiliser une couleur de fond"
                />
              </div>
              <div v-if="!edit.useColor">
                <images-selector
                  v-model="edit.image"
                  single
                  :size="size"
                  :updateData="updateData"
                  :categories="images"
                  :imageSizes="sizes"
                />
                <file-explorer
                  :images="images"
                  :images-already-selected="edit.image"
                  :loading="loadingBanque"
                  class="mt-5"
                  single
                  @uploadImages="uploadImages"
                />
              </div>
              <div v-else>
                <v-color-picker
                  v-model="edit.backgroundColor"
                  :swatches="swatches"
                  show-swatches
                  mode="hexa"
                  class="ma-auto"
                />
              </div>
              <!--              <div>-->
              <!--                <images-selector-->
              <!--                  v-model="edit.image"-->
              <!--                  single-->
              <!--                  :size="size"-->
              <!--                  :updateData="updateData"-->
              <!--                  :categories="images"-->
              <!--                  :imageSizes="sizes"-->
              <!--                />-->
              <!--                <file-explorer-->
              <!--                  :images="images"-->
              <!--                  :images-already-selected="edit.image"-->
              <!--                  :loading="loadingBanque"-->
              <!--                  class="mt-5"-->
              <!--                  single-->
              <!--                  @uploadImages="uploadImages"-->
              <!--                />-->
              <!--              </div>-->
            </div>
            <div class="mt-8 mb-6">
              <strong>{{ $t('geoGroup.generalInformation') }}</strong>
            </div>
            <v-divider />
            <multilang-text-field
              v-model="edit.name"
              :placeholder="$t('geoGroup.geoGroupName')"
              class="mb-6"
            />
          </v-form>

          <v-overlay
            class="d-flex justify-center align-center"
            v-if="saveLoading"
            absolute
            color="grey"
          >
            <v-progress-circular indeterminate />
          </v-overlay>
        </v-card-text>

        <div>
          <v-divider />

          <v-card-actions>
            <v-btn text @click="createGeoDialog = false">
              {{ $t('icons.close') }}
            </v-btn>
            <v-spacer />
            <v-btn type="button" @click="create" color="success" tile>
              <v-icon>mdi-check-bold</v-icon>
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { controli18n } from '../../../helpers/langs'
import icones from '../../../helpers/icones'
import swal from 'sweetalert'
import ImagesSelector from '../../helpers/imagesSelector'
import MultilangTextField from '../../helpers/multilangTextField'
// import MultilangTextarea from '../../helpers/multilangTextarea'
import uploadsHelpers from '@/helpers/uploads'
import FileExplorer from '../../images/fileExplorer'
import banqueImages from '../../../helpers/banqueImages'
import LinkShopsToGeo from './linkShopsToGeo'
import imageSizes from '@/helpers/imageSizes'
import MultilangTextarea from '@/components/helpers/multilangTextarea'

export default {
  components: {
    MultilangTextarea,
    LinkShopsToGeo,
    FileExplorer,
    // MultilangTextarea,
    MultilangTextField,
    ImagesSelector,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    geoGroupments: {
      type: Array,
      default: () => null,
    },
  },
  data: () => ({
    linkShopsDialog: false,
    editGeoDialog: false,
    createGeoDialog: false,
    loading: false,
    saveLoading: false,
    shopsGrps: [],
    edit: {},
    geoGroupment: null,
    images: [],
    updateData: [],
    loadingBanque: false,
    swatches: [
      ['#FFFFFF', '#000000', '#999999'],
      ['#FFFF00', '#FF0000', '#0000FF'],
      ['#00FF00', '#FF7F00', '#50026A'],
      ['#fa2c96', '#D4AF37', '#7B3F00'],
    ],
  }),
  computed: {
    onlineShops() {
      return this.$store.getters['shop/fusionShop']({
        active: true,
        isOnline: true,
        onLineType: [],
      })
    },
    nickname() {
      return this.$store.state.auth.company.nickname
    },
    $icones() {
      return icones().getIcones()
    },
    size() {
      return this.$store.state.auth.company &&
        this.$store.state.auth.company.themes &&
        this.$store.state.auth.company.themes.multiShop &&
        this.$store.state.auth.company.themes.multiShop.id
        ? imageSizes.geogroups[
            this.$store.state.auth.company.themes.multiShop.id
          ]
        : imageSizes.geogroups.default
    },
    sizes() {
      return imageSizes.geogroups
    },
  },
  methods: {
    controli18n,
    linkShops(geo) {
      this.geoGroupment = geo
      this.linkShopsDialog = true
    },
    shopsFiltered(groupe) {
      const tab = []

      this.onlineShops.map((shop) => {
        if (shop.grp.name === groupe) {
          tab.push(shop)
        }
      })

      return tab
    },
    isOnline(shop) {
      let bool = false

      if (shop.geoGroupment && shop.geoGroupment.id === this.geoGroupment.id) {
        bool = true
      }
      return bool
    },
    editGeo(geo) {
      this.geoGroupment = geo

      this.edit = {
        name: this.geoGroupment.name,
        description: this.geoGroupment.description,
        image: [],
        useColor: this.geoGroupment.useColor || false,
        backgroundColor: this.geoGroupment.backgroundColor || '#333333',
      }

      if (this.geoGroupment.image) {
        this.edit.image[0] = {
          url: this.geoGroupment.image.src,
        }
      }

      this.editGeoDialog = true
    },
    createGeo() {
      this.edit = {
        name: null,
        image: [],
        description: { short: {}, long: {} },
        useColor: false,
        backgroundColor: '#333333',
      }
      this.createGeoDialog = true
    },
    uploadImages(images) {
      images.forEach((img) => {
        if (!this.edit.image.find((i) => i.url === img.url)) {
          this.edit.image = [img]
        }
      })
      this.updateData = this.edit.image
    },

    async deleteGeo(geo) {
      swal({
        title:
          this.$t('geoGroup.deleteGeoGroup') +
          this.controli18n(this.$i18n.locale, geo.name) +
          ' ?',
        buttons: [this.$t('icons.cancel'), this.$t('icons.delete')],
      }).then(async (willDelete) => {
        if (willDelete) {
          try {
            await this.$store.dispatch('geogroupments/deleteGeogroupment', {
              id: geo.id,
            })

            this.$emit('update')
          } catch (err) {
            console.log('err:>>', err)
          }
        }
      })
    },
    async save() {
      this.saveLoading = true

      let imagesUrl = []

      try {
        for (let i = 0; i < this.edit.image.length; i++) {
          if (this.edit.image[i].new) {
            const image = this.edit.image[i].blob
            const fileName = uploadsHelpers.randomName()

            const directory = this.edit.image[i].category
              ? 'imagekit/eden/bank/geogrp/' + this.edit.image[i].category
              : 'imagekit/eden/geogrp'

            const imageUrl = await uploadsHelpers.uploadSingleBase64(
              image,
              directory,
              fileName,
              this.nickname
            )

            imagesUrl.push({
              url: imageUrl,
              description: this.edit.image[i].description,
              alt: this.edit.image[i].alt,
            })
          } else {
            imagesUrl.push(this.edit.image[i])
          }
        }
      } catch (err) {
        console.log('err:>>', err)
        return {
          error: true,
          message: this.$t('settings.anErrorOccuredImages'),
        }
      }

      const result = await this.$store.dispatch(
        'geogroupments/updateGeogroupment',
        {
          geogroupment: this.geoGroupment,
          data: {
            name: this.edit.name,
            description: this.edit.description,
            useColor: this.edit.useColor || false,
            backgroundColor: this.edit.backgroundColor || '#333333',
            image: imagesUrl.length
              ? {
                  src: imagesUrl[0].url,
                }
              : {},
          },
        }
      )
      if (!result) {
        swal({
          title: this.$t('geoGroup.unableUpdateGeoGroup'),
          icon: 'error',
        })
      } else {
        await this.$store.dispatch('shop/getShops')
        this.$emit('update')
        this.editGeoDialog = false
      }

      this.saveLoading = false
    },
    async create() {
      this.saveLoading = true

      let imagesUrl = []

      try {
        for (let i = 0; i < this.edit.image.length; i++) {
          if (this.edit.image[i].new) {
            const image = this.edit.image[i].blob
            const fileName = uploadsHelpers.randomName()

            const imageUrl = await uploadsHelpers.uploadSingleBase64(
              image,
              'imagekit/eden/geogrp',
              fileName,
              this.nickname
            )

            imagesUrl.push({
              url: imageUrl,
              description: this.edit.image[i].description,
              alt: this.edit.image[i].alt,
            })
          } else {
            imagesUrl.push(this.edit.image[i])
          }
        }
      } catch (err) {
        console.log('err:>>', err)
        return {
          error: true,
          message: this.$t('settings.anErrorOccuredImages'),
        }
      }

      const result = await this.$store.dispatch(
        'geogroupments/createGeogroupment',
        {
          name: this.edit.name,
          description: this.edit.description,
          useColor: this.edit.useColor || false,
          backgroundColor:
            this.edit.backgroundColor && !this.edit.backgroundColor.hexa
              ? this.edit.backgroundColor
              : this.edit.backgroundColor.hexa
              ? this.edit.backgroundColor.hexa
              : '#333333',
          image: imagesUrl.length
            ? {
                src: imagesUrl[0].url,
              }
            : {},
        }
      )
      if (!result) {
        if (!result) {
          swal({
            title: this.$t('geoGroup.unableCreateGeoGroup'),
            icon: 'error',
          })
        }
      } else {
        await this.$store.dispatch('shop/getShops')
        this.$emit('update')
        this.createGeoDialog = false
      }

      this.saveLoading = false
    },
  },
  async mounted() {
    this.onlineShops.map((shop) => {
      if (!this.shopsGrps.includes(shop.grp.name)) {
        this.shopsGrps.push(shop.grp.name)
      }
    })

    let ourImages,
      yourImages = []
    this.loadingBanque = true
    await banqueImages.getGeogroupments().then((res) => {
      ourImages = res
    })
    await banqueImages.getThem(this.nickname, 'geogrp').then((res) => {
      yourImages = res
    })
    yourImages.forEach((y) => {
      if (ourImages.find((o) => o.title === y.title)) {
        const src = ourImages.find((o) => o.title === y.title).src
        src.push(...y.src)
      } else {
        ourImages.push(y)
      }
    })
    ourImages.sort((a, b) => {
      if (a.title < b.title) return -1
      else if (b.title < a.title) return 1
      return 0
    })
    this.images = ourImages
    this.loadingBanque = false
  },
}
</script>

<style scoped>
.line {
  border: 1px solid #333333;
  border-radius: 5px;
  background-color: #fff;
  height: 75px;
}

.scrollable {
  height: 55vh;
  width: 700px;
  overflow: auto;
  margin: auto;
}
</style>
