<template>
  <div>
    <v-btn-toggle
      v-model="myCompany.booker.payment.provider"
      mandatory
      class="mb-6"
    >
      <v-btn tile value="free">
        <v-icon left> mdi-cancel </v-icon>
        {{ $t('settings.paymentsSettings.deactivate') }}
      </v-btn>
      <v-btn tile value="payzen">
        <v-icon left> mdi-alpha-p-box </v-icon>
        Payzen
      </v-btn>
      <v-btn tile value="stripe">
        <v-icon left> mdi-alpha-s-circle </v-icon>
        Stripe
      </v-btn>
      <v-btn tile value="saferpay">
        <v-icon left> mdi-alpha-f-circle </v-icon>
        Saferpay
      </v-btn>
      <v-btn tile value="paypal">
        <v-icon left> mdi-alpha-y-circle </v-icon>
        Paypal
      </v-btn>
      <v-btn tile value="monetico">
        <v-icon left> mdi-alpha-m-circle </v-icon>
        Monetico
      </v-btn>
    </v-btn-toggle>

    <div>
      <div v-if="myCompany.booker.payment.provider === 'free'">
        <v-alert type="warning">
          {{ $t('settings.paymentsSettings.byChoosingThisPaymentMethod')
          }}<br /><br />
          {{ $t('settings.paymentsSettings.toEnablePayments') }}
        </v-alert>
      </div>

      <div v-if="myCompany.booker.payment.provider === 'payzen'">
        <div class="mb-8">
          Dans votre tableau de bord PayZen, vous devez ajouter une URL de
          notification de fin de paiement, qui pointe sur cette URL :<br />

          <div
            style="
              word-break: break-all;
              border: 1px solid #ccc;
              padding: 5px;
              border-radius: 4px;
              background-color: #eee;
              margin-top: 20px;
              font-family: sans-serif;
            "
          >
            {{ callbackURL }}
          </div>
        </div>
        <v-text-field
          v-model="myCompany.booker.payment.keys.publicKeyPayzen"
          outlined
          type="text"
          :label="$t('settings.paymentsSettings.publicKey')"
          :class="{ empty: !myCompany.booker.payment.keys.publicKeyPayzen }"
        />
        <v-text-field
          v-model="myCompany.booker.payment.keys.user"
          outlined
          type="text"
          :label="$t('settings.paymentsSettings.username')"
          :class="{ empty: !myCompany.booker.payment.keys.user }"
        />
        <v-text-field
          v-model="myCompany.booker.payment.keys.password"
          outlined
          :label="$t('settings.paymentsSettings.password')"
          :class="{ empty: !myCompany.booker.payment.keys.password }"
        />
        <v-text-field
          v-model="myCompany.booker.payment.keys.decryptKey"
          outlined
          type="text"
          :label="$t('settings.paymentsSettings.key', { key: 'HMAC-SHA-256' })"
          :class="{ empty: !myCompany.booker.payment.keys.decryptKey }"
        />
        <v-text-field
          v-model="myCompany.booker.payment.keys.baseURL"
          outlined
          type="text"
          label="URL PayZen"
          :class="{ empty: !myCompany.booker.payment.keys.baseURL }"
        />
        <v-switch
          v-model="myCompany.booker.payment.disableWebservice"
          label="Désactiver le mode webservice"
          inset
          v-show="false"
        />
        <v-switch
          v-model="enableWebservice"
          label="J'ai souscrit à l'option 'Automatisation du back office' comprenant la gestion du remboursement et la validation des paiements via l'API"
          inset
        />
        <v-alert color="red lighten-5" class="mt-6" v-if="enableWebservice">
          <p style="font-weight: bold" class="mb-0">
            <v-icon left color="red">mdi-alert</v-icon>
            En cochant cette case, assurez-vous de bien avoir souscrit à
            l'option
            <strong>Automatisation du back office</strong>.

            <br /><br />

            <span style="color: red; font-size: 1.5rem" class="my-2">
              Si cette case est cochée et que vous n'avez pas souscrit à cette
              option, les paiements NE seront PAS validés en automatique et les
              remboursements ne fonctionneront pas.
            </span>

            <br /><br />

            Si vous n'êtes pas sûr que votre contrat monétique inclus cette
            option, ou si vous n'avez pas accès à cette option, décochez la
            case.

            <br /><br />

            D'autres systèmes de paiements comme
            <strong>
              <a
                href="https://stripe.com/"
                target="_blank"
                style="color: #000; text-decoration: none"
              >
                Stripe <v-icon small>mdi-open-in-new</v-icon>
              </a>
            </strong>
            incluent par défaut cette option.
          </p>
        </v-alert>
      </div>

      <div v-else-if="myCompany.booker.payment.provider === 'stripe'">
        <div class="mb-8">
          Dans votre tableau de bord Stripe, vous devez ajouter une URL de
          callback, qui pointe sur cette URL :<br />

          <div
            style="
              word-break: break-all;
              border: 1px solid #ccc;
              padding: 5px;
              border-radius: 4px;
              background-color: #eee;
              margin-top: 20px;
              margin-bottom: 20px;
              font-family: sans-serif;
            "
          >
            {{ callbackURL }}
          </div>

          Ce callback doit écouter l'évenement
          <span
            style="
              display: inline-block;
              padding: 2px 5px;
              background-color: #eee;
              border: 1px solid #ccc;
              border-radius: 2px;
              font-family: sans-serif;
            "
            >payment_intent.succeeded</span
          >.
        </div>
        <v-text-field
          v-model="myCompany.booker.payment.keys.publicKeyStripe"
          outlined
          type="text"
          :label="$t('settings.paymentsSettings.publicKey')"
          :class="{ empty: !myCompany.booker.payment.keys.publicKeyStripe }"
        />
        <v-text-field
          v-model="myCompany.booker.payment.keys.privateKey"
          outlined
          :label="$t('settings.paymentsSettings.privateKey')"
          :class="{ empty: !myCompany.booker.payment.keys.privateKey }"
        />
      </div>

      <div v-if="myCompany.booker.payment.provider === 'saferpay'">
        <v-text-field
          v-model="myCompany.booker.payment.keys.customerSaferpay"
          outlined
          type="text"
          :label="$t('settings.paymentsSettings.clientId')"
          :class="{ empty: !myCompany.booker.payment.keys.customerSaferpay }"
        />
        <v-text-field
          v-model="myCompany.booker.payment.keys.terminalSaferpay"
          outlined
          type="text"
          :label="$t('settings.paymentsSettings.terminalId')"
          :class="{ empty: !myCompany.booker.payment.keys.terminalSaferpay }"
        />
        <v-text-field
          v-model="myCompany.booker.payment.keys.userSaferpay"
          outlined
          type="text"
          :label="$t('settings.paymentsSettings.username')"
          :class="{ empty: !myCompany.booker.payment.keys.userSaferpay }"
        />
        <v-text-field
          v-model="myCompany.booker.payment.keys.passwordSaferpay"
          outlined
          :label="$t('settings.paymentsSettings.password')"
          :class="{ empty: !myCompany.booker.payment.keys.passwordSaferpay }"
        />
        <v-text-field
          v-model="myCompany.booker.payment.keys.baseURLSaferpay"
          outlined
          type="text"
          label="URL SaferPay"
          :class="{ empty: !myCompany.booker.payment.keys.baseURLSaferpay }"
        />
      </div>

      <div v-if="myCompany.booker.payment.provider === 'paypal'">
        <v-text-field
          v-model="myCompany.booker.payment.keys.paypalAccountId"
          outlined
          type="text"
          :label="$t('settings.paymentsSettings.clientId')"
          :class="{ empty: !myCompany.booker.payment.keys.paypalAccountId }"
        />
        <v-text-field
          v-model="myCompany.booker.payment.keys.paypalPrivateKey"
          outlined
          type="text"
          :label="$t('settings.paymentsSettings.privateKey')"
          :class="{ empty: !myCompany.booker.payment.keys.paypalPrivateKey }"
        />
      </div>

      <div v-if="myCompany.booker.payment.provider === 'monetico'">
        <v-text-field
          v-model="myCompany.booker.payment.keys.tpeKeyMonetico"
          outlined
          type="text"
          label="Clé TPE"
          :class="{ empty: !myCompany.booker.payment.keys.tpeKeyMonetico }"
        />
        <v-text-field
          v-model="myCompany.booker.payment.keys.codeSociete"
          outlined
          type="text"
          label="Code société"
          :class="{ empty: !myCompany.booker.payment.keys.codeSociete }"
        />
        <v-text-field
          v-model="myCompany.booker.payment.keys.cleMac"
          outlined
          label="Clé MAC"
          :class="{ empty: !myCompany.booker.payment.keys.cleMac }"
        />
        <v-text-field
          v-model="myCompany.booker.payment.keys.moneticoBaseURL"
          outlined
          type="text"
          label="URL Monetico"
          :class="{ empty: !myCompany.booker.payment.keys.moneticoBaseURL }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'paiement',
  props: {
    myCompany: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      refreshCompany: true,
      refreshWebservice: true,
      enableWebservice: false,
    }
  },
  computed: {
    callbackURL() {
      return (
        process.env.VUE_APP_MKP_SERVICE_API +
        '/api/payment/callback/' +
        this.$store.state.auth.companyToken
      )
    },
  },
  created() {
    this.initPaiement()
  },
  watch: {
    enableWebservice() {
      if (!this.refreshWebservice) {
        this.refreshWebservice = true
        return
      }

      this.refreshCompany = false
      this.myCompany.booker.payment.disableWebservice = !this.enableWebservice
    },
    'myCompany.booker.payment.provider'(n, o) {
      if ((n === 'payzen' && o !== 'payzen') || n !== 'payzen') {
        this.refreshCompany = false
        this.myCompany.booker.payment.disableWebservice = true

        this.refreshWebservice = false
        this.enableWebservice = !this.myCompany.booker.payment.disableWebservice

        this.$forceUpdate()
      }

      if (n === 'monetico') {
        this.myCompany.booker.appointment.payment.active = false
      }
    },
    myCompany: {
      handler() {
        if (!this.refreshCompany) {
          this.refreshCompany = true
          return
        }

        if (this.myCompany.booker.payment.disableWebservice) {
          this.refreshCompany = false
          this.myCompany.booker.appointment.enableGiftMoneyRefund = false
          this.refreshCompany = false
          this.myCompany.booker.appointment.enableMoneyRefund = false
        }

        if (this.myCompany.booker.payment.provider !== 'payzen') {
          this.refreshCompany = false
          this.myCompany.booker.payment.disableWebservice = false
        }

        this.refreshWebservice = false
        this.enableWebservice = !this.myCompany.booker.payment.disableWebservice

        this.$forceUpdate()
      },
      deep: true,
    },
  },
  methods: {
    initPaiement() {
      if (!this.myCompany.booker) {
        Vue.set(this.myCompany, 'booker', {})
      }

      if (!this.myCompany.booker.payment) {
        Vue.set(this.myCompany.booker, 'payment', {
          active: false,
          bankInfo: {
            mode: 'test',
            system: '',
            certificat: '',
            idmarchand: null,
          },
          provider: 'free',
          keys: {
            publicKey: null,
            publicKeyPayzen: null,
            publicKeyStripe: null,

            privateKey: null,
            user: null,
            password: null,

            decryptKey: null,
            baseURL: null,
          },
        })
      }

      if (!this.myCompany.booker.payment.keys.customer) {
        this.$set(this.myCompany.booker.payment.keys, 'customer', '')
      }
      if (!this.myCompany.booker.payment.keys.terminal) {
        this.$set(this.myCompany.booker.payment.keys, 'terminal', '')
      }
      if (!this.myCompany.booker.payment.keys.user) {
        this.$set(this.myCompany.booker.payment.keys, 'user', '')
      }
      if (!this.myCompany.booker.payment.keys.password) {
        this.$set(this.myCompany.booker.payment.keys, 'password', '')
      }
      if (!this.myCompany.booker.payment.keys.baseURL) {
        this.$set(this.myCompany.booker.payment.keys, 'baseURL', '')
      }
      if (!this.myCompany.booker.payment.keys.username) {
        this.$set(this.myCompany.booker.payment.keys, 'username', '')
      }
      if (!this.myCompany.booker.payment.keys.password) {
        this.$set(this.myCompany.booker.payment.keys, 'password', '')
      }

      if (!this.myCompany.booker.payment.decryptKey) {
        this.myCompany.booker.payment.decryptKey = null
      }

      if (!this.myCompany.booker.payment.keys.baseURL) {
        this.myCompany.booker.payment.keys.baseURL = 'https://api.payzen.eu'
      }

      if (!this.myCompany.booker.payment.keys.customerSaferpay) {
        Vue.set(this.myCompany.booker.payment.keys, 'customerSaferpay', '')
      }
      if (!this.myCompany.booker.payment.keys.terminalSaferpay) {
        Vue.set(this.myCompany.booker.payment.keys, 'terminalSaferpay', '')
      }
      if (!this.myCompany.booker.payment.keys.userSaferpay) {
        Vue.set(this.myCompany.booker.payment.keys, 'userSaferpay', '')
      }
      if (!this.myCompany.booker.payment.keys.passwordSaferpay) {
        Vue.set(this.myCompany.booker.payment.keys, 'passwordSaferpay', '')
      }
      if (!this.myCompany.booker.payment.keys.baseURLSaferpay) {
        Vue.set(this.myCompany.booker.payment.keys, 'baseURLSaferpay', '')
      }
      if (!this.myCompany.booker.payment.keys.paypalAccountId) {
        Vue.set(this.myCompany.booker.payment.keys, 'paypalAccountId', '')
      }
      if (!this.myCompany.booker.payment.keys.paypalPrivateKey) {
        Vue.set(this.myCompany.booker.payment.keys, 'paypalPrivateKey', '')
      }
      if (
        typeof this.myCompany.booker.payment.disableWebservice === 'undefined'
      ) {
        Vue.set(this.myCompany.booker.payment, 'disableWebservice', true)
      }

      this.enableWebservice = !this.myCompany.booker.payment.disableWebservice
    },
  },
}
</script>

<style scoped></style>
