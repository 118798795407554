<template>
  <div style="position: relative">
    <div>
      <vue-editor
        class="text-editor"
        v-model="valueData"
        :style="{ height: height }"
        ref="qeditor"
      />
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'

export default {
  components: { VueEditor },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: { type: String, default: '' },
    height: {
      type: String,
      default: '400px',
    },
  },
  watch: {
    value() {
      if (this.valueData !== this.value) {
        this.valueData = this.value
      }
    },
    valueData() {
      if (this.valueData !== this.value) {
        this.$emit('input', this.valueData)
      }
    },
  },
  data() {
    return {
      valueData: this.value,
    }
  },
  methods: {
    addTextToCursorPosition(text) {
      let length = this.$refs.qeditor.quill.selection.savedRange.index
      this.$refs.qeditor.quill.insertEmbed(length, text)
      this.$refs.qeditor.quill.setSelection(length + text.length)
    },
  },
}
</script>

<style>
.text-editor .ql-container,
.text-editor .ql-container.ql-snow {
  max-height: 100% !important;
  height: 100% !important;
}
</style>
