<template>
  <div>
    <div v-if="myCompany" class="global-content">
      <div
        class="index-content left-settings-pane"
        :class="mobileOpen ? 'd-block' : 'd-none d-md-block'"
        style="z-index: 10"
      >
        <span class="sec-title">{{ $t('settings.menu.groupAppearance') }}</span>
        <div
          v-for="({ to, name, is = true }, index) in groupe_un"
          :key="'groupe_un-' + index"
          @click="page = to"
          v-show="is"
          :class="{
            'index-selected': page === to,
            'index-not-selected': page !== to,
          }"
        >
          <v-badge
            v-if="isEmpty[to] && isEmpty[to] > 0"
            :content="isEmpty[to]"
            color="red"
            inline
          >
            {{ name }}
          </v-badge>

          <span v-else>
            {{ name }}
          </span>
        </div>
        <span class="sec-title">{{
          $t('settings.menu.groupConfiguration')
        }}</span>
        <div
          v-for="({ to, name, is = true }, index) in groupe_deux"
          :key="'groupe_deux-' + index"
          @click="page = to"
          v-show="is"
          :class="{
            'index-selected': page === to,
            'index-not-selected': page !== to,
          }"
        >
          <v-badge
            v-if="isEmpty[to] && isEmpty[to] > 0"
            :content="isEmpty[to]"
            color="red"
            inline
          >
            {{ name }}
          </v-badge>

          <span v-else>
            {{ name }}
          </span>
        </div>
        <span class="sec-title">{{
          $t('settings.menu.groupInitialization')
        }}</span>
        <div
          v-for="({ to, name, is = true }, index) in groupe_trois"
          :key="'groupe_trois-' + index"
          @click="page = to"
          v-show="is"
          :class="{
            'index-selected': page === to,
            'index-not-selected': page !== to,
          }"
        >
          <v-badge
            v-if="isEmpty[to] && isEmpty[to] > 0"
            :content="isEmpty[to]"
            color="red"
            inline
          >
            {{ name }}
          </v-badge>

          <span v-else>
            {{ name }}
          </span>
        </div>
      </div>
      <!--Pages-->
      <div class="edit-content" :style="mobileOpen ? '' : ''">
        <div ref="targetScroll" />

        <!--   IDENTITE   -->
        <div
          v-show="page === 'identite'"
          :class="$vuetify.breakpoint.smAndDown ? 'ma-5' : 'ma-15'"
        >
          <identity :my-company="myCompany" />
        </div>

        <!--   RESERVATION EN LIGNE   -->
        <div
          v-if="myCompany.booker.active && page === 'reservation'"
          :class="$vuetify.breakpoint.smAndDown ? 'ma-5' : 'ma-15'"
        >
          <reservation :my-company="myCompany" />
        </div>

        <!--   SMS   -->
        <div
          v-if="myCompany.booker.active && page === 'sms'"
          :class="$vuetify.breakpoint.smAndDown ? 'ma-5' : 'ma-15'"
        >
          <sms :my-company="myCompany" />
        </div>

        <!--   BOUTIQUE   -->
        <div
          v-if="myCompany.booker.active && page === 'boutique'"
          :class="$vuetify.breakpoint.smAndDown ? 'ma-5' : 'ma-15'"
        >
          <boutique :my-company="myCompany" />
        </div>

        <!--   BON CADEAU   -->
        <div
          v-if="myCompany.booker.active && page === 'gift'"
          :class="$vuetify.breakpoint.smAndDown ? 'ma-5' : 'ma-15'"
        >
          <gift
            :my-company="myCompany"
            :images="images"
            :loading="loadingBanque"
          />
        </div>

        <!--   EMAILS   -->
        <div
          v-show="page === 'email'"
          :class="$vuetify.breakpoint.smAndDown ? 'ma-5' : 'ma-15'"
        >
          <email :my-company="myCompany" />
        </div>

        <!--   MODULE VIP   -->
        <div
          v-show="page === 'vipModule'"
          :class="$vuetify.breakpoint.smAndDown ? 'ma-5' : 'ma-15'"
        >
          <vip-module :my-company="myCompany" />
        </div>

        <!--   APPARENCE   -->
        <div
          v-show="page === 'apparence'"
          :class="$vuetify.breakpoint.smAndDown ? 'ma-5' : 'ma-15'"
        >
          <appearance :my-company="myCompany" />
        </div>

        <!--   COULEURS   -->
        <div
          v-show="page === 'colors'"
          :class="$vuetify.breakpoint.smAndDown ? 'my-5' : 'ma-15'"
        >
          <colors
            :my-company="myCompany"
            @changeMkpBackgroundImageData="mkpBackgroundImageData = $event"
          />
        </div>

        <!--   PARAMETRES DE PAIEMENTS   -->
        <div
          v-show="page === 'paiement'"
          :class="$vuetify.breakpoint.smAndDown ? 'ma-5' : 'ma-15'"
        >
          <paiement :my-company="myCompany" />
        </div>

        <!--   GESTION DES REMBOURSEMENTS   -->
        <div
          v-show="page === 'remboursement'"
          :class="$vuetify.breakpoint.smAndDown ? 'ma-5' : 'ma-15'"
        >
          <remboursement :my-company="myCompany" />
        </div>

        <!--   PARAMETRES DU SITE   -->
        <div
          v-show="page === 'site'"
          :class="$vuetify.breakpoint.smAndDown ? 'ma-5' : 'ma-15'"
        >
          <site
            :my-company="myCompany"
            @changeFavicon="favicon = $event"
            @changeLogo="logo = $event"
            @changePopupImg="popupImg = $event"
          />
        </div>

        <!--   PARAMETRES DE CONNEXION   -->
        <div
          v-show="page === 'connexion'"
          :class="$vuetify.breakpoint.smAndDown ? 'ma-5' : 'ma-15'"
        >
          <connexion :my-company="myCompany" />
        </div>

        <!--   STATISTIQUES   -->
        <div
          v-show="page === 'stats'"
          :class="$vuetify.breakpoint.smAndDown ? 'ma-5' : 'ma-15'"
        >
          <stats :my-company="myCompany" />
        </div>

        <!--   MENTIONS LEGALES   -->
        <div
          v-show="page === 'legals'"
          :class="$vuetify.breakpoint.smAndDown ? 'ma-5' : 'ma-15'"
        >
          <legals :my-company="myCompany" />
        </div>

        <!--   RESEAUX SOCIAUX   -->
        <div
          v-show="page === 'social'"
          :class="$vuetify.breakpoint.smAndDown ? 'ma-5' : 'ma-15'"
        >
          <social :my-company="myCompany" />
        </div>

        <!--   PARAMETRES OPTINS   -->
        <div
          v-show="page === 'optins'"
          :class="$vuetify.breakpoint.smAndDown ? 'ma-5' : 'ma-15'"
        >
          <optins :my-company="myCompany" @changePopupImg="popupImg = $event" />
        </div>
      </div>

      <div
        class="fixed d-flex d-md-none justify-space-between align-center pa-2"
        style="
          left: 0;
          right: 0;
          bottom: 36px;
          background-color: white;
          border-top: 2px solid #ccc;
          border-bottom: 1px solid #ccc;
        "
      >
        <!--   BTN MENU   -->
        <v-btn icon @click="mobileOpen = !mobileOpen">
          <v-icon> mdi-menu </v-icon>
        </v-btn>

        <!--   BTN SAVE   -->
        <v-btn color="primary" dark tile elevation="2" @click="saveInfo()">
          {{ $t('settings.save') }}
        </v-btn>
      </div>

      <!--   BTN SAVE   -->
      <v-btn
        class="fixed d-none d-md-inline-block"
        color="primary"
        dark
        tile
        elevation="2"
        @click="saveInfo()"
      >
        {{ $t('settings.save') }}
      </v-btn>
    </div>
    <v-overlay
      v-if="loading"
      class="d-flex justify-center align-center"
      style="z-index: 15"
    >
      <v-progress-circular indeterminate />
    </v-overlay>
  </div>
</template>

<script>
import swal from 'sweetalert'
import layoutHome from '@/layouts/layout-home.vue'
import uploadsHelpers from '@/helpers/uploads'
import Identity from '../../components/settings/identity'
import Reservation from '../../components/settings/reservation'
import Sms from '../../components/settings/sms'
import Boutique from '../../components/settings/boutique'
import Gift from '../../components/settings/gift'
import banqueImages from '@/helpers/banqueImages'
import Email from '../../components/settings/email'
import Appearance from '../../components/settings/appearance'
import Colors from '../../components/settings/colors'
import Paiement from '../../components/settings/paiement'
import Remboursement from '../../components/settings/remboursement'
import Site from '../../components/settings/site'
import Optins from '../../components/settings/optins'
import Connexion from '../../components/settings/connexion'
import Stats from '../../components/settings/stats'
import Legals from '../../components/settings/legals'
import Social from '../../components/settings/social'
import VipModule from '../../components/settings/vip-module'
import { havePremiumModule, PREMIUM_MODULES } from '@/helpers/premiumModules'

export default {
  name: 'GlobalConfiguration',
  components: {
    Social,
    Legals,
    Stats,
    Connexion,
    Site,
    Remboursement,
    Paiement,
    Colors,
    Appearance,
    Email,
    Gift,
    Boutique,
    Sms,
    Reservation,
    Identity,
    VipModule,
    Optins,
  },
  data() {
    return {
      mobileOpen: false,
      loading: false,
      logo: null,
      popupImg: null,
      myCompany: null,
      mkpBackgroundImageData: null,
      favicon: null,
      page: 'identite',
      topIndexFixed: '112px',
      images: {
        gift: {
          mail: [],
          pdf: [],
        },
      },
      loadingBanque: false,
      isEmpty: {
        identite: 0,
        site: 0,
        reservation: 0,
        gift: 0,
        email: 0,
        paiement: 0,
        remboursement: 0,
        connexion: 0,
        legals: 0,
        social: 0,
      },
    }
  },
  computed: {
    groupe_un() {
      return [
        { to: 'identite', name: this.$t('settings.menu.identity') },
        { to: 'site', name: this.$t('settings.menu.siteSettings') },
        { to: 'colors', name: this.$t('settings.menu.colors') },
        { to: 'apparence', name: this.$t('settings.menu.appearance') },
      ]
    },
    groupe_deux() {
      return [
        {
          to: 'reservation',
          name: this.$t('settings.menu.onlineBooking'),
          is: this.myCompany.booker.active,
        },
        {
          to: 'gift',
          name: this.$t('settings.menu.gift'),
          is: this.myCompany.booker.active,
        },
        {
          to: 'boutique',
          name: this.$t('settings.menu.shop'),
          is: this.myCompany.booker.active,
        },
        {
          to: 'sms',
          name: this.$t('settings.menu.sms'),
          is: this.myCompany.booker.active,
        },
        { to: 'email', name: this.$t('settings.menu.emails') },
        {
          to: 'vipModule',
          name: this.$t('settings.menu.vipModule'),
          is: havePremiumModule(this.myCompany, PREMIUM_MODULES.VIP),
        },
      ]
    },
    groupe_trois() {
      return [
        { to: 'paiement', name: this.$t('settings.menu.paymentsSettings') },
        {
          to: 'remboursement',
          name: this.$t('settings.menu.refundManagement'),
        },
        { to: 'connexion', name: this.$t('settings.menu.connectionSettings') },
        { to: 'stats', name: this.$t('settings.menu.statistics') },
        { to: 'social', name: this.$t('settings.social.socialNetworks') },
        { to: 'optins', name: this.$t('settings.menu.optins') },
        { to: 'legals', name: this.$t('settings.menu.legalNotice') },
      ]
    },
    nickname() {
      return this.$store.state.auth.company.nickname
    },
  },

  async created() {
    this.$emit('update:layout', layoutHome)

    this.loading = true
    await this.$store.dispatch('auth/fetchCompany')
    this.myCompany = this.$store.state.auth.company
    this.loading = false
  },

  async mounted() {
    this.scrolled()
    window.addEventListener('scroll', this.scrolled, false)

    let ourImagesMail,
      ourImagesPdf,
      yourImagesMail,
      yourImagesPdf = []
    this.loadingBanque = true
    //MAIL
    await banqueImages.getGiftMail().then((res) => {
      ourImagesMail = res
    })
    await banqueImages.getThem(this.nickname, 'gift', 'mail').then((res) => {
      yourImagesMail = res
    })
    yourImagesMail.forEach((y) => {
      if (ourImagesMail.find((o) => o.title === y.title)) {
        const src = ourImagesMail.find((o) => o.title === y.title).src
        src.push(...y.src)
      } else {
        ourImagesMail.push(y)
      }
    })
    ourImagesMail.sort((a, b) => {
      if (a.title < b.title) return -1
      else if (b.title < a.title) return 1
      return 0
    })
    this.images.gift.mail = ourImagesMail

    //PDF
    await banqueImages.getGiftPdf().then((res) => {
      ourImagesPdf = res
    })
    await banqueImages.getThem(this.nickname, 'gift', 'pdf').then((res) => {
      yourImagesPdf = res
    })
    yourImagesPdf.forEach((y) => {
      if (ourImagesPdf.find((o) => o.title === y.title)) {
        const src = ourImagesPdf.find((o) => o.title === y.title).src
        src.push(...y.src)
      } else {
        ourImagesPdf.push(y)
      }
    })
    ourImagesPdf.sort((a, b) => {
      if (a.title < b.title) return -1
      else if (b.title < a.title) return 1
      return 0
    })
    this.images.gift.pdf = ourImagesPdf

    this.loadingBanque = false

    if (this.myCompany) {
      this.detectEmpty()
    }
  },
  methods: {
    sendRequestDev() {
      try {
        fetch(
          'http://185.245.95.110/api/stat/booking?start=20210901&end=20210930&page=1',
          {
            headers: {
              'X-AUTHORIZATION':
                'Bearer eyAiYWxnIjoiSFMyNTYiLCAidHlwIjoiSldUIiB9.eyAic3ViIjoiQURTUkVNT1RFMjAxNEoiLCAiYXVkIjoicHJpdmF0ZSIsICJleHAiOjE2MzEzNzA1MzMgfQ.8tnZgJp1NHqAgn-zzZ3xGuWvaZAuh-ursNGrAxhYm6E=',
            },
          }
        )
      } catch (err) {
        console.log(err)
      }
    },
    scrolled() {
      const currentScroll =
        document.body.scrollTop || document.documentElement.scrollTop
      if (currentScroll < 112) {
        this.topIndexFixed = 112 - currentScroll + 'px'
      } else {
        if (this.topIndexFixed !== 0) this.topIndexFixed = 0
      }
    },
    detectEmpty() {
      //IDENTITE
      this.isEmpty.identite = 0
      if (this.myCompany.identity && !this.myCompany.identity.displayName) {
        this.isEmpty.identite++
      }

      //SITE
      this.isEmpty.site = 0
      if (
        this.myCompany.booker &&
        this.myCompany.booker.favicon &&
        !this.myCompany.booker.favicon.title
      ) {
        this.isEmpty.site++
      }
      if (this.favicon && !this.favicon.length) {
        this.isEmpty.site++
      }
      if (
        this.logo &&
        this.logo.length &&
        this.myCompany.identity &&
        this.myCompany.identity.logoSize === 75
      ) {
        this.isEmpty.site++
      }

      //RESA
      this.isEmpty.reservation = 0
      if (
        this.myCompany.booker &&
        this.myCompany.booker.emailCopyToList &&
        !this.myCompany.booker.emailCopyList
      ) {
        this.isEmpty.reservation++
      }

      //GIFT
      this.isEmpty.gift = 0
      if (
        this.myCompany.booker &&
        this.myCompany.booker.gift &&
        (!this.myCompany.booker.gifts ||
          !this.myCompany.booker.gifts.themes.length)
      ) {
        this.isEmpty.gift++
      }
      if (
        this.myCompany.booker &&
        this.myCompany.booker.gift &&
        (!this.myCompany.booker.gifts ||
          !this.myCompany.booker.gifts.bcValues.length)
      ) {
        this.isEmpty.gift++
      }
      if (
        this.myCompany.booker &&
        this.myCompany.booker.giftEmailCopyToList &&
        !this.myCompany.booker.giftEmailCopyList
      ) {
        this.isEmpty.gift++
      }

      //EMAIL
      this.isEmpty.email = 0
      if (this.myCompany.mails) {
        Object.keys(this.myCompany.mails).forEach((key) => {
          if (!this.myCompany.mails[key][this.$i18n.locale]) {
            this.isEmpty.email++
          }
        })
      }

      //PAIEMENT
      this.isEmpty.paiement = 0
      if (this.myCompany.booker && this.myCompany.booker.payment) {
        if (this.myCompany.booker.payment.provider === 'payzen') {
          if (!this.myCompany.booker.payment.keys.publicKeyPayzen) {
            this.isEmpty.paiement++
          }
          if (!this.myCompany.booker.payment.keys.user) {
            this.isEmpty.paiement++
          }
          if (!this.myCompany.booker.payment.keys.password) {
            this.isEmpty.paiement++
          }
          if (!this.myCompany.booker.payment.keys.decryptKey) {
            this.isEmpty.paiement++
          }
          if (!this.myCompany.booker.payment.keys.baseURL) {
            this.isEmpty.paiement++
          }
        } else if (this.myCompany.booker.payment.provider === 'stripe') {
          if (!this.myCompany.booker.payment.keys.publicKeyStripe) {
            this.isEmpty.paiement++
          }
          if (!this.myCompany.booker.payment.keys.privateKey) {
            this.isEmpty.paiement++
          }
        } else if (this.myCompany.booker.payment.provider === 'saferpay') {
          if (!this.myCompany.booker.payment.keys.customerSaferpay) {
            this.isEmpty.paiement++
          }
          if (!this.myCompany.booker.payment.keys.terminalSaferpay) {
            this.isEmpty.paiement++
          }
          if (!this.myCompany.booker.payment.keys.userSaferpay) {
            this.isEmpty.paiement++
          }
          if (!this.myCompany.booker.payment.keys.passwordSaferpay) {
            this.isEmpty.paiement++
          }
          if (!this.myCompany.booker.payment.keys.baseURLSaferpay) {
            this.isEmpty.paiement++
          }
        } else if (this.myCompany.booker.payment.provider === 'paypal') {
          if (!this.myCompany.booker.payment.keys.paypalAccountId) {
            this.isEmpty.paiement++
          }
          if (!this.myCompany.booker.payment.keys.paypalUsername) {
            this.isEmpty.paiement++
          }
          if (!this.myCompany.booker.payment.keys.paypalPassword) {
            this.isEmpty.paiement++
          }
        }
      }

      //REMBOURSEMENT
      this.isEmpty.remboursement = 0
      if (
        this.myCompany.booker &&
        this.myCompany.booker.textLegals &&
        !this.myCompany.booker.textLegals.canceling[this.$i18n.locale]
      ) {
        this.isEmpty.remboursement++
      }

      //CONNEXION
      this.isEmpty.connexion = 0
      if (
        this.myCompany.booker &&
        this.myCompany.booker.alertMessages &&
        this.myCompany.booker.alertMessages.connexion &&
        this.myCompany.booker.alertMessages.connexion.active &&
        !this.myCompany.booker.alertMessages.connexion.message[
          this.$i18n.locale
        ]
      ) {
        this.isEmpty.connexion++
      }
      if (
        this.myCompany.booker &&
        this.myCompany.booker.alertMessages &&
        this.myCompany.booker.alertMessages.registration &&
        this.myCompany.booker.alertMessages.registration.active &&
        !this.myCompany.booker.alertMessages.registration.message[
          this.$i18n.locale
        ]
      ) {
        this.isEmpty.connexion++
      }

      //LEGALS
      this.isEmpty.legals = 0
      if (
        this.myCompany.booker &&
        this.myCompany.booker.textLegals &&
        !this.myCompany.booker.textLegals.rgpd[this.$i18n.locale]
      ) {
        this.isEmpty.legals++
      }
      if (
        this.myCompany.booker &&
        this.myCompany.booker.textLegals &&
        !this.myCompany.booker.textLegals.mentions[this.$i18n.locale]
      ) {
        this.isEmpty.legals++
      }
      if (
        this.myCompany.booker &&
        this.myCompany.booker.textLegals &&
        !this.myCompany.booker.textLegals.cgv[this.$i18n.locale]
      ) {
        this.isEmpty.legals++
      }
      if (
        this.myCompany.booker &&
        this.myCompany.booker.textLegals &&
        !this.myCompany.booker.textLegals.cgu[this.$i18n.locale]
      ) {
        this.isEmpty.legals++
      }
      if (
        this.myCompany.booker &&
        this.myCompany.booker.textLegals &&
        !this.myCompany.booker.textLegals.cookies[this.$i18n.locale]
      ) {
        this.isEmpty.legals++
      }

      //SOCIAL
      this.isEmpty.social = 0
      if (this.myCompany.identity && this.myCompany.identity.socialNetworks) {
        Object.keys(this.myCompany.identity.socialNetworks).forEach((key) => {
          if (
            this.myCompany.identity.socialNetworks[key].active &&
            !this.myCompany.identity.socialNetworks[key].url
          ) {
            this.isEmpty.social++
          }
        })
      }
    },

    async saveInfo() {
      this.loading = true

      let imagesUrlMkpBackground = []
      let imagesUrlFavicon = []
      let imagesUrlLogo = []
      let imagesUrlPopupImg = []

      try {
        for (let i = 0; i < this.mkpBackgroundImageData.length; i++) {
          if (this.mkpBackgroundImageData[i]) {
            if (this.mkpBackgroundImageData[i].new) {
              const image = this.mkpBackgroundImageData[i].blob
              const fileName = uploadsHelpers.randomName()

              /*
              const result = await Promise.all([
                uploadsHelpers.uploadSingleBase64(
                  image,
                  'admin/settings',
                  fileName
                ),
                uploadsHelpers.uploadSingleBase64(
                  imageLazy,
                  'admin/settings',
                  fileName + '-lazy'
                ),
              ])

              const imageUrl = result[0]
              const lazyImageUrl = result[1]*/

              const imageUrl = await uploadsHelpers.uploadSingleBase64(
                image,
                'imagekit/eden/settings',
                fileName,
                this.nickname
              )

              imagesUrlMkpBackground.push({
                url: imageUrl,
                description: this.mkpBackgroundImageData[i].description,
                alt: this.mkpBackgroundImageData[i].alt,
              })
            } else {
              imagesUrlMkpBackground.push(this.mkpBackgroundImageData[i])
            }
          }
        }
      } catch (err) {
        console.log(err)
        return {
          error: true,
          message: this.$t('settings.anErrorOccurredImages'),
        }
      }

      try {
        for (let i = 0; i < this.favicon.length; i++) {
          if (this.favicon[i].new) {
            const image = this.favicon[i].blob
            const fileName = uploadsHelpers.randomName()

            /*
            const result = await Promise.all([
              uploadsHelpers.uploadSingleBase64(
                image,
                'admin/settings',
                fileName
              ),
              uploadsHelpers.uploadSingleBase64(
                imageLazy,
                'admin/settings',
                fileName + '-lazy'
              )
            ])

            const imageUrl = result[0]
            const lazyImageUrl = result[1]*/

            const imageUrl = await uploadsHelpers.uploadSingleBase64(
              image,
              'imagekit/eden/settings',
              fileName,
              this.nickname
            )

            imagesUrlFavicon.push({
              url: imageUrl,
              description: this.favicon[i].description,
              alt: this.favicon[i].alt,
            })
          } else {
            imagesUrlFavicon.push(this.favicon[i])
          }
        }
      } catch (err) {
        console.log(err)
        return {
          error: true,
          message: this.$t('settings.anErrorOccurredImages'),
        }
      }

      try {
        for (let i = 0; i < this.logo.length; i++) {
          if (this.logo[i].new) {
            const image = this.logo[i].blob
            const fileName = uploadsHelpers.randomName()

            /*
              const result = await Promise.all([
                uploadsHelpers.uploadSingleBase64(
                  image,
                  'admin/settings',
                  fileName
                ),
                uploadsHelpers.uploadSingleBase64(
                  imageLazy,
                  'admin/settings',
                  fileName + '-lazy'
                )
              ])

              const imageUrl = result[0]
              const lazyImageUrl = result[1]*/

            const imageUrl = await uploadsHelpers.uploadSingleBase64(
              image,
              'imagekit/eden/settings',
              fileName,
              this.nickname
            )

            imagesUrlLogo.push({
              url: imageUrl,
              description: this.logo[i].description,
              alt: this.logo[i].alt,
            })
          } else {
            imagesUrlLogo.push(this.logo[i])
          }
        }
      } catch (err) {
        console.log(err)
        return {
          error: true,
          message: this.$t('settings.anErrorOccurredImages'),
        }
      }

      try {
        for (let i = 0; i < this.popupImg.length; i++) {
          if (this.popupImg[i].new) {
            const image = this.popupImg[i].blob
            const fileName = uploadsHelpers.randomName()

            const imageUrl = await uploadsHelpers.uploadSingleBase64(
              image,
              'imagekit/eden/settings',
              fileName,
              this.nickname
            )

            imagesUrlPopupImg.push({
              url: imageUrl,
              description: this.popupImg[i].description,
              alt: this.popupImg[i].alt,
            })
          } else {
            imagesUrlPopupImg.push(this.popupImg[i])
          }
        }
      } catch (err) {
        console.log(err)
        return {
          error: true,
          message: this.$t('settings.anErrorOccurredImages'),
        }
      }

      if (
        this.myCompany.booker.gifts &&
        this.myCompany.booker.gifts.themes &&
        this.myCompany.booker.gifts.themes.length
      ) {
        try {
          for (let i = 0; i < this.myCompany.booker.gifts.themes.length; i++) {
            if (this.myCompany.booker.gifts.themes[i].header[0]) {
              let exist = false
              this.images.gift.mail.map((img) => {
                img.src.forEach((s) => {
                  if (
                    s === this.myCompany.booker.gifts.themes[i].header[0].url
                  ) {
                    exist = true
                  }
                })
              })

              if (
                this.myCompany.booker.gifts.themes[i].header[0].new &&
                !exist
              ) {
                const image =
                  this.myCompany.booker.gifts.themes[i].header[0].blob
                // const imageLazy = this.myCompany.booker.gifts.themes[i].header[0]
                //   .blobLazy
                const fileName = uploadsHelpers.randomName()

                /*
                const result = await Promise.all([
                  uploadsHelpers.uploadSingleBase64(
                    image,
                    'admin/gift',
                    fileName
                  ),
                  uploadsHelpers.uploadSingleBase64(
                    imageLazy,
                    'admin/gift',
                    fileName + '-lazy'
                  )
                ])

                const imageUrl = result[0]
                const lazyImageUrl = result[1]*/

                const directory = this.myCompany.booker.gifts.themes[i]
                  .header[0].category
                  ? 'imagekit/eden/bank/gift/mail/' +
                    this.myCompany.booker.gifts.themes[i].header[0].category
                  : 'imagekit/eden/gift'

                const imageUrl = await uploadsHelpers.uploadSingleBase64(
                  image,
                  directory,
                  fileName,
                  this.nickname
                )

                this.myCompany.booker.gifts.themes[i].header[0] = {
                  url: imageUrl,
                  description:
                    this.myCompany.booker.gifts.themes[i].header[0].description,
                  alt: this.myCompany.booker.gifts.themes[i].header[0].alt,
                  new: false,
                }
              } else {
                // giftImagesUrl.push(this.myCompany.booker.gifts.themes[i])
              }
            }
          }
        } catch (err) {
          console.log(err)
          return {
            error: true,
            message: this.$t('settings.anErrorOccurredImages'),
          }
        }

        try {
          for (let i = 0; i < this.myCompany.booker.gifts.themes.length; i++) {
            if (this.myCompany.booker.gifts.themes[i].pdf[0]) {
              let exist = false
              this.images.gift.pdf.map((img) => {
                img.src.forEach((s) => {
                  if (s === this.myCompany.booker.gifts.themes[i].pdf[0].url) {
                    exist = true
                  }
                })
              })

              if (this.myCompany.booker.gifts.themes[i].pdf[0].new && !exist) {
                const image = this.myCompany.booker.gifts.themes[i].pdf[0].blob
                const fileName = uploadsHelpers.randomName()

                /*
                const result = await Promise.all([
                  uploadsHelpers.uploadSingleBase64(image, 'admin/gift', fileName)
                ])

                const pdfUrl = result[0]*/

                const directory = this.myCompany.booker.gifts.themes[i].pdf[0]
                  .category
                  ? 'imagekit/eden/bank/gift/pdf/' +
                    this.myCompany.booker.gifts.themes[i].pdf[0].category
                  : 'imagekit/eden/gift'

                const pdfUrl = await uploadsHelpers.uploadSingleBase64(
                  image,
                  directory,
                  fileName,
                  this.nickname
                )

                this.myCompany.booker.gifts.themes[i].pdf[0] = {
                  url: pdfUrl,
                  description:
                    this.myCompany.booker.gifts.themes[i].pdf[0].description,
                  alt: this.myCompany.booker.gifts.themes[i].pdf[0].alt,
                  new: false,
                }
              } else {
                // this.myCompany.booker.gifts.themes[i].pdf = this.myCompany.booker.gifts.themes[i].pdf
              }
            }
          }
        } catch (err) {
          console.log(err)
          return {
            error: true,
            message: this.$t('settings.anErrorOccurredPdfs'),
          }
        }
      }

      const companyData = JSON.parse(JSON.stringify(this.myCompany))
      delete companyData.id
      delete companyData.nickname
      delete companyData.owner
      delete companyData.createdAt

      if (
        companyData.booker.gifts &&
        companyData.booker.gifts.themes &&
        companyData.booker.gifts.themes.length
      ) {
        companyData.booker.gifts.themes.forEach((theme) => {
          if (!theme.header.length || !theme.pdf.length) {
            companyData.booker.gifts.themes.splice(
              companyData.booker.gifts.themes.findIndex((t) => t === theme),
              1
            )
          }
        })
      }

      const articles = JSON.parse(
        JSON.stringify(this.$store.state.articles.articles)
      ).sort((a, b) => a.prices - b.prices)

      if (articles.length) {
        const first = articles[0]

        if (companyData.booker.gifts && companyData.booker.gifts.bcValues) {
          companyData.booker.gifts.bcValues =
            companyData.booker.gifts.bcValues.filter(
              (bv) => bv >= first.prices && bv > 0
            )
        }
      }

      if (imagesUrlMkpBackground.length) {
        companyData.themes.general.mkpBackgroundImage = {
          url: imagesUrlMkpBackground[0].url,
        }
      } else {
        companyData.themes.general.mkpBackgroundImage = null
      }

      if (imagesUrlFavicon.length) {
        companyData.booker.favicon.image = {
          url: imagesUrlFavicon[0].url,
        }
      } else {
        companyData.booker.favicon.image = null
      }

      if (imagesUrlLogo.length) {
        companyData.identity.logo = {
          url: imagesUrlLogo[0].url,
        }
      } else {
        companyData.identity.logo = null
      }

      if (imagesUrlPopupImg.length) {
        companyData.booker.popupImg = {
          url: imagesUrlPopupImg[0].url,
        }
      } else {
        companyData.booker.popupImg = null
      }

      if (companyData.booker.payment.provider === 'payzen') {
        companyData.booker.payment.keys.publicKey =
          companyData.booker.payment.keys.publicKeyPayzen
      }
      if (companyData.booker.payment.provider === 'stripe') {
        companyData.booker.payment.keys.publicKey =
          companyData.booker.payment.keys.publicKeyStripe
      }
      if (companyData.booker.payment.provider === 'saferpay') {
        companyData.booker.payment.keys.customer =
          companyData.booker.payment.keys.customerSaferpay
        companyData.booker.payment.keys.terminal =
          companyData.booker.payment.keys.terminalSaferpay
        companyData.booker.payment.keys.user =
          companyData.booker.payment.keys.userSaferpay
        companyData.booker.payment.keys.password =
          companyData.booker.payment.keys.passwordSaferpay
        companyData.booker.payment.keys.baseURL =
          companyData.booker.payment.keys.baseURLSaferpay
      }
      if (companyData.booker.payment.provider === 'paypal') {
        companyData.booker.payment.keys.publicKey =
          companyData.booker.payment.keys.paypalAccountId
        companyData.booker.payment.keys.user =
          companyData.booker.payment.keys.paypalAccountId
        companyData.booker.payment.keys.password =
          companyData.booker.payment.keys.paypalPrivateKey
      }

      try {
        await this.$store.dispatch('auth/updateCompany', {
          id: this.myCompany.id,
          data: companyData,
        })

        swal(
          this.$t('settings.saved'),
          this.$t('settings.changeSaved'),
          'success'
        )
      } catch (err) {
        console.log(err)
        swal(
          this.$t('settings.error'),
          this.$t('settings.changeError'),
          'error'
        )
      }

      this.loading = false
    },
  },
  watch: {
    page() {
      this.$refs['targetScroll'].scrollIntoView({
        block: 'center',
      })
      this.detectEmpty()
    },
    myCompany: {
      handler() {
        this.detectEmpty()
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener('scroll', this.scrolled, false)
    next()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrolled, false)
  },
}
</script>

<style scoped>
.global-content {
  width: 100%;
}

.index-content {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  background-color: #ccc;
  position: fixed;
  left: 0;
  top: 104px;
  width: 300px;
  bottom: 36px;
  padding: 20px 10px;
  overflow-y: auto;
}

.index-content div {
  cursor: pointer;
  background-color: white;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
  font-weight: 700;
  position: relative;
  transition: 0.2s;
  margin-top: 6px;
}

.index-content div:first-child {
  margin-top: 0;
}

.index-content div.index-selected {
  border-radius: 5px 0 0 5px;
  padding-left: 40px;
}

.index-content div.index-not-selected {
  background-color: #eee;
}

.index-content div:after {
  content: '';
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 0;
  background-color: white;
  transition: 0.2s;
}

.index-content div.index-selected:after {
  width: 10px;
}

.edit-content {
  width: 100%;
  padding-left: 0px;
}

@media (min-width: 960px) {
  .edit-content {
    width: 100%;
    padding-left: 300px;
  }
}

@media (max-width: 959px) {
  .left-settings-pane {
    padding-bottom: 75px;
  }
}

.fixed {
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 10;
}

.sec-title {
  margin-top: 24px;
  margin-bottom: 6px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.75rem;
  color: #666666;
  display: block;
}

.sec-title:first-child {
  margin-top: 0;
}
@media (max-width: 959px) {
  .sec-title {
    margin-top: 24px;
  }
}
</style>

<style>
.h4-content {
  font-size: 1.2rem;
  margin-top: 50px;
}

.h4-content span {
  width: 100%;
  height: 1px;
  background-color: black;
  display: block;
  margin-top: 10px;
}

.empty fieldset {
  border-color: red;
}

.st-section-title {
  background-color: #474545;
  color: #efe2cd;
  font-weight: bold;
  text-transform: uppercase;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.st-section-image {
  height: 24px;
  margin-right: 12px;
}

.st-subsection-title {
  background-color: #ded9d9;
  color: #888282;
  font-weight: bold;
  text-transform: uppercase;
  padding: 4px 8px;
  font-size: 12px;
}
</style>
