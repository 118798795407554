<template>
  <v-tabs v-model="themeTab" fixed-tabs active-class="font-weight-bold">
    <v-tab class="black--text">
      {{ $t('settings.appearance.home') }}
    </v-tab>
    <v-tab class="black--text">
      {{ $t('settings.appearance.aShop') }}
    </v-tab>
    <v-tab class="black--text">
      {{ $t('settings.appearance.services') }}
    </v-tab>
    <v-tab v-if="myCompany.booker.products.active" class="black--text">
      {{ $t('settings.appearance.shop') }}
    </v-tab>
    <v-tab class="black--text">
      {{ $t('settings.appearance.gift') }}
    </v-tab>
    <v-tab v-if="onlineShops.length > 1" class="black--text">
      {{ $t('settings.appearance.contact') }}
    </v-tab>
    <v-tab class="black--text">
      {{ $t('settings.appearance.reservation') }}
    </v-tab>

    <v-tab-item class="ma-10">
      <template v-if="onlineShops.length <= 1">
        <div class="d-flex align-center justify-center">
          <span class="mr-5"> Afficher le site en mode conteneurisé : </span>
          <v-switch
            inset
            v-model="myCompany.themes.siteContained"
            class="ml-3 mr-2"
          />
        </div>
        <div class="my-5 text-center">
          <img
            :src="'/themes/' + 'monoShop' + '.png'"
            style="filter: blur(1px); width: 35%"
            alt="monoshop"
          />
        </div>

        <!--              <v-row align="stretch">-->
        <!--                <v-col cols="12 h4-content">-->
        <!--                  <h4>-->
        <!--                    {{ $t('settings.appearance.imageDisplay') }}-->
        <!--                  </h4>-->
        <!--                  <span />-->
        <!--                </v-col>-->
        <!--                <v-col cols="12" class="text-center">-->
        <!--                  {{-->
        <!--                    $t('settings.appearance.selectYourChoiceAccordingToThemesBelow')-->
        <!--                  }}-->
        <!--                </v-col>-->
        <!--                <v-col cols="12">-->
        <!--                  <v-row class="mt-6 mx-5">-->
        <!--                    <v-col-->
        <!--                      v-for="themeItem in themesList['monoShop'].themes"-->
        <!--                      :key="themeItem.id"-->
        <!--                      cols="12"-->
        <!--                      md="4"-->
        <!--                    >-->
        <!--                      <div-->
        <!--                        v-ripple-->
        <!--                        style="position: relative"-->
        <!--                        @click="selectTheme('monoShop', themeItem)"-->
        <!--                      >-->
        <!--                        <img-->
        <!--                          :src="'/themes/' + 'monoShop' + '/' + themeItem.id + '.png'"-->
        <!--                          style="width: 100%"-->
        <!--                          alt="monoshop"-->
        <!--                        />-->
        <!--                        <div style="position: absolute; top: 5px; right: 5px">-->
        <!--                          <v-btn icon dark color="green">-->
        <!--                            <v-icon>-->
        <!--                              {{-->
        <!--                                myCompany.themes['monoShop'] &&-->
        <!--                                themeItem.id === myCompany.themes['monoShop'].id-->
        <!--                                  ? 'mdi-check-circle'-->
        <!--                                  : ''-->
        <!--                              }}-->
        <!--                            </v-icon>-->
        <!--                          </v-btn>-->
        <!--                        </div>-->
        <!--                      </div>-->
        <!--                    </v-col>-->
        <!--                  </v-row>-->
        <!--                </v-col>-->
        <!--              </v-row>-->

        <!--              <v-row align="stretch">-->
        <!--                <v-col cols="12 h4-content">-->
        <!--                  <h4>-->
        <!--                    {{ $t('settings.appearance.categoriesDisplay') }}-->
        <!--                  </h4>-->
        <!--                  <span />-->
        <!--                </v-col>-->
        <!--                <v-col cols="12" class="text-center">-->
        <!--                  {{-->
        <!--                    $t('settings.appearance.selectYourChoiceAccordingToThemesBelow')-->
        <!--                  }}-->
        <!--                </v-col>-->
        <!--                <v-col cols="12">-->
        <!--                  <v-row class="mt-6 mx-5">-->
        <!--                    <v-col-->
        <!--                      v-for="themeItem in themesList['services'].themes"-->
        <!--                      :key="themeItem.id"-->
        <!--                      cols="12"-->
        <!--                      md="4"-->
        <!--                    >-->
        <!--                      <div-->
        <!--                        v-ripple-->
        <!--                        style="position: relative"-->
        <!--                        @click="selectTheme('services', themeItem)"-->
        <!--                      >-->
        <!--                        <img-->
        <!--                          :src="'/themes/' + 'services' + '/' + themeItem.id + '.png'"-->
        <!--                          style="width: 100%"-->
        <!--                          :alt="themeItem.id"-->
        <!--                        />-->
        <!--                        <div style="position: absolute; top: 5px; right: 5px">-->
        <!--                          <v-btn icon dark color="green">-->
        <!--                            <v-icon>-->
        <!--                              {{-->
        <!--                                myCompany.themes['services'] &&-->
        <!--                                themeItem.id === myCompany.themes['services'].id-->
        <!--                                  ? 'mdi-check-circle'-->
        <!--                                  : ''-->
        <!--                              }}-->
        <!--                            </v-icon>-->
        <!--                          </v-btn>-->
        <!--                        </div>-->
        <!--                      </div>-->
        <!--                    </v-col>-->
        <!--                  </v-row>-->
        <!--                </v-col>-->
        <!--              </v-row>-->
      </template>
      <template v-if="onlineShops.length > 1">
        <div class="d-flex align-center justify-center">
          <span class="mr-5"> Afficher le site en mode conteneurisé : </span>
          <v-switch
            inset
            v-model="myCompany.themes.siteContained"
            class="ml-3 mr-2"
          />
        </div>
        <div class="d-flex align-center justify-center">
          <span class="mr-5">
            {{ $t('settings.appearance.displayMode') }} :
          </span>
          <span>MultiShop</span>
          <v-switch inset v-model="homepageType" class="ml-3 mr-2" />
          <span>Marketplace</span>
        </div>
        <template v-if="myCompany.booker.homepageType === 'multi'">
          <div class="my-5 text-center">
            <img
              :src="'/themes/' + 'multiShop' + '.png'"
              style="filter: blur(1px); width: 35%"
              alt="multiShop"
            />
          </div>

          <v-row align="stretch">
            <v-col cols="12 h4-content">
              <h4>
                {{ $t('settings.appearance.numberShopsLine') }}
              </h4>
              <span />
            </v-col>
            <v-col cols="12" class="text-center">
              {{
                $t('settings.appearance.selectYourChoiceAccordingToThemesBelow')
              }}
            </v-col>
            <v-col cols="12">
              <v-row class="mt-6 mx-5">
                <v-col
                  v-for="themeItem in themesList['multiShop'].themes"
                  :key="themeItem.id"
                  cols="12"
                  md="4"
                >
                  <div
                    v-ripple
                    style="position: relative"
                    @click="selectTheme('multiShop', themeItem)"
                  >
                    <img
                      :src="
                        '/themes/' + 'multiShop' + '/' + themeItem.id + '.png'
                      "
                      style="width: 100%"
                      :alt="themeItem.id"
                    />
                    <div style="position: absolute; top: 5px; right: 5px">
                      <v-btn icon dark color="green">
                        <v-icon>
                          {{
                            myCompany.themes['multiShop'] &&
                            themeItem.id === myCompany.themes['multiShop'].id
                              ? 'mdi-check-circle'
                              : ''
                          }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  ['grid', 'grid2'].includes(myCompany.themes['multiShop'].id)
                "
                dense
              >
                <v-col cols="12">
                  <v-switch
                    inset
                    v-model="myCompany.themes['multiShop'].useDense"
                    label="Utiliser la version dense"
                  />
                </v-col>
                <v-col cols="12">
                  <v-switch
                    inset
                    v-model="myCompany.themes['multiShop'].useContained"
                    label="Utiliser la version conteneurisée"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <div class="my-5 text-center">
            <img
              :src="'/themes/' + 'mkpShop' + '.png'"
              style="filter: blur(1px); width: 35%"
              alt="mkpShop"
            />
          </div>

          <v-row align="stretch">
            <v-col cols="12 h4-content">
              <h4>
                {{ $t('settings.appearance.pageDisplay') }}
              </h4>
              <span />
            </v-col>
            <v-col cols="12" class="text-center">
              {{
                $t('settings.appearance.selectYourChoiceAccordingToThemesBelow')
              }}
            </v-col>
            <v-col cols="12">
              <v-row class="mt-6 mx-5">
                <v-col
                  v-for="themeItem in themesList['mkpShop'].themes"
                  :key="themeItem.id"
                  cols="12"
                  md="4"
                >
                  <div
                    v-ripple
                    style="position: relative"
                    @click="selectTheme('mkpShop', themeItem)"
                  >
                    <img
                      :src="
                        '/themes/' + 'mkpShop' + '/' + themeItem.id + '.png'
                      "
                      style="width: 100%"
                      :alt="themeItem.id"
                    />
                    <div style="position: absolute; top: 5px; right: 5px">
                      <v-btn icon dark color="green">
                        <v-icon>
                          {{
                            myCompany.themes['mkpShop'] &&
                            themeItem.id === myCompany.themes['mkpShop'].id
                              ? 'mdi-check-circle'
                              : ''
                          }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </template>
    </v-tab-item>

    <v-tab-item class="ma-10">
      <div class="my-5 text-center">
        <img
          :src="'/themes/' + 'monoShop' + '.png'"
          style="filter: blur(1px); width: 35%"
          alt="monoShop"
        />
      </div>

      <v-row align="stretch">
        <v-col cols="12 h4-content">
          <h4>{{ $t('settings.appearance.imageDisplay') }}</h4>
          <span />
        </v-col>
        <v-col cols="12" class="text-center">
          {{ $t('settings.appearance.selectYourChoiceAccordingToThemesBelow') }}
        </v-col>
        <v-col cols="12">
          <v-row class="mt-6 mx-5">
            <v-col
              v-for="themeItem in themesList['monoShop'].themes"
              :key="themeItem.id"
              cols="12"
              md="4"
            >
              <div
                v-ripple
                style="position: relative"
                @click="selectTheme('monoShop', themeItem)"
              >
                <img
                  :src="'/themes/' + 'monoShop' + '/' + themeItem.id + '.png'"
                  style="width: 100%"
                  :alt="themeItem.id"
                />
                <div style="position: absolute; top: 5px; right: 5px">
                  <v-btn icon dark color="green">
                    <v-icon>
                      {{
                        myCompany.themes['monoShop'] &&
                        themeItem.id === myCompany.themes['monoShop'].id
                          ? 'mdi-check-circle'
                          : ''
                      }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch
                inset
                v-model="myCompany.themes['monoShop'].useV2"
                label="Utiliser la version horizontale"
              />
            </v-col>
            <v-col cols="12" v-if="!!myCompany.themes['monoShop'].useV2">
              <v-switch
                inset
                v-model="myCompany.themes['monoShop'].useCompact"
                label="Utiliser la version compact"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row align="stretch">
        <v-col cols="12 h4-content">
          <h4>{{ $t('settings.appearance.categoriesDisplay') }}</h4>
          <span />
        </v-col>
        <v-col cols="12" class="text-center">
          {{ $t('settings.appearance.selectYourChoiceAccordingToThemesBelow') }}
        </v-col>
        <v-col cols="12">
          <v-row class="mt-6 mx-5">
            <v-col
              v-for="themeItem in themesList['services'].themes"
              :key="themeItem.id"
              cols="12"
              md="4"
            >
              <div
                v-ripple
                style="position: relative"
                @click="selectTheme('services', themeItem)"
              >
                <img
                  :src="'/themes/' + 'services' + '/' + themeItem.id + '.png'"
                  style="width: 100%"
                  :alt="themeItem.id"
                />
                <div style="position: absolute; top: 5px; right: 5px">
                  <v-btn icon dark color="green">
                    <v-icon>
                      {{
                        myCompany.themes['services'] &&
                        themeItem.id === myCompany.themes['services'].id
                          ? 'mdi-check-circle'
                          : ''
                      }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="['cards'].includes(myCompany.themes['services'].id)"
          >
            <v-col cols="12">
              <v-switch
                inset
                v-model="myCompany.themes['services'].useFusion"
                label="Utiliser la version couleur et image"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-tab-item>

    <v-tab-item class="ma-10">
      <div class="my-5 text-center">
        <img
          :src="'/themes/' + 'services' + '.png'"
          style="filter: blur(1px); width: 35%"
          alt="services"
        />
      </div>

      <v-row align="stretch">
        <v-col cols="12 h4-content">
          <h4>{{ $t('settings.appearance.categoriesDisplay') }}</h4>
          <span />
        </v-col>
        <v-col cols="12" class="text-center">
          {{ $t('settings.appearance.selectYourChoiceAccordingToThemesBelow') }}
        </v-col>
        <v-col cols="12">
          <v-row class="mt-6 mx-5">
            <v-col
              v-for="themeItem in themesList['categories'].themes"
              :key="themeItem.id"
              cols="12"
              md="4"
            >
              <div
                v-ripple
                style="position: relative"
                @click="selectTheme('categories', themeItem)"
              >
                <img
                  :src="'/themes/' + 'categories' + '/' + themeItem.id + '.png'"
                  style="width: 100%"
                  :alt="themeItem.id"
                />
                <div style="position: absolute; top: 5px; right: 5px">
                  <v-btn icon dark color="green">
                    <v-icon>
                      {{
                        myCompany.themes['categories'] &&
                        themeItem.id === myCompany.themes['categories'].id
                          ? 'mdi-check-circle'
                          : ''
                      }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch
                inset
                v-model="myCompany.themes['categories'].useV2"
                label="Utiliser la version héritée"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row align="stretch">
        <v-col cols="12 h4-content">
          <h4>
            {{ $t('settings.appearance.servicesDisplay') }}
          </h4>
          <span />
        </v-col>
        <v-col cols="12" class="text-center">
          {{ $t('settings.appearance.selectYourChoiceAccordingToThemesBelow') }}
        </v-col>
        <v-col cols="12">
          <v-row class="mt-6 mx-5">
            <v-col
              v-for="themeItem in themesList['articles'].themes"
              :key="themeItem.id"
              cols="12"
              md="4"
            >
              <div
                v-ripple
                style="position: relative"
                @click="selectTheme('articles', themeItem)"
              >
                <img
                  :src="'/themes/' + 'articles' + '/' + themeItem.id + '.png'"
                  style="width: 100%"
                  :alt="themeItem.id"
                />
                <div style="position: absolute; top: 5px; right: 5px">
                  <v-btn icon dark color="green">
                    <v-icon>
                      {{
                        myCompany.themes['articles'] &&
                        themeItem.id === myCompany.themes['articles'].id
                          ? 'mdi-check-circle'
                          : ''
                      }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-if="['default'].includes(myCompany.themes['articles'].id)"
            dense
          >
            <v-col cols="12">
              <v-switch
                inset
                v-model="myCompany.themes['articles'].useContained"
                label="Utiliser la version conteneurisée"
              />
            </v-col>
            <v-col cols="12">
              <v-switch
                inset
                v-model="myCompany.themes['articles'].useBarMode"
                label="Utiliser la version en barre"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="['cards'].includes(myCompany.themes['articles'].id)"
            dense
          >
            <v-col cols="12">
              <v-switch
                inset
                v-model="myCompany.themes['articles'].withImage"
                label="Utiliser les images"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-tab-item>

    <v-tab-item v-if="myCompany.booker.products.active" class="ma-10">
      <div class="my-5 text-center">
        <img
          :src="'/themes/' + 'eshop' + '.png'"
          style="filter: blur(1px); width: 35%"
        />
      </div>

      <v-row align="stretch">
        <v-col cols="12 h4-content">
          <h4>
            {{ $t('settings.appearance.numberArticlesLine') }}
          </h4>
          <span />
        </v-col>
        <v-col cols="12" class="text-center">
          {{ $t('settings.appearance.selectYourChoiceAccordingToThemesBelow') }}
        </v-col>
        <v-col cols="12">
          <v-row class="mt-6 mx-5">
            <v-col
              v-for="themeItem in themesList['shop'].themes"
              :key="themeItem.id"
              cols="12"
              md="4"
            >
              <div
                v-ripple
                style="position: relative"
                @click="selectTheme('shop', themeItem)"
              >
                <img
                  :src="'/themes/' + 'shop' + '/' + themeItem.id + '.png'"
                  style="width: 100%"
                  :alt="themeItem.id"
                />
                <div style="position: absolute; top: 5px; right: 5px">
                  <v-btn icon dark color="green">
                    <v-icon>
                      {{
                        myCompany.themes['shop'] &&
                        themeItem.id === myCompany.themes['shop'].id
                          ? 'mdi-check-circle'
                          : ''
                      }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-tab-item>

    <v-tab-item class="ma-10">
      <div class="my-5 text-center">
        <img
          :src="'/themes/' + 'gift' + '.png'"
          style="filter: blur(1px); width: 35%"
          alt="gift"
        />
      </div>

      <v-row align="stretch">
        <v-col cols="12 h4-content">
          <h4>
            {{ $t('settings.appearance.pageDisplay') }}
          </h4>
          <span />
        </v-col>
        <v-col cols="12" class="text-center">
          {{ $t('settings.appearance.selectYourChoiceAccordingToThemesBelow') }}
        </v-col>
        <v-col cols="12">
          <v-row class="mt-6 mx-5">
            <v-col
              v-for="themeItem in themesList['gift'].themes"
              :key="themeItem.id"
              cols="12"
              md="4"
            >
              <div
                v-ripple
                style="position: relative"
                @click="selectTheme('gift', themeItem)"
              >
                <img
                  :src="'/themes/' + 'gift' + '/' + themeItem.id + '.png'"
                  style="width: 100%"
                  :alt="themeItem.id"
                />
                <div style="position: absolute; top: 5px; right: 5px">
                  <v-btn icon dark color="green">
                    <v-icon>
                      {{
                        myCompany.themes['gift'] &&
                        themeItem.id === myCompany.themes['gift'].id
                          ? 'mdi-check-circle'
                          : ''
                      }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-tab-item>

    <v-tab-item v-if="onlineShops.length > 1" class="ma-10">
      <div class="my-5 text-center">
        <img
          :src="'/themes/' + 'contact' + '.png'"
          style="filter: blur(1px); width: 35%"
          alt="contact"
        />
      </div>

      <v-row align="stretch">
        <v-col cols="12 h4-content">
          <h4>
            {{ $t('settings.appearance.pageDisplay') }}
          </h4>
          <span />
        </v-col>
        <v-col cols="12" class="text-center">
          {{ $t('settings.appearance.selectYourChoiceAccordingToThemesBelow') }}
        </v-col>
        <v-col cols="12">
          <v-row class="mt-6 mx-5">
            <v-col
              v-for="themeItem in themesList['contact'].themes"
              :key="themeItem.id"
              cols="12"
              md="4"
            >
              <div
                v-ripple
                style="position: relative"
                @click="selectTheme('contact', themeItem)"
              >
                <img
                  :src="'/themes/' + 'contact' + '/' + themeItem.id + '.png'"
                  style="width: 100%"
                  :alt="themeItem.id"
                />
                <div style="position: absolute; top: 5px; right: 5px">
                  <v-btn icon dark color="green">
                    <v-icon>
                      {{
                        myCompany.themes['contact'] &&
                        themeItem.id === myCompany.themes['contact'].id
                          ? 'mdi-check-circle'
                          : ''
                      }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-tab-item>

    <v-tab-item class="ma-10">
      <div class="my-5 text-center">
        <img
          :src="'/themes/' + 'servicePresentation' + '.png'"
          style="filter: blur(1px); width: 35%"
          alt="servicePresentation"
        />
      </div>

      <v-row align="stretch">
        <v-col cols="12 h4-content">
          <h4>
            {{ $t('settings.appearance.serviceDisplay') }}
          </h4>
          <span />
        </v-col>
        <v-col cols="12" class="text-center">
          {{ $t('settings.appearance.selectYourChoiceAccordingToThemesBelow') }}
        </v-col>
        <v-col cols="12">
          <v-row class="mt-6 mx-5">
            <v-col
              v-for="themeItem in themesList['servicePresentation'].themes"
              :key="themeItem.id"
              cols="12"
              md="4"
            >
              <div
                v-ripple
                style="position: relative"
                @click="selectTheme('servicePresentation', themeItem)"
              >
                <img
                  :src="
                    '/themes/' +
                    'servicePresentation' +
                    '/' +
                    themeItem.id +
                    '.png'
                  "
                  style="width: 100%"
                  :alt="themeItem.id"
                />
                <div style="position: absolute; top: 5px; right: 5px">
                  <v-btn icon dark color="green">
                    <v-icon>
                      {{
                        myCompany.themes['servicePresentation'] &&
                        themeItem.id ===
                          myCompany.themes['servicePresentation'].id
                          ? 'mdi-check-circle'
                          : ''
                      }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import Vue from 'vue'
import themesHelper from '@/helpers/themes'

export default {
  name: 'appearance',
  props: {
    myCompany: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      themeTab: 0,
      themesList: themesHelper,
      themesListKeys: Object.keys(themesHelper),
      homepageType: false,
      swatches: [
        ['#FFFFFF', '#000000', '#999999'],
        ['#FFFF00', '#FF0000', '#0000FF'],
        ['#00FF00', '#FF7F00', '#50026A'],
        ['#fa2c96', '#D4AF37', '#7B3F00'],
      ],
    }
  },
  computed: {
    fusionShops: function () {
      return this.$store.getters['shop/fusionShop']({
        active: true,
        isOnline: true,
        onLineType: [],
      })
    },
    onlineShops() {
      return this.fusionShops.filter((s) => !s.draft)
    },
  },
  created() {
    this.initAppearance()
  },
  mounted() {
    if (this.onlineShops.length > 1) {
      if (this.homepageType) {
        this.myCompany.booker.homepageType = 'mkp'
      } else {
        this.myCompany.booker.homepageType = 'multi'
      }
    } else {
      this.myCompany.booker.homepageType = 'mono'
    }
  },
  methods: {
    initAppearance() {
      if (!this.myCompany.booker) {
        Vue.set(this.myCompany, 'booker', {})
      }

      if (!this.myCompany.themes) {
        Vue.set(this.myCompany, 'themes', {})
      }

      if (!this.myCompany.booker.homepageType) {
        Vue.set(this.myCompany.booker, 'homepageType', 'multi')
      } else {
        if (this.myCompany.booker.homepageType === 'multi') {
          this.homepageType = false
        }
        if (this.myCompany.booker.homepageType === 'mkp') {
          this.homepageType = true
        }
      }

      this.themesListKeys.forEach((themeKey) => {
        if (
          !this.myCompany.themes[themeKey] ||
          !Object.keys(this.myCompany.themes[themeKey]).length
        ) {
          Vue.set(
            this.myCompany.themes,
            themeKey,
            themesHelper[themeKey].themes[0]
          )
        }
      })

      if (!this.myCompany.booker.products) {
        Vue.set(this.myCompany.booker, 'products', {
          active: false,
          list: {
            display: {
              price: true,
              duration: true,
            },
          },
        })
      }

      if (typeof this.myCompany.booker.products.active === 'undefined') {
        Vue.set(this.myCompany.booker.products, 'active', false)
      }

      if (typeof this.myCompany.themes.siteContained === 'undefined') {
        Vue.set(this.myCompany.themes, 'siteContained', false)
      }

      if (
        typeof this.myCompany.themes.containedBackgroundColor === 'undefined'
      ) {
        Vue.set(this.myCompany.themes, 'containedBackgroundColor', '#ffffff')
      }
    },
    selectTheme(themeName, themeValue) {
      this.myCompany.themes[themeName] = {
        ...this.myCompany.themes[themeName],
        ...themeValue,
      }
    },
  },
  watch: {
    homepageType() {
      if (this.homepageType) {
        this.myCompany.booker.homepageType = 'mkp'
      } else {
        this.myCompany.booker.homepageType = 'multi'
      }
    },
  },
}
</script>

<style scoped></style>
