<template>
  <div>
    <template v-if="type === 'default'">
      <!--  HEADER  -->
      <div style="height: 60px" :style="getHeaderStyle">
        <div class="d-flex align-center justify-center" style="height: 30px">
          {{ nickname }}
        </div>
        <div
          style="height: 30px; font-size: 0.8rem"
          :style="getMenuStyle"
          class="d-flex align-center justify-center font-weight-light"
        >
          <span>{{ $t('settings.appearance.services') }}</span>
          <span class="mx-2">{{ $t('customMails.gift') }}</span>
          <span>{{ $t('settings.siteSettings.contact') }}</span>
        </div>
      </div>

      <!--  SUBHEADER  -->
      <div
        style="height: 40px"
        :style="{ backgroundColor: colors.colorUnderHeader }"
      />

      <!--  MAIN  -->
      <div
        style="height: 120px"
        :style="{ backgroundColor: colors.mkpBackgroundColor }"
        class="d-flex align-center justify-center"
      >
        <div
          style="
            height: 25px;
            width: 30%;
            border-radius: 2px;
            font-size: 0.8rem;
          "
          :style="{
            backgroundColor: colors.buttonsColor,
            color: getColor(colors.buttonsColor),
          }"
          class="d-flex align-center justify-center"
        >
          {{ $t('other.add') }}
        </div>
      </div>

      <!--  FOOTER  -->
      <div
        style="height: 40px"
        :style="{ backgroundColor: colors.footerColor }"
      />
    </template>

    <template v-else-if="type === 'general'">
      <!--  HEADER  -->
      <div style="height: 60px" :style="getHeaderStyle">
        <div class="d-flex align-center justify-center" style="height: 30px">
          {{ nickname }}
        </div>
        <div
          style="height: 30px; font-size: 0.8rem"
          :style="getMenuStyle"
          class="d-flex align-center justify-center font-weight-light"
        >
          <span>{{ $t('settings.appearance.services') }}</span>
          <span class="mx-2">{{ $t('customMails.gift') }}</span>
          <span>{{ $t('settings.siteSettings.contact') }}</span>
        </div>
      </div>

      <!--   SUBHEADER   -->
      <div
        style="height: 30px"
        :style="{ backgroundColor: colors.colorUnderHeader }"
        class="d-flex align-center justify-center"
      />

      <!--  MAIN  -->
      <div
        style="height: 120px"
        :style="{ backgroundColor: colors.mkpBackgroundColor }"
        class="d-flex align-center justify-center"
      >
        <div
          style="
            height: 25px;
            width: 30%;
            border-radius: 2px;
            font-size: 0.8rem;
          "
          :style="{
            backgroundColor: colors.buttonsColor,
            color: getColor(colors.buttonsColor),
          }"
          class="d-flex align-center justify-center elevation-2"
        >
          {{ $t('other.add') }}
        </div>
      </div>

      <!--  FOOTER  -->
      <div
        style="height: 40px"
        :style="{ backgroundColor: colors.footerColor }"
      />
    </template>

    <template v-else-if="type === 'reservation'">
      <!--  HEADER  -->
      <div style="height: 30px" :style="getHeaderStyle" />
      <div
        style="height: 30px"
        :style="{ backgroundColor: colors.menuColor }"
      />

      <!--   SUBHEADER   -->
      <div
        style="height: 30px"
        :style="{ backgroundColor: colors.colorUnderHeader }"
        class="d-flex align-center justify-center"
      />

      <!--  MAIN  -->
      <div
        style="height: 300px"
        :style="{ backgroundColor: colors.mkpBackgroundColor }"
      >
        <div class="d-flex justify-center align-center" style="height: 300px">
          <div style="width: 100%">
            <div
              style="height: 20px; width: 50%"
              :style="{ backgroundColor: colors.bookingAccentColor }"
              class="ma-auto d-flex align-center justify-center"
            >
              <div
                style="width: 75%; height: 2px"
                :style="{
                  backgroundColor: getColor(colors.bookingAccentColor),
                }"
                class="ma-auto"
              />
            </div>
            <div
              style="height: 20px; width: 65%"
              :style="{ backgroundColor: colors.bookingAccentColor }"
              class="my-3 ma-auto d-flex align-center justify-center"
            >
              <div
                style="width: 75%; height: 2px"
                :style="{
                  backgroundColor: getColor(colors.bookingAccentColor),
                }"
                class="ma-auto"
              />
            </div>
            <div
              style="height: 20px; width: 30%"
              :style="{ backgroundColor: colors.bookingAccentColor }"
              class="ma-auto d-flex align-center justify-center"
            >
              <div
                style="width: 75%; height: 2px"
                :style="{
                  backgroundColor: getColor(colors.bookingAccentColor),
                }"
                class="ma-auto"
              />
            </div>

            <div
              style="height: 20px; width: 80%"
              :style="{ backgroundColor: colors.bookingGeneralColor }"
              class="mt-7 mx-auto d-flex justify-space-between align-center"
            >
              <v-icon
                class="ml-1"
                size="15"
                :color="getColor(colors.bookingGeneralColor)"
              >
                mdi-arrow-left-bold-box
              </v-icon>
              <div
                v-for="n in 7"
                :key="n"
                style="width: 10px; height: 2px"
                :style="{
                  backgroundColor: getColor(colors.bookingGeneralColor),
                }"
              />
              <v-icon
                class="mr-1"
                size="15"
                :color="getColor(colors.bookingGeneralColor)"
              >
                mdi-arrow-right-bold-box
              </v-icon>
            </div>
            <div
              :style="'background: ' + colors.backgroundBookingCalendar"
              style="width: 80%; margin: 0 auto; padding: 4px 0 16px 0"
            >
              <div
                v-for="n in 5"
                :key="n"
                style="width: 80%"
                class="mt-4 mx-auto d-flex justify-space-between align-center"
              >
                <div style="width: 19px" />
                <div
                  v-for="n in 7"
                  :key="n"
                  style="width: 10px; height: 2px; background-color: #000000"
                />
                <div style="width: 19px" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  FOOTER  -->
      <div
        style="height: 40px"
        :style="{ backgroundColor: colors.footerColor }"
      />
    </template>

    <template v-else-if="type === 'subHeader'">
      <!--   HEADER   -->
      <div style="height: 30px" :style="getHeaderStyle" />
      <div
        style="height: 30px"
        :style="{ backgroundColor: colors.menuColor }"
      />

      <!--   SUBHEADER   -->
      <div
        style="height: 30px"
        :style="{ backgroundColor: colors.colorUnderHeader }"
        class="d-flex align-center justify-center"
      >
        <div
          style="height: 2px; width: 25%"
          :style="{ backgroundColor: colors.accentColor }"
        />
      </div>
      <div
        style="height: 30px"
        :style="{ backgroundColor: colors.colorUnderHeader }"
        class="d-flex align-center"
      >
        <div
          v-for="n in 7"
          :key="n"
          style="height: 2px; width: 5%"
          :style="{ backgroundColor: colors.accentColor }"
          class="mx-3"
        />
      </div>

      <!--   MAIN   -->
      <div
        style="height: 120px"
        :style="{ backgroundColor: colors.mkpBackgroundColor }"
        class="d-flex align-center justify-center"
      />

      <!--  FOOTER  -->
      <div
        style="height: 40px"
        :style="{ backgroundColor: colors.footerColor }"
      />
    </template>

    <template v-else-if="type === 'numberPerson'">
      <!--  HEADER  -->
      <div style="height: 30px" :style="getHeaderStyle" />
      <div
        style="height: 30px"
        :style="{ backgroundColor: colors.menuColor }"
      />

      <!--   SUBHEADER   -->
      <div
        style="height: 30px"
        :style="{ backgroundColor: colors.colorUnderHeader }"
        class="d-flex align-center justify-center"
      />

      <!--  MAIN  -->
      <div
        style="height: 200px"
        :style="{ backgroundColor: colors.mkpBackgroundColor }"
      >
        <div class="d-flex justify-center align-center" style="height: 100px">
          <div style="width: 100%">
            <div
              style="width: 50%; height: 2px"
              :style="{ backgroundColor: colors.dark ? '#FFFFFF' : '#000000' }"
              class="mx-auto"
            />

            <div class="d-flex align-center justify-space-around mt-5">
              <div
                style="width: 20%; height: 25px; font-size: 0.6rem"
                :style="{
                  backgroundColor: colors.buttonSolo,
                  color: getColor(colors.buttonSolo),
                }"
                class="
                  elevation-2
                  text-uppercase
                  d-flex
                  align-center
                  justify-center
                "
              >
                Solo
              </div>
              <div
                style="width: 20%; height: 25px; font-size: 0.6rem"
                :style="{
                  backgroundColor: colors.buttonDuo,
                  color: getColor(colors.buttonDuo),
                }"
                class="
                  elevation-2
                  text-uppercase
                  d-flex
                  align-center
                  justify-center
                "
              >
                Duo
              </div>
              <div
                style="width: 20%; height: 25px; font-size: 0.6rem"
                :style="{
                  backgroundColor: colors.buttonOther,
                  color: getColor(colors.buttonOther),
                }"
                class="
                  elevation-2
                  text-uppercase
                  d-flex
                  align-center
                  justify-center
                "
              >
                {{ $t('settings.colors.groups') }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  FOOTER  -->
      <div
        style="height: 40px"
        :style="{ backgroundColor: colors.footerColor }"
      />
    </template>

    <template v-else-if="type === 'events'">
      <div
        style="height: 300px; position: relative"
        :style="{ backgroundColor: colors.backgroundColorEvents }"
        class="pa-5"
      >
        <div
          style="height: 50px"
          class="white d-flex align-center justify-center"
        >
          {{ $t('image.image') }}
        </div>

        <v-row class="my-2">
          <v-col cols="2">
            <v-icon>mdi-chevron-left</v-icon>
          </v-col>
          <v-col cols="4" align-self="center">
            <div
              class="elevation-2 text-center pa-1"
              style="font-size: 0.7rem"
              :style="{
                backgroundColor: colors.datesEvents,
                color: getColor(colors.datesEvents),
              }"
            >
              {{ $t('blog.startDate') }}
            </div>
          </v-col>
          <v-col cols="4" align-self="center">
            <div
              class="elevation-2 text-center pa-1"
              style="font-size: 0.7rem"
              :style="{
                backgroundColor: colors.datesEvents,
                color: getColor(colors.datesEvents),
              }"
            >
              {{ $t('blog.endDate') }}
            </div>
          </v-col>
          <v-col cols="2">
            <v-icon>mdi-chevron-right</v-icon>
          </v-col>
        </v-row>

        <div style="font-size: 0.7rem" :style="{ color: colors.bodyEvents }">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad at enim
          id illum modi nesciunt, nulla officiis perspiciatis quas quibusdam
          saepe, sapiente. Earum expedita ipsam iusto libero magni quia
          quibusdam?
        </div>

        <v-row
          no-gutters
          style="position: absolute; bottom: 10px; width: 100%"
          class="ml-n5"
        >
          <v-col cols="3" align-self="center">
            <div
              :style="{
                backgroundColor: colors.closeBtnEvents,
                color: getColor(colors.closeBtnEvents),
              }"
              style="font-size: 0.8rem"
              class="text-center pa-1 mx-2 elevation-1"
            >
              {{ $t('icons.close') }}
            </div>
          </v-col>
          <v-col cols="6" align-self="center">
            <div
              :style="{
                color: colors.pageEvents,
              }"
              style="font-size: 0.9rem"
              class="text-center"
            >
              1/2
            </div>
          </v-col>
          <v-col cols="3" align-self="center">
            <div
              :style="{
                backgroundColor: colors.linkBtnEvents,
                color: getColor(colors.linkBtnEvents),
              }"
              style="font-size: 0.8rem"
              class="text-center pa-1 mx-2 elevation-1"
            >
              {{ $t('other.showMore') }}
            </div>
          </v-col>
        </v-row>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'preview',
  props: {
    colors: {
      type: Object,
      default: () => null,
    },
    nickname: {
      type: String,
      default: () => 'Company',
    },
    type: {
      type: String,
      default: () => 'default',
    },
  },
  computed: {
    getHeaderStyle() {
      const styles = {}

      const bgColor = this.colors.headerColor

      styles['background-color'] = bgColor

      if (this.lightOrDark(bgColor) === 'light') {
        styles['color'] = '#000000'
      } else {
        styles['color'] = '#FFFFFF'
      }

      return styles
    },
    getMenuStyle() {
      const styles = {}

      const bgColor = this.colors.menuColor

      styles['background-color'] = bgColor

      if (this.lightOrDark(bgColor) === 'light') {
        styles['color'] = '#000000'
      } else {
        styles['color'] = '#FFFFFF'
      }

      return styles
    },
  },
  methods: {
    getColor(background) {
      if (this.lightOrDark(background) === 'light') {
        return '#000000'
      } else {
        return '#FFFFFF'
      }
    },

    lightOrDark(color) {
      let r, g, b
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        )

        r = color[1]
        g = color[2]
        b = color[3]
      } else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +(
          '0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&')
        )

        r = color >> 16
        g = (color >> 8) & 255
        b = color & 255
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

      // Using the HSP value, determine whether the color is light or dark
      if (hsp > 135) {
        return 'light'
      } else {
        return 'dark'
      }
    },
  },
}
</script>

<style scoped></style>
