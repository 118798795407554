<template>
  <div>
    <div
      v-if="
        $store.state.auth.user.role === 'cmpadmin' ||
          $store.state.auth.user.acl.includes('can_access_shops')
      "
    >
      <v-overlay
        class="d-flex justify-center align-center"
        v-if="loading"
        absolute
      >
        <v-progress-circular indeterminate />
      </v-overlay>

      <!--  HOME  -->
      <module-list-light v-if="!manageGeoGroups && !showNewOnlineShop" key="1">
        <template v-slot:actionBlock>
          <v-btn-toggle v-model="selectedStatus" mandatory @change="search">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon :color="$icones['all'].color">
                    {{ $icones['all'].icon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('shops.allShops') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon :color="$icones['onLine'].color">
                    {{ $icones['onLine'].icon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('shops.onlineShops') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon :color="$icones['draft'].color">
                    {{ $icones['draft'].icon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('shops.draftShops') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon :color="$icones['offLine'].color">
                    {{ $icones['offLine'].icon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('shops.offlineShops') }}</span>
            </v-tooltip>
          </v-btn-toggle>

          <v-text-field
            v-model="selectedSociety"
            :label="
              $t('database.tables.society') + ' / ' + $t('database.tables.sign')
            "
            background-color="white"
            outlined
            hide-details
            dense
            @keypress.enter="search"
          />

          <v-select
            v-model="selectedGeoGroup"
            :items="tabNameGeoGroups"
            :label="$t('database.tables.geoGroup')"
            background-color="white"
            outlined
            hide-details
            dense
            style="width: 175px"
            multiple
            class="customWidth"
          />

          <v-text-field
            v-model="selectedCode"
            :label="$t('database.tables.postalCode')"
            background-color="white"
            outlined
            hide-details
            dense
            @keypress.enter="search"
          />

          <v-text-field
            v-model="selectedLocality"
            :label="$t('database.tables.locality')"
            background-color="white"
            outlined
            hide-details
            dense
            @keypress.enter="search"
          />

          <v-btn dark @click="search">
            {{ $t('other.research') }}
          </v-btn>

          <v-btn dark @click="exportCSV">
            {{ $t('other.exportCSV') }}
          </v-btn>

          <v-btn
            v-if="getNewOnlineShop.length && $vuetify.breakpoint.smAndDown"
            class="my-auto"
            dark
            dense
            @click="showNewOnlineShop = true"
          >
            {{ $t('shops.latestShopsPosted') }}
          </v-btn>
        </template>

        <template v-slot:actionSearch="{ isMobile }">
          <v-btn dark @click="manageGeoGroups = true" v-if="isMobile">
            {{ $t('shops.manageGeoGroups') }}
          </v-btn>
        </template>

        <template v-slot:manageContent="{ isMobile }">
          <div class="d-flex justify-end" v-if="!isMobile">
            <v-btn
              v-if="getNewOnlineShop.length"
              class="my-auto"
              dark
              dense
              @click="showNewOnlineShop = true"
            >
              {{ $t('shops.latestShopsPosted') }}
            </v-btn>
            <v-spacer v-if="getNewOnlineShop.length" />
            <v-btn class="ma-2" dark @click="manageGeoGroups = true">
              {{ $t('shops.manageGeoGroups') }}
            </v-btn>
          </div>
        </template>

        <template v-slot:contentBlock>
          <v-card>
            <v-data-table
              :headers="abHeaders"
              :items="
                shops.filter(
                  s =>
                    $store.state.auth.user.role === 'cmpadmin' ||
                    $store.state.auth.user.aclShops.includes(s.id)
                )
              "
              :items-per-page="abItemsPerPage"
              :page.sync="abPage"
              group-by="grp.name"
              :hide-default-footer="$vuetify.breakpoint.mdAndUp"
              @page-count="abPageCount = $event"
              class="data-table"
              mobile-breakpoint="1100"
              fixed-header
              height="50vh"
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-row>
                  <v-col cols="6">
                    <icon-display
                      v-if="!getLocalShop(item.abId)"
                      @actionClick="publishShop(item)"
                      :icon="$icones.offLine"
                      :small="false"
                      :tool-tip="$t('icons.putInDraft')"
                    />
                    <icon-display
                      v-else-if="item.draft"
                      @actionClick="undraftShop(item)"
                      :icon="$icones.draft"
                      :small="false"
                      :tool-tip="$t('other.upload')"
                    />
                    <icon-display
                      v-else
                      @actionClick="draftShop(item)"
                      :icon="$icones.onLine"
                      :small="false"
                      :tool-tip="$t('icons.putInDraft')"
                    />
                  </v-col>
                  <v-col v-if="missingSettings(item)" cols="6">
                    <v-tooltip right>
                      <template v-slot:activator="{ attr, on }">
                        <span
                          v-bind="attr"
                          v-on="on"
                          class="red rounded-circle white--text py-1 px-2"
                          style="font-size: 0.8rem"
                        >
                          {{ missingSettings(item) }}
                        </span>
                      </template>
                      <span>{{ $t('icons.missingElements') }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <icon-display
                  v-if="item.booker && item.booker.active"
                  :icon="$icones.booker"
                />
                <span class="mr-2">{{ item.name }}</span>
                <icon-display
                  v-if="item.passport && item.passport.active"
                  :icon="$icones.passbeaute"
                />
              </template>
              <template v-slot:[`item.geoGroupment.id`]="{ item }">
                <div v-if="getLocalShop(item.abId)">
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :loading="
                          geoLoading.findIndex(id => id === item.id) !== -1
                        "
                        class="update-gg"
                        text
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span v-if="item.geoGroupment">
                          {{
                            item.geoGroupment.name.fr.split(' ')[
                              item.geoGroupment.name.fr.split(' ').length - 1
                            ]
                          }}
                        </span>
                        <span class="add-gg" v-else>{{ $t('other.add') }}</span>
                        <v-icon> mdi-chevron-down </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(geo, i) in geoGroupments"
                        :key="i"
                        @click="inputGeoGroupments(geo.id, item.id, true)"
                      >
                        <v-list-item-title>
                          {{
                            geo.name.fr.split(' ')[
                              geo.name.fr.split(' ').length - 1
                            ]
                          }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn
                    v-if="item.geoGroupment"
                    @click="
                      inputGeoGroupments(item.geoGroupment.id, item.id, false)
                    "
                    color="black"
                    icon
                    x-small
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div v-if="getLocalShop(item.abId)" class="content-icon-tab">
                  <v-btn
                    @click="editedItem(item)"
                    class="btn-tab"
                    color="#433883"
                    outlined
                  >
                    <v-icon>mdi-clipboard-edit-outline</v-icon>
                    <span>{{ $t('other.edit') }}</span>
                  </v-btn>

                  <v-badge
                    v-if="item.articles && !item.articles.length"
                    color="red"
                    dot
                  >
                    <icon-display
                      :small="false"
                      @actionClick="linkArticles(item)"
                      :toolTip="$t('shops.itemsOfferedInShop')"
                      :icon="$icones.article"
                    />
                  </v-badge>
                  <icon-display
                    v-else
                    :small="false"
                    @actionClick="linkArticles(item)"
                    :toolTip="$t('shops.itemsOfferedInShop')"
                    :icon="$icones.article"
                  />
                </div>
              </template>
              <template
                v-slot:[`group.header`]="{ group, toggle, isOpen, items }"
              >
                <td :colspan="abHeaders.length + 1" class="grey darken-2">
                  <div class="d-inline">
                    <v-btn icon @click="toggle">
                      <v-icon color="white" v-if="isOpen"
                        >mdi-chevron-up</v-icon
                      >
                      <v-icon color="white" v-if="!isOpen"
                        >mdi-chevron-down</v-icon
                      >
                    </v-btn>
                    <span
                      v-if="items.length === 1"
                      style="color: white; font-weight: bold"
                    >
                      {{ group }} - {{ items.length }} {{ $t('shops.shop') }}
                    </span>
                    <span v-else style="color: white; font-weight: bold">
                      {{ group }} - {{ items.length }} {{ $t('shops.shops') }}
                    </span>
                  </div>
                  <v-divider v-if="!isOpen" dark></v-divider>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </template>

        <template v-slot:footerBlock>
          <div class="d-flex justify-center">
            <v-pagination
              v-if="abPageCount > 1"
              v-model="abPage"
              :length="abPageCount"
            />
          </div>
          <v-row no-gutters>
            <v-col cols="4" align-self="center">
              <div class="d-flex align-center justify-space-around">
                <div>
                  <v-icon :color="$icones['onLine'].color">
                    {{ $icones['onLine'].icon }}
                  </v-icon>
                  {{ $t('icons.online') }}
                </div>
                <div>
                  <v-icon :color="$icones['draft'].color">
                    {{ $icones['draft'].icon }}
                  </v-icon>
                  {{ $t('icons.draft') }}
                </div>
                <div>
                  <v-icon :color="$icones['offLine'].color">
                    {{ $icones['offLine'].icon }}
                  </v-icon>
                  {{ $t('icons.offline') }}
                </div>
              </div>
            </v-col>
            <v-col cols="4">
              <div class="d-flex justify-center mt-2">
                <div>
                  <v-select
                    v-model="abItemsPerPage"
                    :items="selectAbItemsPerPage"
                    background-color="white"
                    outlined
                    hide-details
                    dense
                    class="customWidth"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </template>
      </module-list-light>

      <!--   NEW SHOPS ONLINE   -->
      <module-list-light
        v-else-if="!manageGeoGroups && showNewOnlineShop"
        key="2"
      >
        <template v-slot:actionBlock>
          <v-text-field
            v-model="selectedSociety"
            :label="
              $t('database.tables.society') + ' / ' + $t('database.tables.sign')
            "
            background-color="white"
            outlined
            hide-details
            dense
            @keypress.enter="search"
          />

          <v-select
            v-model="selectedGeoGroup"
            :items="tabNameGeoGroups"
            :label="$t('database.tables.geoGroup')"
            background-color="white"
            outlined
            hide-details
            dense
            style="width: 175px"
            multiple
            class="customWidth"
          />

          <v-text-field
            v-model="selectedCode"
            :label="$t('database.tables.postalCode')"
            background-color="white"
            outlined
            hide-details
            dense
            @keypress.enter="search"
          />

          <v-text-field
            v-model="selectedLocality"
            :label="$t('database.tables.locality')"
            background-color="white"
            outlined
            hide-details
            dense
            @keypress.enter="search"
          />

          <v-btn dark @click="search">
            {{ $t('other.research') }}
          </v-btn>

          <v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            class="my-auto"
            dark
            dense
            @click="showNewOnlineShop = false"
          >
            {{ $t('shops.showingAllShops') }}
          </v-btn>
        </template>

        <template v-slot:actionSearch v-if="$vuetify.breakpoint.smAndDown">
          <v-btn dark @click="manageGeoGroups = true">
            {{ $t('shops.manageGeoGroups') }}
          </v-btn>
        </template>

        <template v-slot:manageContent v-else>
          <div class="d-flex justify-end">
            <v-btn
              class="my-auto"
              dark
              dense
              @click="showNewOnlineShop = false"
            >
              {{ $t('shops.showingAllShops') }}
            </v-btn>
            <v-spacer />
            <v-btn class="ma-2" dark @click="manageGeoGroups = true">
              {{ $t('shops.manageGeoGroups') }}
            </v-btn>
          </div>
        </template>

        <template v-slot:contentBlock>
          <v-card>
            <v-data-table
              :headers="abHeaders"
              :items="
                tabNewShops.filter(
                  s =>
                    $store.state.auth.user.role === 'cmpadmin' ||
                    $store.state.auth.user.aclShops.includes(s.id)
                )
              "
              :items-per-page="abItemsPerPage"
              :page.sync="abPage"
              :hide-default-footer="$vuetify.breakpoint.mdAndUp"
              @page-count="abPageCount = $event"
              class="data-table"
              mobile-breakpoint="1100"
              fixed-header
              height="55vh"
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-row>
                  <v-col cols="6">
                    <icon-display
                      v-if="!getLocalShop(item.abId)"
                      @actionClick="publishShop(item)"
                      :icon="$icones.offLine"
                      :small="false"
                      :tool-tip="$t('icons.putInDraft')"
                    />
                    <icon-display
                      v-else-if="item.draft"
                      @actionClick="undraftShop(item)"
                      :icon="$icones.draft"
                      :small="false"
                      :tool-tip="$t('other.upload')"
                    />
                    <icon-display
                      v-else
                      @actionClick="draftShop(item)"
                      :icon="$icones.onLine"
                      :small="false"
                      :tool-tip="$t('icons.putInDraft')"
                    />
                  </v-col>
                  <v-col v-if="missingSettings(item)" cols="6">
                    <v-tooltip right>
                      <template v-slot:activator="{ attr, on }">
                        <span
                          v-bind="attr"
                          v-on="on"
                          class="red rounded-circle white--text py-1 px-2"
                          style="font-size: 0.8rem"
                        >
                          {{ missingSettings(item) }}
                        </span>
                      </template>
                      <span>{{ $t('icons.missingElements') }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <icon-display
                  v-if="item.booker && item.booker.active"
                  :icon="$icones.booker"
                />
                <span class="mr-2">{{ item.name }}</span>
                <icon-display
                  v-if="item.passport && item.passport.active"
                  :icon="$icones.passbeaute"
                />
              </template>
              <template v-slot:[`item.geoGroupment.id`]="{ item }">
                <div v-if="getLocalShop(item.abId)">
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :loading="
                          geoLoading.findIndex(id => id === item.id) !== -1
                        "
                        class="update-gg"
                        text
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span v-if="item.geoGroupment">
                          {{
                            item.geoGroupment.name.fr.split(' ')[
                              item.geoGroupment.name.fr.split(' ').length - 1
                            ]
                          }}
                        </span>
                        <span class="add-gg" v-else>{{ $t('other.add') }}</span>
                        <v-icon> mdi-chevron-down </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(geo, i) in geoGroupments"
                        :key="i"
                        @click="inputGeoGroupments(geo.id, item.id, true)"
                      >
                        <v-list-item-title>
                          {{
                            geo.name.fr.split(' ')[
                              geo.name.fr.split(' ').length - 1
                            ]
                          }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn
                    v-if="item.geoGroupment"
                    @click="
                      inputGeoGroupments(item.geoGroupment.id, item.id, false)
                    "
                    color="black"
                    icon
                    x-small
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div v-if="getLocalShop(item.abId)" class="content-icon-tab">
                  <v-btn
                    @click="editedItem(item)"
                    class="btn-tab"
                    color="#433883"
                    outlined
                  >
                    <v-icon>mdi-clipboard-edit-outline</v-icon>
                    <span>{{ $t('other.edit') }}</span>
                  </v-btn>

                  <v-badge
                    v-if="item.articles && !item.articles.length"
                    color="red"
                    dot
                  >
                    <icon-display
                      :small="false"
                      @actionClick="linkArticles(item)"
                      :toolTip="$t('shops.itemsOfferedInShop')"
                      :icon="$icones.article"
                    />
                  </v-badge>
                  <icon-display
                    v-else
                    :small="false"
                    @actionClick="linkArticles(item)"
                    :toolTip="$t('shops.itemsOfferedInShop')"
                    :icon="$icones.article"
                  />
                </div>
              </template>
            </v-data-table>
          </v-card>
        </template>

        <template v-slot:footerBlock>
          <div class="d-flex justify-center">
            <v-pagination
              v-if="abPageCount > 1"
              v-model="abPage"
              :length="abPageCount"
            />
          </div>
          <v-row no-gutters>
            <v-col cols="4" align-self="center">
              <div class="d-flex align-center justify-space-around">
                <div>
                  <v-icon :color="$icones['onLine'].color">
                    {{ $icones['onLine'].icon }}
                  </v-icon>
                  {{ $t('icons.online') }}
                </div>
                <div>
                  <v-icon :color="$icones['draft'].color">
                    {{ $icones['draft'].icon }}
                  </v-icon>
                  {{ $t('icons.draft') }}
                </div>
                <div>
                  <v-icon :color="$icones['offLine'].color">
                    {{ $icones['offLine'].icon }}
                  </v-icon>
                  {{ $t('icons.offline') }}
                </div>
              </div>
            </v-col>
            <v-col cols="4">
              <div class="d-flex justify-center mt-2">
                <div>
                  <v-select
                    v-model="abItemsPerPage"
                    :items="selectAbItemsPerPage"
                    background-color="white"
                    outlined
                    hide-details
                    dense
                    class="customWidth"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </template>
      </module-list-light>

      <!--  GESTION DES GEOGROUPEMENTS  -->
      <module-list-light v-else key="3">
        <template v-slot:actionBlock>
          <v-text-field
            v-model="selectedNameGeo"
            :label="$t('other.name')"
            background-color="white"
            outlined
            hide-details
            dense
            @keypress.enter="searchGeo"
          />

          <v-btn dark @click="searchGeo">
            {{ $t('other.research') }}
          </v-btn>
        </template>

        <template v-slot:contentBlock>
          <div>
            <geogroupements-manager
              :geo-groupments="tabGeoGroups"
              @update="emitUpdate"
              @back="manageGeoGroups = false"
            />
          </div>
        </template>
      </module-list-light>

      <!--  EDIT PANEL SHOP  -->
      <edit-panel
        :display="displayShopInfo"
        :title="shopSel ? shopSel.name + ' - ' + shopSel.country : 'edition'"
        @close="displayShopInfo = false"
        @actionModal="actionForm"
      >
        <template v-slot:body>
          <shop-edit
            v-model="displayShopInfo"
            :submit="submitAction"
            :item="shopSel"
            @isSubmit="isSubmit"
            ref="shopEdit"
          />
        </template>
      </edit-panel>

      <!--  DIALOG LINK ARTICLES  -->
      <v-dialog v-model="linkArticlesDialog" width="950">
        <link-articles-to-shop
          :link-articles-shop="linkArticlesShop"
          :online-articles="onlineArticles"
          :loading="loadingLink"
          @close="linkArticlesDialog = false"
          @publish="publishArticle"
          @unpublish="unpublishArticle"
          @publishAll="linkAllArticles"
          @unpublishAll="unlinkAllArticles"
        />
      </v-dialog>
    </div>
    <div v-else class="text-center my-12 py-12">
      <p>
        Vous n'avez pas accès à cette page.
      </p>
    </div>
  </div>
</template>

<script>
import layoutHome from '@/layouts/layout-home.vue'
import { mapGetters } from 'vuex'
import shopEdit from '../../../components/database/shops/shopEdit.vue'
import swal from 'sweetalert'
import IconDisplay from '../../../components/helpers/iconDisplay.vue'
import ModuleListLight from '../../../components/general/moduleListLight.vue'
import EditPanel from '@/components/helpers/edit-panel'
import icones from '@/helpers/icones'
import { controli18n } from '@/helpers/langs'
import GeogroupementsManager from '@/components/database/geogroupments/geogroupementsManager'
import LinkArticlesToShop from '../../../components/database/shops/linkArticlesToShop'

export default {
  components: {
    LinkArticlesToShop,
    GeogroupementsManager,
    shopEdit,
    IconDisplay,
    ModuleListLight,
    EditPanel
  },
  data: () => {
    return {
      submitAction: false,
      displayShopInfo: false,
      shopSel: null,
      shopAction: null,
      abPage: 1,
      abPageCount: 0,
      abItemsPerPage: 50,
      shopsFiltered: {
        active: false,
        isOnline: null,
        onLineType: []
      },
      geoLoading: [],
      linkArticlesDialog: false,
      linkArticlesShop: null,
      articles: [],
      articlesCategories: [],
      loading: false,
      loadingLink: false,
      shops: [],
      tabStatus: ['all', 'onLine', 'offLine'],
      selectedStatus: 0,
      selectedSociety: null,
      tabGeoGroups: [],
      selectedGeoGroup: null,
      selectedCode: null,
      selectedLocality: null,
      selectedNameGeo: null,
      manageGeoGroups: false,
      showNewOnlineShop: false,
      tabNewShops: []
    }
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  computed: {
    ...mapGetters({
      geoGroupments: 'geogroupments/getGeogroupments',
      getNewOnlineShop: 'shop/getNewOnlineShop'
    }),
    fusionShops: function() {
      return this.$store.getters['shop/fusionShop'](this.shopsFiltered)
    },
    onlineArticles() {
      return this.articles
        .filter(a => [1, 2, 4].includes(parseInt(a.art_type)))
        .filter(a => !!a.id)
        .filter(a => !a.draft)
    },
    selectAbItemsPerPage() {
      return [
        10,
        25,
        50,
        100,
        {
          text: this.$t('other.all'),
          value: -1
        }
      ]
    },
    abHeaders() {
      return [
        {
          text: this.$t('database.tables.status'),
          value: 'status',
          width: '10%',
          divider: true,
          sortable: false,
          align: 'center'
        },
        {
          text: this.$t('database.tables.society'),
          value: 'society',
          width: '15%',
          divider: true,
          sortable: true,
          align: 'start'
        },
        {
          text: this.$t('database.tables.sign'),
          value: 'name',
          width: '15%',
          divider: true,
          sortable: true,
          align: 'start'
        },
        {
          text: this.$t('database.tables.geoGroup'),
          value: 'geoGroupment.id',
          width: '18%',
          divider: true,
          sortable: false
        },
        {
          text: this.$t('database.tables.postalCode'),
          value: 'zipcode',
          width: '12%',
          divider: true,
          sortable: true,
          align: 'start'
        },
        {
          text: this.$t('database.tables.locality'),
          value: 'city',
          width: '15%',
          divider: true,
          sortable: true,
          align: 'start'
        },
        /*
        {
          text: this.$t('database.tables.telephone'),
          value: 'phone',
          width: '10%',
          divider: true,
          sortable: false,
          align: 'start'
        },
          */
        {
          text: '',
          value: 'actions',
          width: '15%',
          divider: true,
          sortable: false
        }
      ]
    },
    $icones() {
      return icones().getIcones()
    },
    tabNameGeoGroups() {
      return this.geoGroupments.map(geo => {
        return this.controli18n(this.$i18n.locale, geo.name)
      })
    }
  },
  methods: {
    controli18n,
    exportCSV() {
      let str =
        'id,slug,name,phone,siret,address,city,country,zipcode,lat,lng\r\n'

      const ss = this.shops.map(s => ({
        id: s.id,
        slug: s.slug,
        name: s.publicInfo.name,
        phone: s.publicInfo.phone,
        siret: s.publicInfo.siret,
        address: s.publicInfo.address.address,
        city: s.publicInfo.address.city,
        country: s.publicInfo.address.country,
        zipcode: s.publicInfo.address.zipcode,
        lat: s.localisation.coordonate.lat,
        lng: s.localisation.coordonate.lng
      }))

      for (let i = 0; i < ss.length; i++) {
        let line = ''
        for (let index in ss[i]) {
          if (line != '') line += ','

          line += ss[i][index] ? (ss[i][index] + '').split(',').join('') : ''
        }

        str += line + '\r\n'
      }

      const downloadLink = document.createElement('a')
      const blob = new Blob(['\ufeff', str])
      const url = URL.createObjectURL(blob)
      downloadLink.href = url
      downloadLink.download = 'export-shops.csv'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    },
    actionForm(value) {
      if (value) {
        this.submitAction = true
      } else {
        this.displayShopInfo = false
      }
    },
    isSubmit(value) {
      if (value) this.displayShopInfo = false
      this.submitAction = false
      this.emitUpdate()
    },
    getLocalShop(abID) {
      const localShop = this.$store.state.shop.shops.find(
        s => s.artebeauteId === abID || s.abId === abID
      )

      return localShop
    },
    editedItem(val) {
      this.shopSel = val
      this.shopAction = 'edited'
      this.displayShopInfo = true
      this.displayAlertLastShop = false
    },
    linkArticles(shop) {
      if (!shop.articles) {
        shop = { ...shop, articles: [] }
      }

      this.linkArticlesShop = this.$store.getters['shop/fusionShop']({
        active: true,
        isOnline: true,
        onLineType: []
      }).find(s => s.abId === shop.abId)

      this.linkArticlesDialog = true
    },
    majArticles() {
      this.articles = this.$store.getters['articles/fusionArticle']()

      if (this.articles) {
        this.onlineArticles.map(art => {
          if (art.categories.length) {
            art.categories.map(cat => {
              if (!this.articlesCategories.includes(cat.name.fr)) {
                this.articlesCategories.push(cat.name.fr)
              }
            })
          }
        })
        this.articlesCategories.sort()
      }
    },
    search() {
      if (!this.showNewOnlineShop) {
        if (this.selectedStatus === 0) {
          this.shopsFiltered.active = false
        } else if (this.selectedStatus === 1 || this.selectedStatus === 2) {
          this.shopsFiltered.active = true
          this.shopsFiltered.isOnline = true
        } else if (this.selectedStatus === 3) {
          this.shopsFiltered.active = true
          this.shopsFiltered.isOnline = false
        }

        let shops = this.fusionShops

        if (this.selectedStatus === 1) {
          shops = shops.filter(s => !s.draft)
        } else if (this.selectedStatus === 2) {
          shops = shops.filter(s => s.draft)
        }

        if (this.selectedSociety) {
          shops = shops.filter(
            shop =>
              shop.society
                .toUpperCase()
                .includes(this.selectedSociety.toUpperCase()) ||
              shop.name
                .toUpperCase()
                .includes(this.selectedSociety.toUpperCase())
          )
        }

        if (this.selectedGeoGroup && this.selectedGeoGroup.length) {
          shops = shops.filter(
            shop =>
              shop.geoGroupment &&
              this.selectedGeoGroup.includes(
                this.controli18n(this.$i18n.locale, shop.geoGroupment.name)
              )
          )
        }

        if (this.selectedCode) {
          shops = shops.filter(shop => shop.zipcode.includes(this.selectedCode))
        }

        if (this.selectedLocality) {
          shops = shops.filter(shop =>
            shop.city
              .toUpperCase()
              .includes(this.selectedLocality.toUpperCase())
          )
        }

        this.shops = shops
      } else {
        let shops = this.getNewOnlineShop

        if (this.selectedSociety) {
          shops = shops.filter(
            shop =>
              shop.society
                .toUpperCase()
                .includes(this.selectedSociety.toUpperCase()) ||
              shop.name
                .toUpperCase()
                .includes(this.selectedSociety.toUpperCase())
          )
        }

        if (this.selectedGeoGroup && this.selectedGeoGroup.length) {
          shops = shops.filter(
            shop =>
              shop.geoGroupment &&
              this.selectedGeoGroup.includes(
                this.controli18n(this.$i18n.locale, shop.geoGroupment.name)
              )
          )
        }

        if (this.selectedCode) {
          shops = shops.filter(shop => shop.zipcode.includes(this.selectedCode))
        }

        if (this.selectedLocality) {
          shops = shops.filter(shop =>
            shop.city
              .toUpperCase()
              .includes(this.selectedLocality.toUpperCase())
          )
        }

        this.tabNewShops = shops
      }
    },
    searchGeo() {
      this.tabGeoGroups = this.geoGroupments

      if (this.selectedNameGeo) {
        this.tabGeoGroups = this.tabGeoGroups.filter(geo =>
          this.controli18n(this.$i18n.locale, geo.name)
            .toUpperCase()
            .includes(this.selectedNameGeo.toUpperCase())
        )
      }
    },
    emitUpdate() {
      this.tabNewShops = this.getNewOnlineShop
      this.search()
    },
    missingSettings(item) {
      if (!item.id) {
        return
      }
      let missing = 0

      if (!item.name) {
        missing++
      }

      if (!item.slug) {
        missing++
      }

      if (item.articles && !item.articles.length) {
        missing++
      }

      if (item.publicInfo && !item.publicInfo.email) {
        missing++
      }

      if (item.publicInfo && !item.publicInfo.phone) {
        missing++
      }

      if (
        item.publicInfo &&
        item.publicInfo.address &&
        !item.publicInfo.address.address
      ) {
        missing++
      }

      if (
        item.publicInfo &&
        item.publicInfo.address &&
        !item.publicInfo.address.city
      ) {
        missing++
      }

      if (
        item.publicInfo &&
        item.publicInfo.address &&
        !item.publicInfo.address.country
      ) {
        missing++
      }

      if (
        item.publicInfo &&
        item.publicInfo.address &&
        !item.publicInfo.address.zipcode
      ) {
        missing++
      }

      let closed = true
      Object.keys(item.openingHours).forEach(key => {
        if (item.openingHours[key].status !== 'closed') {
          closed = false
        }
      })
      if (closed) {
        missing++
      }

      if (
        (item.localisation &&
          item.localisation.coordonate &&
          (!item.localisation.coordonate.lat ||
            !item.localisation.coordonate.lng)) ||
        (item.localisation.coordonate.lat === 48.856614 &&
          item.localisation.coordonate.lng === 2.3522219)
      ) {
        missing++
      }

      if (missing > 0) {
        return missing
      }
    },

    async inputGeoGroupments(value, shop, type) {
      this.geoLoading.push(shop)
      if (type) {
        await this.linkGeogroupment({ id: value }, { id: shop })
      } else {
        await this.unlinkGeogroupment({ id: value }, { id: shop })
      }
      this.geoLoading.splice(
        this.geoLoading.findIndex(item => item === shop),
        1
      )
      this.search()
    },
    async linkGeogroupment(geogroupment, shop) {
      const result = await this.$store.dispatch(
        'geogroupments/linkGeogroupment',
        { geogroupment, shop }
      )

      if (!result) {
        swal({
          title: this.$t('shops.impossibleLinkGroup'),
          icon: 'error'
        })
        return false
      } else {
        return true
      }
    },
    async unlinkGeogroupment(geogroupment, shop) {
      const result = await this.$store.dispatch(
        'geogroupments/unlinkGeogroupment',
        { geogroupment, shop }
      )

      if (!result) {
        swal({
          title: this.$t('shops.impossibleUnlinkGroup'),
          icon: 'error'
        })
        return false
      } else {
        return true
      }
    },

    async publishShop(shop) {
      swal({
        title: this.$t('shops.shopEnable', { name: shop.name }),
        buttons: [this.$t('icons.cancel'), this.$t('other.add')]
      }).then(async ok => {
        if (ok) {
          this.loading = true

          const result = await this.$store.dispatch('shop/publishShop', shop)

          if (!result) {
            swal({
              title: this.$t('shops.unablePutShopOnline'),
              icon: 'error'
            })
          } else {
            this.emitUpdate()

            await this.changeThemeHome()
          }

          this.loading = false
        }
      })
    },
    async unpublishShop(localShop) {
      swal({
        title: this.$t('shops.shopPutOffline', {
          name: localShop.publicInfo.name
        }),
        buttons: [this.$t('icons.cancel'), this.$t('other.putOffline')]
      }).then(async ok => {
        if (ok) {
          this.loading = true

          const result = await this.$store.dispatch(
            'shop/unpublishShop',
            localShop
          )

          if (!result) {
            swal({
              title: this.$t('shops.unablePutShopOffline'),
              icon: 'error'
            })
          } else {
            this.emitUpdate()

            await this.changeThemeHome()
          }

          this.loading = false
        }
      })
    },
    async undraftShop(item) {
      swal({
        title: this.$t('shops.shopUpload', {
          name: item.name
        }),
        buttons: [this.$t('icons.cancel'), this.$t('other.upload')]
      }).then(async ok => {
        if (ok) {
          this.loading = true

          try {
            await this.$store.dispatch('shop/undraftShop', item)
            this.emitUpdate()

            await this.changeThemeHome()
          } catch (err) {
            console.log('err :>> ', err)
          }

          this.loading = false
        }
      })
    },
    async draftShop(item) {
      swal({
        title: this.$t('shops.shopPutInDraft', {
          name: item.name
        }),
        buttons: [this.$t('icons.cancel'), this.$t('icons.putInDraft')]
      }).then(async ok => {
        if (ok) {
          this.loading = true

          try {
            await this.$store.dispatch('shop/draftShop', item)
            this.emitUpdate()

            await this.changeThemeHome()
          } catch (err) {
            console.log('err :>> ', err)
          }

          this.loading = false
        }
      })
    },

    async publishArticle(art) {
      this.loadingLink = true

      try {
        await this.$store.dispatch('shop/linkArticle', {
          shop: this.linkArticlesShop,
          article: art
        })
        this.linkArticlesShop = this.$store.getters['shop/fusionShop']({
          active: true,
          isOnline: true,
          onLineType: []
        }).find(shop => shop.abId === this.linkArticlesShop.abId)
        this.majArticles()
      } catch (err) {
        console.log('err :>> ', err)
      }

      this.loadingLink = false
    },
    async unpublishArticle(art) {
      this.loadingLink = true

      try {
        await this.$store.dispatch('shop/unlinkArticle', {
          shop: this.linkArticlesShop,
          article: art
        })
        this.linkArticlesShop = this.$store.getters['shop/fusionShop']({
          active: true,
          isOnline: true,
          onLineType: []
        }).find(shop => shop.abId === this.linkArticlesShop.abId)
        this.majArticles()
      } catch (err) {
        console.log('err :>> ', err)
      }

      this.loadingLink = false
    },
    async linkAllArticles() {
      this.loadingLink = true
      await Promise.all(
        this.onlineArticles.map(async art => {
          if (!this.linkArticlesShop.articles.find(a => a.id === art.id)) {
            await this.$store.dispatch('shop/linkArticle', {
              shop: this.linkArticlesShop,
              article: art
            })
          }
        })
      )
      this.linkArticlesShop = this.$store.getters['shop/fusionShop']({
        active: true,
        isOnline: true,
        onLineType: []
      }).find(shop => shop.abId === this.linkArticlesShop.abId)
      this.majArticles()
      this.loadingLink = false
    },
    async unlinkAllArticles() {
      this.loadingLink = true

      await Promise.all(
        this.onlineArticles.map(async art => {
          if (this.linkArticlesShop.articles.find(a => a.id === art.id)) {
            await this.$store.dispatch('shop/unlinkArticle', {
              shop: this.linkArticlesShop,
              article: art
            })
          }
        })
      )

      this.linkArticlesShop = this.$store.getters['shop/fusionShop']({
        active: true,
        isOnline: true,
        onLineType: []
      }).find(shop => shop.abId === this.linkArticlesShop.abId)
      this.majArticles()
      this.loadingLink = false
    },

    async changeThemeHome() {
      const nbrShops = this.$store.getters['shop/fusionShop']({
        active: true,
        isOnline: true,
        onLineType: []
      }).filter(s => !s.draft).length

      const myCompany = this.$store.state.auth.company

      if (
        (nbrShops > 1 && myCompany.booker.homepageType === 'mono') ||
        nbrShops === 1
      ) {
        let companyData = {
          ...myCompany
        }
        delete companyData.id
        delete companyData.nickname
        delete companyData.owner
        delete companyData.createdAt

        if (nbrShops > 1 && myCompany.booker.homepageType === 'mono') {
          companyData.booker.homepageType = 'multi'
        } else if (nbrShops === 1) {
          companyData.booker.homepageType = 'mono'
        }

        await this.$store.dispatch('auth/updateCompany', {
          id: myCompany.id,
          data: companyData
        })
      }
    }
  },

  async mounted() {
    try {
      this.loaded = false

      await this.$store.dispatch('geogroupments/fetchGeogroupments', {})

      this.loaded = true
    } catch (err) {
      console.log('err :>> ', err)
    }
    this.majArticles()
    this.shops = this.fusionShops
    this.tabGeoGroups = this.geoGroupments
    this.search()
  },
  watch: {
    linkArticlesDialog(val) {
      if (val === false) {
        this.emitUpdate()
      }
    }
  }
}
</script>

<style>
.customWidth input {
  display: none;
}

@media screen and (min-width: 1100px) {
  .data-table table {
    table-layout: fixed;
  }
}
</style>

<style scoped>
/* .btn-toggle {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
} */

.data-table >>> .v-data-table__divider {
  border-right: none;
}

.content-icon-tab {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.btn-tab >>> .v-btn__content {
  column-gap: 8px;
}

.btn-tab span {
  font-weight: 700;
  letter-spacing: normal;
}

.update-gg.v-btn {
  padding: 2px 5px;
  height: auto;
  margin-right: 5px;
}

.update-gg span {
  color: black;
  letter-spacing: normal;
  text-transform: none;
  margin-right: 10px;
  width: 70px;
  text-align: left;
}

.update-gg span.add-gg {
  color: rgb(150, 150, 150);
}

.cardTitle {
  font-size: 1.2rem !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
