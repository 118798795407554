<template>
  <div>
    <v-switch
      inset
      v-model="myCompany.booker.gift"
      :label="$t('settings.gift.enableSaleGift')"
    />

    <v-switch
      inset
      v-model="myCompany.booker.gifts.offerButton"
      :label="$t('settings.gift.offerButton')"
    />

    <v-row align="stretch">
      <v-col cols="12" class="h4-content">
        <h4 class="d-flex align-center">
          {{ $t('settings.gift.giftThemes') }}
          <span
            v-if="
              myCompany.booker.gift &&
                (!myCompany.booker.gifts ||
                  !myCompany.booker.gifts.themes.length)
            "
            style="width: 15px; height: 15px"
            class="mt-0 ml-5 red rounded-circle"
          />
        </h4>
        <span
          :class="{
            red:
              myCompany.booker.gift &&
              (!myCompany.booker.gifts || !myCompany.booker.gifts.themes.length)
          }"
        />
      </v-col>
      <v-col
        cols="12"
        v-for="theme in myCompany.booker.gifts.themes"
        :key="theme.objectID"
        class="row-theme-bon-cadeau"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              regular
              dense
              hide-details="auto"
              v-model="theme.title"
              :label="$t('settings.gift.themeName')"
            />
          </v-col>
          <v-col cols="12" md="6">
            <div
              style="background-color: #FFF; padding: 10px 20px; border: 1px solid #CCC;"
            >
              <div>
                <strong>{{ $t('settings.gift.mailImage') }}</strong>
              </div>
              <images-selector
                v-model="theme.header"
                :size="[1000, 600]"
                single
                :updateData="
                  theme.id === idMailToUpdate ? updateGiftMailData : undefined
                "
                :key="theme.id"
                :categories="images.gift.mail"
              />
              <file-explorer
                v-if="!theme.header || !theme.header.length"
                :images="images.gift.mail"
                :loading="loading"
                single
                @uploadImages="uploadMail(theme, $event)"
              />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div
              style="background-color: #FFF; padding: 10px 20px; border: 1px solid #CCC;"
            >
              <div>
                <strong>{{ $t('settings.gift.pdfImage') }}</strong>
              </div>
              <images-selector
                v-model="theme.pdf"
                :size="[600, 850]"
                single
                :updateData="
                  theme.id === idPdfToUpdate ? updateGiftPdfData : undefined
                "
                :key="theme.id"
                :categories="images.gift.pdf"
              />
              <file-explorer
                v-if="!theme.pdf || !theme.pdf.length"
                :images="images.gift.pdf"
                :loading="loading"
                single
                @uploadImages="uploadPdf(theme, $event)"
              />
            </div>
          </v-col>
          <v-col cols="12" align="right">
            <v-btn @click="deleteGiftTheme(theme)" block text>
              <v-icon left>mdi-delete</v-icon>
              {{ $t('settings.gift.delete') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn @click="addGiftTheme" outlined color="#2B46DF">
          {{ $t('other.add') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="h4-content">
        <h4 class="d-flex align-center">
          {{ $t('settings.gift.giftValues') }}
          <span
            v-if="
              myCompany.booker.gift &&
                (!myCompany.booker.gifts ||
                  !myCompany.booker.gifts.bcValues.length)
            "
            style="width: 15px; height: 15px"
            class="mt-0 ml-5 red rounded-circle"
          />
        </h4>
        <span
          :class="{
            red:
              myCompany.booker.gift &&
              (!myCompany.booker.gifts ||
                !myCompany.booker.gifts.bcValues.length)
          }"
        />
      </v-col>
      <v-col cols="12" class="input-add-value-bon-cadeau d-flex align-center">
        <v-text-field
          outlined
          hide-details
          v-model="newBCValue"
          type="number"
          step="1"
          append-icon="mdi-currency-eur"
          @keypress.enter="addBCValue"
        />
        <v-btn @click="addBCValue" outlined color="#2B46DF">
          {{ $t('other.add') }}
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-chip
          v-for="(value, valueIndex) in myCompany.booker.gifts.bcValues"
          :key="value + '' + valueIndex"
          class="ma-2"
          close
          @click:close="removeBCValue(value)"
        >
          {{ value }}€
        </v-chip>
      </v-col>
      <v-col cols="12">
        <v-alert type="warning" v-if="bcLowPrice">
          {{ $t('settings.gift.oneOrMore') }}
        </v-alert>
      </v-col>
      <v-col cols="12">
        <v-switch
          inset
          v-model="myCompany.booker.gifts.customValue"
          :label="$t('settings.gift.enableCustomValues')"
        />
      </v-col>
      <v-col
        cols="12"
        v-if="myCompany.booker.gifts.customValue"
        class="d-flex align-center justify-center"
      >
        <v-btn
          height="56"
          class="elevation-0"
          style="border-radius: 5px 0 0 5px; border: 1px #333 solid"
          @click="minus"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>
        <div style="width: 200px">
          <v-text-field
            v-model.number="myCompany.booker.gifts.minCustomValue"
            :label="$t('settings.gift.minimumValue')"
            outlined
            hide-details
            readonly
            append-icon="mdi-currency-eur"
            class="customValue"
          />
        </div>
        <v-btn
          height="56"
          class="elevation-0"
          style="border-radius: 0 5px 5px 0; border: 1px #333 solid"
          @click="plus"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="h4-content">
        <h4>
          {{ $t('settings.gift.giftSettings') }}
        </h4>
        <span />
      </v-col>
      <v-col cols="12">
        <v-text-field
          type="number"
          step="1"
          outlined
          v-model="myCompany.booker.gifts.validity"
          :label="$t('settings.gift.validityPeriod')"
          @blur="
            () =>
              (myCompany.booker.gifts.validity =
                myCompany.booker.gifts.validity <= 0
                  ? 1
                  : myCompany.booker.gifts.validity)
          "
        />
      </v-col>
    </v-row>

    <v-row align="stretch">
      <v-col cols="12 h4-content">
        <h4 class="d-flex align-center">
          {{ $t('settings.onlineBooking.notifications') }}
          <span
            v-if="
              myCompany.booker.giftEmailCopyToList &&
                !myCompany.booker.giftEmailCopyList
            "
            style="width: 15px; height: 15px"
            class="mt-0 ml-5 red rounded-circle"
          />
        </h4>
        <span
          :class="{
            red:
              myCompany.booker.giftEmailCopyToList &&
              !myCompany.booker.giftEmailCopyList
          }"
        />
      </v-col>
      <v-col cols="12">
        <v-switch
          inset
          v-model="myCompany.booker.giftEmailCopyToList"
          :label="$t('settings.onlineBooking.receiveGiftCopyEmailsAddressList')"
        />
        <v-textarea
          v-show="myCompany.booker.giftEmailCopyToList"
          v-model="myCompany.booker.giftEmailCopyList"
          outlined
          :label="$t('settings.onlineBooking.listOfEmails')"
          :class="{
            empty:
              myCompany.booker.giftEmailCopyToList &&
              !myCompany.booker.giftEmailCopyList
          }"
        />
      </v-col>
    </v-row>

    <div class="h4-content">
      <h4>
        {{ $t('settings.gift.headerText') }}
      </h4>
      <span />
    </div>
    <div>
      <multilang-editor v-model="myCompany.booker.gifts.introText" />
    </div>

    <div class="h4-content">
      <h4>
        {{ $t('settings.gift.pdfGift') }}
      </h4>
      <span />
    </div>
    <div>
      <multilang-editor v-model="myCompany.booker.textLegals.giftPDF" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import FileExplorer from '../../components/images/fileExplorer'
import ImagesSelector from '../helpers/imagesSelector'
import MultilangEditor from '../helpers/multilangEditor'

export default {
  name: 'gift',
  components: {
    MultilangEditor,
    ImagesSelector,
    FileExplorer
  },
  props: {
    myCompany: {
      type: Object,
      default: () => {}
    },
    images: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      newBCValue: 0,
      updateGiftMailData: [],
      updateGiftPdfData: [],
      idMailToUpdate: null,
      idPdfToUpdate: null
    }
  },
  computed: {
    bcLowPrice() {
      if (!this.$store.state.articles.articles.length) {
        return false
      }
      if (!this.myCompany.booker.gifts.bcValues.length) {
        return false
      }

      const articles = JSON.parse(
        JSON.stringify(this.$store.state.articles.articles)
      ).sort((a, b) => a.prices - b.prices)
      const first = articles[0]

      const firstBC = this.myCompany.booker.gifts.bcValues[0]

      return firstBC < first.prices
    }
  },
  created() {
    this.initGift()
  },
  methods: {
    initGift() {
      if (!this.myCompany.booker) {
        Vue.set(this.myCompany, 'booker', {})
      }

      if (typeof this.myCompany.booker.gift === 'undefined') {
        Vue.set(this.myCompany.booker, 'gift', false)
      }

      if (!this.myCompany.booker.gifts) {
        Vue.set(this.myCompany.booker, 'gifts', {})
      }

      if (!this.myCompany.booker.gifts.themes) {
        Vue.set(this.myCompany.booker.gifts, 'themes', [])
      }

      if (!this.myCompany.booker.gifts.bcValues) {
        Vue.set(this.myCompany.booker.gifts, 'bcValues', [])
      }

      if (!this.myCompany.booker.gifts.validity) {
        Vue.set(this.myCompany.booker.gifts, 'validity', 12)
      }

      if (typeof this.myCompany.booker.giftEmailCopyToList === 'undefined') {
        Vue.set(this.myCompany.booker, 'giftEmailCopyToList', false)
      }

      if (!this.myCompany.booker.giftEmailCopyList) {
        Vue.set(this.myCompany.booker, 'giftEmailCopyList', '')
      }

      if (!this.myCompany.booker.gifts.introText) {
        Vue.set(this.myCompany.booker.gifts, 'introText', {
          fr: `<p>${this.$t('settings.gift.toOfferAGift')}</p>`
        })
      }

      if (!this.myCompany.booker.textLegals) {
        Vue.set(this.myCompany.booker, 'textLegals', {})
      }

      if (!this.myCompany.booker.textLegals.giftPDF) {
        Vue.set(this.myCompany.booker.textLegals, 'giftPDF', {})
      }

      if (typeof this.myCompany.booker.gifts.offerButton === 'undefined') {
        Vue.set(this.myCompany.booker.gifts, 'offerButton', false)
      }

      if (typeof this.myCompany.booker.gifts.customValue === 'undefined') {
        Vue.set(this.myCompany.booker.gifts, 'customValue', false)
      }

      if (!this.myCompany.booker.gifts.minCustomValue) {
        Vue.set(this.myCompany.booker.gifts, 'minCustomValue', 10)
      }
    },

    deleteGiftTheme(theme) {
      this.myCompany.booker.gifts.themes = this.myCompany.booker.gifts.themes.filter(
        t => t.id !== theme.id
      )
    },
    addGiftTheme() {
      this.myCompany.booker.gifts.themes.push({
        id:
          Math.random().toString(32) +
          this.myCompany.booker.gifts.themes.length,
        header: [],
        pdf: []
      })
    },

    addBCValue() {
      if (
        this.newBCValue < 1 ||
        this.myCompany.booker.gifts.bcValues.find(
          v => v === parseInt(this.newBCValue)
        )
      ) {
        return
      }

      this.myCompany.booker.gifts.bcValues.push(parseInt(this.newBCValue))
      this.newBCValue = 0

      this.myCompany.booker.gifts.bcValues = this.myCompany.booker.gifts.bcValues.sort(
        (a, b) => a - b
      )
    },
    removeBCValue(v) {
      this.myCompany.booker.gifts.bcValues = this.myCompany.booker.gifts.bcValues.filter(
        vv => vv !== v
      )
    },

    uploadMail(theme, value) {
      this.myCompany.booker.gifts.themes.map(t => {
        if (t.id === theme.id) {
          t.header = value
          this.idMailToUpdate = theme.id
          this.updateGiftMailData = value
        }
      })
    },
    uploadPdf(theme, value) {
      this.myCompany.booker.gifts.themes.map(t => {
        if (t.id === theme.id) {
          t.pdf = value
          this.idPdfToUpdate = theme.id
          this.updateGiftPdfData = value
        }
      })
    },

    minus() {
      if (this.myCompany.booker.gifts.minCustomValue === 10) {
        return
      }

      this.myCompany.booker.gifts.minCustomValue -= 10
    },
    plus() {
      this.myCompany.booker.gifts.minCustomValue += 10
    }
  }
}
</script>

<style scoped>
.row-theme-bon-cadeau {
  background-color: rgb(245, 245, 245);
  margin-top: 15px;
  padding: 20px 30px;
  border-radius: 5px;
}

.input-add-value-bon-cadeau {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
</style>

<style>
.customValue input {
  text-align: center;
  font-size: 1.4rem;
}
</style>
