var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.moduleEnabled)?_c('div',[_c('v-row',{attrs:{"align":"stretch"}},[_c('v-col',{attrs:{"cols":"12 h4-content mt-0"}},[_c('h4',[_vm._v("Clients VIP")]),_c('span')]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('v-switch',{attrs:{"inset":"","label":"Peut réserver sans payer"},model:{value:(
              _vm.myCompany.booker.modulesSettings.vip.customerVip.freeBooking
            ),callback:function ($$v) {_vm.$set(_vm.myCompany.booker.modulesSettings.vip.customerVip, "freeBooking", $$v)},expression:"\n              myCompany.booker.modulesSettings.vip.customerVip.freeBooking\n            "}})],1),_c('div',[_c('v-switch',{attrs:{"inset":"","label":"Peut choisir un prestataire"},model:{value:(
              _vm.myCompany.booker.modulesSettings.vip.customerVip
                .employeeSelection
            ),callback:function ($$v) {_vm.$set(_vm.myCompany.booker.modulesSettings.vip.customerVip
                , "employeeSelection", $$v)},expression:"\n              myCompany.booker.modulesSettings.vip.customerVip\n                .employeeSelection\n            "}})],1),_c('div',[_c('v-switch',{attrs:{"inset":"","label":"Afficher les tarifs","disabled":!_vm.myCompany.booker.modulesSettings.vip.customerVip.freeBooking},model:{value:(
              _vm.myCompany.booker.modulesSettings.vip.customerVip.showPrices
            ),callback:function ($$v) {_vm.$set(_vm.myCompany.booker.modulesSettings.vip.customerVip, "showPrices", $$v)},expression:"\n              myCompany.booker.modulesSettings.vip.customerVip.showPrices\n            "}})],1),_c('div',[_c('MultilangEditor',{attrs:{"label":"Message personnalisé","placeholder":"Message personnalisé"},model:{value:(
              _vm.myCompany.booker.modulesSettings.vip.customerVip.customMessage
            ),callback:function ($$v) {_vm.$set(_vm.myCompany.booker.modulesSettings.vip.customerVip, "customMessage", $$v)},expression:"\n              myCompany.booker.modulesSettings.vip.customerVip.customMessage\n            "}})],1)])],1),_c('v-row',{attrs:{"align":"stretch"}},[_c('v-col',{attrs:{"cols":"12 h4-content mt-0"}},[_c('h4',[_vm._v("Clients VIP Plus")]),_c('span')]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('div',[_c('v-switch',{attrs:{"inset":"","label":"Peut réserver sans payer"},model:{value:(
                _vm.myCompany.booker.modulesSettings.vip.customerVipPlus
                  .freeBooking
              ),callback:function ($$v) {_vm.$set(_vm.myCompany.booker.modulesSettings.vip.customerVipPlus
                  , "freeBooking", $$v)},expression:"\n                myCompany.booker.modulesSettings.vip.customerVipPlus\n                  .freeBooking\n              "}})],1),_c('div',[_c('v-switch',{attrs:{"inset":"","label":"Peut choisir un prestataire"},model:{value:(
                _vm.myCompany.booker.modulesSettings.vip.customerVipPlus
                  .employeeSelection
              ),callback:function ($$v) {_vm.$set(_vm.myCompany.booker.modulesSettings.vip.customerVipPlus
                  , "employeeSelection", $$v)},expression:"\n                myCompany.booker.modulesSettings.vip.customerVipPlus\n                  .employeeSelection\n              "}})],1),_c('div',[_c('v-switch',{attrs:{"inset":"","label":"Afficher les tarifs","disabled":!_vm.myCompany.booker.modulesSettings.vip.customerVipPlus
                  .freeBooking},model:{value:(
                _vm.myCompany.booker.modulesSettings.vip.customerVipPlus
                  .showPrices
              ),callback:function ($$v) {_vm.$set(_vm.myCompany.booker.modulesSettings.vip.customerVipPlus
                  , "showPrices", $$v)},expression:"\n                myCompany.booker.modulesSettings.vip.customerVipPlus\n                  .showPrices\n              "}})],1),_c('div',[_c('MultilangEditor',{attrs:{"label":"Message personnalisé","placeholder":"Message personnalisé"},model:{value:(
                _vm.myCompany.booker.modulesSettings.vip.customerVipPlus
                  .customMessage
              ),callback:function ($$v) {_vm.$set(_vm.myCompany.booker.modulesSettings.vip.customerVipPlus
                  , "customMessage", $$v)},expression:"\n                myCompany.booker.modulesSettings.vip.customerVipPlus\n                  .customMessage\n              "}})],1)])])],1)],1):_c('div',[_c('p',[_vm._v("Ce module n'est pas activé sur votre compte.")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }