<template>
  <div>
    <v-text-field
      v-model="myCompany.identity.displayName"
      outlined
      :label="$t('settings.identity.companyName')"
      type="text"
      :class="{
        empty: !myCompany.identity.displayName
      }"
    />
    <v-text-field
      v-model="myCompany.identity.companyName"
      outlined
      :label="$t('settings.identity.corporateName')"
      type="text"
    />

    <v-textarea
      v-model="myCompany.identity.address"
      outlined
      :label="$t('settings.identity.address')"
      type="text"
    />
    <v-text-field
      v-model="myCompany.identity.zipCode"
      outlined
      :label="$t('settings.identity.postalCode')"
      type="text"
    />
    <v-text-field
      v-model="myCompany.identity.city"
      outlined
      :label="$t('settings.identity.city')"
      type="text"
    />

    <div class="d-flex align-center justify-space-between">
      <v-text-field
        v-model="myCompany.identity.siret"
        outlined
        :label="$t('settings.identity.siret')"
        type="text"
      />
      <v-text-field
        v-model="myCompany.identity.ape"
        outlined
        :label="$t('settings.identity.codeape')"
        type="text"
        class="mx-4"
      />
      <v-text-field
        v-model="myCompany.identity.nii"
        outlined
        :label="$t('settings.identity.nii')"
        type="text"
      />
    </div>
    <div class="d-flex align-center justify-space-between">
      <v-text-field
        v-model="myCompany.identity.iban"
        outlined
        label="IBAN"
        type="text"
      />
      <v-text-field
        v-model="myCompany.identity.bic"
        outlined
        label="BIC"
        type="text"
        class="mx-4"
      />
      <v-text-field
        v-model="myCompany.identity.prctcom"
        outlined
        label="% commision"
        type="number"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'identity',
  props: {
    myCompany: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    this.initIdentity()
  },
  methods: {
    initIdentity() {
      if (!this.myCompany.identity) {
        Vue.set(this.myCompany, 'identity', {})
      }

      if (!this.myCompany.identity.displayName) {
        Vue.set(this.myCompany.identity, 'displayName', '')
      }

      if (!this.myCompany.identity.companyName) {
        Vue.set(this.myCompany.identity, 'companyName', '')
      }

      if (!this.myCompany.identity.address) {
        Vue.set(this.myCompany.identity, 'address', '')
      }

      if (!this.myCompany.identity.zipCode) {
        Vue.set(this.myCompany.identity, 'zipCode', '')
      }

      if (!this.myCompany.identity.city) {
        Vue.set(this.myCompany.identity, 'city', '')
      }

      if (!this.myCompany.identity.siret) {
        Vue.set(this.myCompany.identity, 'siret', '')
      }

      if (!this.myCompany.identity.ape) {
        Vue.set(this.myCompany.identity, 'ape', '')
      }

      if (!this.myCompany.identity.nii) {
        Vue.set(this.myCompany.identity, 'nii', '')
      }

      if (!this.myCompany.identity.iban) {
        Vue.set(this.myCompany.identity, 'iban', '')
      }

      if (!this.myCompany.identity.iban) {
        Vue.set(this.myCompany.identity, 'bic', '')
      }

      if (!this.myCompany.identity.prctcom) {
        Vue.set(this.myCompany.identity, 'prctcom', '')
      }
    }
  }
}
</script>

<style scoped></style>
