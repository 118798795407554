<template>
  <div>
    <div>
      <div class="h4-content">
        <h4>
          {{ $t('settings.connectionSettings.customerConnectionViaSocial') }}
        </h4>
        <span />
      </div>
      <div class="mt-5">
        <v-text-field
          v-model="myCompany.booker.oauthKeys.google"
          :label="
            $t('settings.connectionSettings.key', { key: 'OAuth Google' })
          "
          outlined
        />
      </div>
      <div class="mt-5">
        <v-text-field
          v-model="myCompany.booker.oauthKeys.facebook"
          label="App ID Facebook"
          outlined
        />
      </div>
    </div>

    <div>
      <div class="h4-content">
        <h4>
          {{ $t('settings.connectionSettings.telephoneFieldForm') }}
        </h4>
        <span />
      </div>
      <div class="mt-5">
        <v-autocomplete
          v-model="myCompany.themes.defaultCountry"
          :items="countryList"
          outlined
          :label="$t('settings.connectionSettings.defaultCountry')"
        />
      </div>
      <div class="mt-5">
        <v-autocomplete
          v-model="myCompany.themes.highlightedCountries"
          :items="countryList"
          outlined
          :label="$t('settings.connectionSettings.highlightedCountries')"
          chips
          deletable-chips
          multiple
          clearable
        />
      </div>
    </div>

    <div>
      <div class="h4-content">
        <h4 class="d-flex align-center">
          {{ $t('settings.connectionSettings.alertMessageManagement') }}
          <span
            v-if="
              (myCompany.booker &&
                myCompany.booker.alertMessages &&
                myCompany.booker.alertMessages.connexion &&
                myCompany.booker.alertMessages.connexion.active &&
                !myCompany.booker.alertMessages.connexion.message[
                  $i18n.locale
                ]) ||
                (myCompany.booker &&
                  myCompany.booker.alertMessages &&
                  myCompany.booker.alertMessages.registration &&
                  myCompany.booker.alertMessages.registration.active &&
                  !myCompany.booker.alertMessages.registration.message[
                    $i18n.locale
                  ])
            "
            style="width: 15px; height: 15px"
            class="mt-0 ml-5 red rounded-circle"
          />
        </h4>
        <span
          :class="{
            red:
              (myCompany.booker &&
                myCompany.booker.alertMessages &&
                myCompany.booker.alertMessages.connexion &&
                myCompany.booker.alertMessages.connexion.active &&
                !myCompany.booker.alertMessages.connexion.message[
                  $i18n.locale
                ]) ||
              (myCompany.booker &&
                myCompany.booker.alertMessages &&
                myCompany.booker.alertMessages.registration &&
                myCompany.booker.alertMessages.registration.active &&
                !myCompany.booker.alertMessages.registration.message[
                  $i18n.locale
                ])
          }"
        />
      </div>

      <v-tabs v-model="tabAlert" hide-slider class="mt-5">
        <v-tab>
          <div
            class="primary white--text pa-3 rounded font-weight-bold elevation-2"
            :class="{
              notSelectedTab: tabAlert !== 0
            }"
          >
            {{ $t('settings.connectionSettings.logIn') }}
          </div>
        </v-tab>
        <v-tab>
          <div
            class="primary white--text pa-3 rounded font-weight-bold elevation-2"
            :class="{
              notSelectedTab: tabAlert !== 1
            }"
          >
            {{ $t('settings.connectionSettings.registration') }}
          </div>
        </v-tab>
        <v-tab-item :key="1">
          <div class="d-flex justify-lg-space-between">
            <v-switch
              class="ml-2"
              inset
              v-model="myCompany.booker.alertMessages.connexion.active"
              :label="$t('settings.connectionSettings.activate')"
            />
            <v-switch inset v-model="showHtmlConnection" label="HTML" />
          </div>
          <v-row>
            <v-col :cols="showHtmlConnection ? 6 : 12">
              <multilang-editor
                height="300px"
                v-model="myCompany.booker.alertMessages.connexion.message"
                :label="$t('settings.connectionSettings.message')"
              />
            </v-col>
            <v-col cols="6" v-if="showHtmlConnection">
              {{
                controli18n(
                  $i18n.locale,
                  myCompany.booker.alertMessages.connexion.message
                )
              }}
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item :key="2">
          <div class="d-flex justify-lg-space-between">
            <v-switch
              class="ml-2"
              inset
              v-model="myCompany.booker.alertMessages.registration.active"
              :label="$t('settings.connectionSettings.activate')"
            />
            <v-switch inset v-model="showHtmlRegistration" label="HTML" />
          </div>
          <v-row>
            <v-col :cols="showHtmlRegistration ? 6 : 12">
              <multilang-editor
                height="300px"
                v-model="myCompany.booker.alertMessages.registration.message"
                :label="$t('settings.connectionSettings.message')"
              />
            </v-col>
            <v-col cols="6" v-if="showHtmlRegistration">
              {{
                controli18n(
                  $i18n.locale,
                  myCompany.booker.alertMessages.registration.message
                )
              }}
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import countrySearch from '../../helpers/countrySearch'
import MultilangEditor from '../helpers/multilangEditor'
import { controli18n } from '../../helpers/langs'

export default {
  name: 'connexion',
  components: { MultilangEditor },
  props: {
    myCompany: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showHtmlConnection: false,
      showHtmlRegistration: false,
      tabAlert: 0
    }
  },
  computed: {
    countryList() {
      return Object.keys(countrySearch().countries).map(key => {
        return {
          value: countrySearch().countries[key].code,
          text: countrySearch().countries[key].name
        }
      })
    }
  },
  created() {
    this.initConnexion()
  },
  methods: {
    controli18n,
    initConnexion() {
      if (!this.myCompany.booker) {
        Vue.set(this.myCompany, 'booker', {})
      }

      if (!this.myCompany.booker.oauthKeys) {
        Vue.set(this.myCompany.booker, 'oauthKeys', {})
      }

      if (!this.myCompany.booker.oauthKeys.google) {
        Vue.set(this.myCompany.booker.oauthKeys, 'google', '')
      }

      if (!this.myCompany.booker.oauthKeys.facebook) {
        Vue.set(this.myCompany.booker.oauthKeys, 'facebook', '')
      }

      if (!this.myCompany.themes) {
        Vue.set(this.myCompany, 'themes', {})
      }

      if (!this.myCompany.themes.defaultCountry) {
        Vue.set(this.myCompany.themes, 'defaultCountry', 'FR')
      }

      if (!this.myCompany.themes.highlightedCountries) {
        Vue.set(this.myCompany.themes, 'highlightedCountries', [])
      }

      if (!this.myCompany.booker.alertMessages) {
        Vue.set(this.myCompany.booker, 'alertMessages', {
          connexion: {
            active: false,
            message: {}
          },
          registration: {
            active: false,
            message: {}
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.notSelectedTab {
  background-color: #cbba95aa !important;
}
.notSelectedTab:hover {
  background-color: #cbba95 !important;
}
</style>
