<template>
  <div>
    <div v-if="false">
      <v-row align="stretch">
        <v-col cols="12 h4-content mt-0">
          <h4>{{ $t('settings.sms.credentials') }}</h4>
          <span />
        </v-col>
        <v-col cols="12">
          <v-switch
            inset
            :label="$t('settings.sms.enabled')"
            v-model="myCompany.booker.sms.enabled"
          />
          <v-text-field
            :label="$t('settings.sms.username')"
            v-model="myCompany.booker.sms.username"
            outlined
          />
          <v-text-field
            :label="$t('settings.sms.password')"
            v-model="myCompany.booker.sms.password"
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="8">
              <v-textarea
                v-model="myCompany.booker.sms.templates.codeRegistration"
                outlined
                :label="$t('settings.sms.codeRegistrationLabel')"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-btn
                type="button"
                @click="addVarToSMS('codeRegistration', '[code]')"
                small
                text
              >
                [code]
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="8">
              <v-textarea
                v-model="myCompany.booker.sms.templates.bookingRemind"
                outlined
                :label="$t('settings.sms.bookingRemind')"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-btn
                type="button"
                @click="addVarToSMS('bookingRemind', '[date]')"
                small
                text
              >
                [date]
              </v-btn>

              <v-btn
                type="button"
                @click="addVarToSMS('bookingRemind', '[shop]')"
                small
                text
              >
                [shop]
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row align="stretch">
        <v-col cols="12">
          <SmsTextarea
            v-model="myCompany.booker.sms.templates.bookingRemind"
            :label="$t('settings.sms.bookingRemind')"
            :vars="['date', 'shop', 'firstname', 'lastname', 'address']"
          />
          <v-icon>info</v-icon>
          <span>{{ $t('settings.sms.explanation') }}</span>
          <!--          <v-row>-->
          <!--            <v-col cols="12" md="8">-->
          <!--              <v-textarea-->
          <!--                v-model="myCompany.booker.sms.templates.bookingRemind"-->
          <!--                outlined-->
          <!--                :label="$t('settings.sms.bookingRemind')"-->
          <!--                hide-details-->
          <!--              />-->
          <!--              <div style="font-size: 12px">-->
          <!--                <div class="d-flex justify-space-between">-->
          <!--                  <div>-->
          <!--                    Estimation min SMS :-->
          <!--                    {{-->
          <!--                      calculateNBSMS(-->
          <!--                        myCompany.booker.sms.templates.bookingRemind-->
          <!--                      ).min-->
          <!--                    }}-->
          <!--                  </div>-->
          <!--                  <div>-->
          <!--                    Estimation min caractères :-->
          <!--                    {{-->
          <!--                      calculateNBChar(-->
          <!--                        myCompany.booker.sms.templates.bookingRemind-->
          <!--                      ).min-->
          <!--                    }}-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="d-flex justify-space-between">-->
          <!--                  <div>-->
          <!--                    Estimation max SMS :-->
          <!--                    {{-->
          <!--                      calculateNBSMS(-->
          <!--                        myCompany.booker.sms.templates.bookingRemind-->
          <!--                      ).max-->
          <!--                    }}-->
          <!--                  </div>-->
          <!--                  <div>-->
          <!--                    Estimation max caractères :-->
          <!--                    {{-->
          <!--                      calculateNBChar(-->
          <!--                        myCompany.booker.sms.templates.bookingRemind-->
          <!--                      ).max-->
          <!--                    }}-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </v-col>-->
          <!--            <v-col cols="12" md="4">-->
          <!--              <v-btn-->
          <!--                type="button"-->
          <!--                @click="addVarToSMS('bookingRemind', '[date]')"-->
          <!--                small-->
          <!--                text-->
          <!--              >-->
          <!--                [date]-->
          <!--              </v-btn>-->

          <!--              <v-btn-->
          <!--                type="button"-->
          <!--                @click="addVarToSMS('bookingRemind', '[shop]')"-->
          <!--                small-->
          <!--                text-->
          <!--              >-->
          <!--                [shop]-->
          <!--              </v-btn>-->

          <!--              <v-btn-->
          <!--                type="button"-->
          <!--                @click="addVarToSMS('bookingRemind', '[firstname]')"-->
          <!--                small-->
          <!--                text-->
          <!--              >-->
          <!--                [firstname]-->
          <!--              </v-btn>-->

          <!--              <v-btn-->
          <!--                type="button"-->
          <!--                @click="addVarToSMS('bookingRemind', '[lastname]')"-->
          <!--                small-->
          <!--                text-->
          <!--              >-->
          <!--                [lastname]-->
          <!--              </v-btn>-->

          <!--              <v-btn-->
          <!--                type="button"-->
          <!--                @click="addVarToSMS('bookingRemind', '[address]')"-->
          <!--                small-->
          <!--                text-->
          <!--              >-->
          <!--                [address]-->
          <!--              </v-btn>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import SmsTextarea from '@/components/helpers/smsTextarea'

export default {
  name: 'sms',
  components: { SmsTextarea },
  props: {
    myCompany: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    this.initSms()
  },
  methods: {
    calculateNBSMS(content) {
      return {
        min: Math.ceil(this.calculateNBChar(content).min / 160),
        max: Math.ceil(this.calculateNBChar(content).max / 160)
      }
    },
    calculateNBChar(content) {
      let nb = content
        .split('[date]')
        .join('')
        .split('[shop]')
        .join('')
        .split('[firstname]')
        .join('')
        .split('[lastname]')
        .join('')
        .split('[address]')
        .join('').length

      const minShopsName = this.$store.state.shop.shops.reduce((acc, cur) => {
        const l = cur.publicInfo.name ? cur.publicInfo.name.length : 0

        if (acc === null || acc > l) {
          acc = l
        }

        return acc
      }, null)
      const maxShopsName = this.$store.state.shop.shops.reduce((acc, cur) => {
        const l = cur.publicInfo.name ? cur.publicInfo.name.length : 0

        if (acc === null || acc < l) {
          acc = l
        }

        return acc
      }, null)
      // const moyenneShopsName = Math.ceil(
      //   this.$store.state.shop.shops.reduce((acc, cur) => {
      //     acc += cur.publicInfo.name ? cur.publicInfo.name.length : 0
      //
      //     return acc
      //   }, 0) / this.$store.state.shop.shops.length
      // )
      const minShopsAddress = this.$store.state.shop.shops.reduce(
        (acc, cur) => {
          let l = 0

          l +=
            cur.publicInfo.address && cur.publicInfo.address.address
              ? cur.publicInfo.address.address.length
              : 0
          l +=
            cur.publicInfo.address && cur.publicInfo.address.city
              ? cur.publicInfo.address.city.length
              : 0
          l +=
            cur.publicInfo.address && cur.publicInfo.address.zipcode
              ? cur.publicInfo.address.zipcode.length
              : 0
          l +=
            cur.publicInfo.address && cur.publicInfo.address.country
              ? cur.publicInfo.address.country.length
              : 0

          if (acc === null || acc > l) {
            acc = l
          }

          return acc
        },
        null
      )
      const maxShopsAddress = this.$store.state.shop.shops.reduce(
        (acc, cur) => {
          let l = 0

          l +=
            cur.publicInfo.address && cur.publicInfo.address.address
              ? cur.publicInfo.address.address.length
              : 0
          l +=
            cur.publicInfo.address && cur.publicInfo.address.city
              ? cur.publicInfo.address.city.length
              : 0
          l +=
            cur.publicInfo.address && cur.publicInfo.address.zipcode
              ? cur.publicInfo.address.zipcode.length
              : 0
          l +=
            cur.publicInfo.address && cur.publicInfo.address.country
              ? cur.publicInfo.address.country.length
              : 0

          if (acc === null || acc < l) {
            acc = l
          }

          return acc
        },
        null
      )
      // const moyenneShopsAddress = Math.ceil(
      //   this.$store.state.shop.shops.reduce((acc, cur) => {
      //     acc +=
      //       cur.publicInfo.address && cur.publicInfo.address.address
      //         ? cur.publicInfo.address.address.length
      //         : 0
      //     acc +=
      //       cur.publicInfo.address && cur.publicInfo.address.city
      //         ? cur.publicInfo.address.city.length
      //         : 0
      //     acc +=
      //       cur.publicInfo.address && cur.publicInfo.address.zipcode
      //         ? cur.publicInfo.address.zipcode.length
      //         : 0
      //     acc +=
      //       cur.publicInfo.address && cur.publicInfo.address.country
      //         ? cur.publicInfo.address.country.length
      //         : 0
      //
      //     return acc
      //   }, 0) / this.$store.state.shop.shops.length
      // )

      let min = nb
      let max = nb

      min += (content.match(/\[date\]/g) || []).length * 16
      min += (content.match(/\[shop\]/g) || []).length * minShopsName
      min += (content.match(/\[firstname\]/g) || []).length * 6
      min += (content.match(/\[lastname\]/g) || []).length * 8
      min += (content.match(/\[address\]/g) || []).length * minShopsAddress

      max += (content.match(/\[date\]/g) || []).length * 16
      max += (content.match(/\[shop\]/g) || []).length * maxShopsName
      max += (content.match(/\[firstname\]/g) || []).length * 16
      max += (content.match(/\[lastname\]/g) || []).length * 20
      max += (content.match(/\[address\]/g) || []).length * maxShopsAddress

      return {
        min,
        max
      }
    },
    initSms() {
      if (!this.myCompany.booker) {
        Vue.set(this.myCompany, 'booker', {})
      }

      if (!this.myCompany.booker.sms) {
        Vue.set(this.myCompany.booker, 'sms', {})
      }

      if (typeof this.myCompany.booker.sms.enabled === 'undefined') {
        Vue.set(this.myCompany.booker.sms, 'enabled', false)
      }

      if (!this.myCompany.booker.sms.username) {
        Vue.set(this.myCompany.booker.sms, 'username', '')
      }

      if (!this.myCompany.booker.sms.password) {
        Vue.set(this.myCompany.booker.sms, 'password', '')
      }

      if (!this.myCompany.booker.sms.templates) {
        Vue.set(this.myCompany.booker.sms, 'templates', {})
      }

      if (!this.myCompany.booker.sms.templates.codeRegistration) {
        Vue.set(this.myCompany.booker.sms.templates, 'codeRegistration', '')
      }

      if (!this.myCompany.booker.sms.templates.bookingRemind) {
        Vue.set(this.myCompany.booker.sms.templates, 'bookingRemind', '')
      }
    },
    addVarToSMS(templateName, varName) {
      this.myCompany.booker.sms.templates[templateName] += ' ' + varName
    }
  }
}
</script>

<style scoped></style>
