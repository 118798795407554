export default {
  getTemplate(nickname) {
    return `
<table border="0" style="border-collapse: collapse; font-family: sans-serif; box-sizing: border-box; height: 90px; padding: 0; margin: 0; width: 100%; height: 90px; width="100%" height="100%">
  <tbody style="box-sizing: border-box;  padding: 0; margin: 0;;">
    <tr style="box-sizing: border-box;  padding: 0; margin: 0;">
      <td style="box-sizing: border-box;  padding: 0; margin: 0;">
        <table border="0" style="border-collapse: collapse; box-sizing: border-box; height: 90px;  padding: 0; margin: 0; width: 100%; height: 90px; background-color: #212121;" width="100%" height="100%" bgcolor="#212121">
          <tbody style="box-sizing: border-box;  padding: 0; margin: 0;">
            <tr style="box-sizing: border-box;  padding: 0; margin: 0;">
              <td style="box-sizing: border-box;  padding: 0; margin: 0;">
                <div style="box-sizing: border-box; padding: 10px; font-size: 22px; font-weight: 700; text-align: center; color: #FFFFFF;">${nickname}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr style="box-sizing: border-box;  padding: 0; margin: 0;">
      <td style="box-sizing: border-box; padding: 0; margin: 0;">
        <table border="0" style="border-collapse: collapse; box-sizing: border-box;  padding: 0; margin: 0; width: 100%;  width="100%" height="100%">
          <tbody style="box-sizing: border-box;  padding: 0; margin: 0;">
            <tr style="box-sizing: border-box;  padding: 0; margin: 0;">
              <td style="box-sizing: border-box;  padding: 0; margin: 0; color: #999999;">
                <div style="width: 500px; margin: auto; box-sizing: border-box; padding: 10px; text-align: justify; font-size: 16px; color: #999999;">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusantium animi asperiores dolore ipsam minus molestiae numquam porro quas qui, repudiandae vero voluptatem voluptatibus! Delectus iusto maiores ullam ut vero.
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>
`
  }
}
