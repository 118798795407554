<template>
  <v-row>
    <v-col cols="12" md="8">
      <v-textarea
        :value="value"
        @input="$emit('input', $event)"
        outlined
        :label="label"
        hide-details
      />
      <div style="font-size: 12px">
        <div class="d-flex justify-space-between">
          <div>
            Estimation min SMS :
            {{ calculateNBSMS().min }}
          </div>
          <div>
            Estimation min caractères :
            {{ calculateNBChar().min }}
          </div>
        </div>
        <div class="d-flex justify-space-between">
          <div>
            Estimation max SMS :
            {{ calculateNBSMS().max }}
          </div>
          <div>
            Estimation max caractères :
            {{ calculateNBChar().max }}
          </div>
        </div>
      </div>
    </v-col>
    <v-col cols="12" md="4">
      <v-btn
        type="button"
        v-for="v in vars"
        :key="v"
        @click="addVarToSMS('[' + v + ']')"
        small
        text
      >
        [{{ v }}]
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'smsTextarea',
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    vars: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    calculateNBSMS() {
      return {
        min: Math.ceil(this.calculateNBChar(this.value).min / 160),
        max: Math.ceil(this.calculateNBChar(this.value).max / 160)
      }
    },
    calculateNBChar() {
      let nb = this.value
        .split('[date]')
        .join('')
        .split('[shop]')
        .join('')
        .split('[firstname]')
        .join('')
        .split('[lastname]')
        .join('')
        .split('[address]')
        .join('').length

      const minShopsName = this.$store.state.shop.shops.reduce((acc, cur) => {
        const l = cur.publicInfo.name ? cur.publicInfo.name.length : 0

        if (acc === null || acc > l) {
          acc = l
        }

        return acc
      }, null)
      const maxShopsName = this.$store.state.shop.shops.reduce((acc, cur) => {
        const l = cur.publicInfo.name ? cur.publicInfo.name.length : 0

        if (acc === null || acc < l) {
          acc = l
        }

        return acc
      }, null)
      // const moyenneShopsName = Math.ceil(
      //   this.$store.state.shop.shops.reduce((acc, cur) => {
      //     acc += cur.publicInfo.name ? cur.publicInfo.name.length : 0
      //
      //     return acc
      //   }, 0) / this.$store.state.shop.shops.length
      // )
      const minShopsAddress = this.$store.state.shop.shops.reduce(
        (acc, cur) => {
          let l = 0

          l +=
            cur.publicInfo.address && cur.publicInfo.address.address
              ? cur.publicInfo.address.address.length
              : 0
          l +=
            cur.publicInfo.address && cur.publicInfo.address.city
              ? cur.publicInfo.address.city.length
              : 0
          l +=
            cur.publicInfo.address && cur.publicInfo.address.zipcode
              ? cur.publicInfo.address.zipcode.length
              : 0
          l +=
            cur.publicInfo.address && cur.publicInfo.address.country
              ? cur.publicInfo.address.country.length
              : 0

          if (acc === null || acc > l) {
            acc = l
          }

          return acc
        },
        null
      )
      const maxShopsAddress = this.$store.state.shop.shops.reduce(
        (acc, cur) => {
          let l = 0

          l +=
            cur.publicInfo.address && cur.publicInfo.address.address
              ? cur.publicInfo.address.address.length
              : 0
          l +=
            cur.publicInfo.address && cur.publicInfo.address.city
              ? cur.publicInfo.address.city.length
              : 0
          l +=
            cur.publicInfo.address && cur.publicInfo.address.zipcode
              ? cur.publicInfo.address.zipcode.length
              : 0
          l +=
            cur.publicInfo.address && cur.publicInfo.address.country
              ? cur.publicInfo.address.country.length
              : 0

          if (acc === null || acc < l) {
            acc = l
          }

          return acc
        },
        null
      )
      // const moyenneShopsAddress = Math.ceil(
      //   this.$store.state.shop.shops.reduce((acc, cur) => {
      //     acc +=
      //       cur.publicInfo.address && cur.publicInfo.address.address
      //         ? cur.publicInfo.address.address.length
      //         : 0
      //     acc +=
      //       cur.publicInfo.address && cur.publicInfo.address.city
      //         ? cur.publicInfo.address.city.length
      //         : 0
      //     acc +=
      //       cur.publicInfo.address && cur.publicInfo.address.zipcode
      //         ? cur.publicInfo.address.zipcode.length
      //         : 0
      //     acc +=
      //       cur.publicInfo.address && cur.publicInfo.address.country
      //         ? cur.publicInfo.address.country.length
      //         : 0
      //
      //     return acc
      //   }, 0) / this.$store.state.shop.shops.length
      // )

      let min = nb
      let max = nb

      min += (this.value.match(/\[date\]/g) || []).length * 16
      min += (this.value.match(/\[shop\]/g) || []).length * minShopsName
      min += (this.value.match(/\[firstname\]/g) || []).length * 6
      min += (this.value.match(/\[lastname\]/g) || []).length * 8
      min += (this.value.match(/\[address\]/g) || []).length * minShopsAddress

      max += (this.value.match(/\[date\]/g) || []).length * 16
      max += (this.value.match(/\[shop\]/g) || []).length * maxShopsName
      max += (this.value.match(/\[firstname\]/g) || []).length * 16
      max += (this.value.match(/\[lastname\]/g) || []).length * 20
      max += (this.value.match(/\[address\]/g) || []).length * maxShopsAddress

      return {
        min,
        max
      }
    },
    addVarToSMS(varName) {
      this.$emit('input', this.value + ' ' + varName)
    }
  }
}
</script>

<style scoped></style>
