<template>
  <div>
    <p>Bientôt disponible !</p>

    <div v-if="false">
      <v-switch
        inset
        v-model="myCompany.booker.products.active"
        :label="$t('settings.shop.enableSaleProducts')"
      />

      <v-switch
        inset
        v-model="myCompany.booker.products.list.display.price"
        :label="$t('settings.shop.displayPrices')"
        disabled
      />

      <v-switch
        inset
        v-model="myCompany.booker.products.list.display.duration"
        :label="$t('settings.shop.displayDurations')"
        disabled
      />
    </div>

    <v-row v-if="false" align="stretch">
      <v-col cols="12 h4-content">
        <h4>Titre</h4>
        <span />
      </v-col>
      <v-col cols="12">
        <v-switch
          inset
          v-model="myCompany.booker.products.list.where.central"
          :label="$t('settings.shop.soldableInCentral')"
        />
        <v-switch
          inset
          v-model="myCompany.booker.products.list.where.shop"
          :label="$t('settings.shop.soldableInShop')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'boutique',
  props: {
    myCompany: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    this.initBoutique()
  },
  methods: {
    initBoutique() {
      if (!this.myCompany.booker) {
        Vue.set(this.myCompany, 'booker', {})
      }

      if (!this.myCompany.booker.products) {
        Vue.set(this.myCompany.booker, 'products', {
          active: false,
          list: {
            display: {
              price: true,
              duration: true
            }
          }
        })
      }

      if (typeof this.myCompany.booker.products.active === 'undefined') {
        Vue.set(this.myCompany.booker.products, 'active', false)
      }

      if (!this.myCompany.booker.products.list.where) {
        Vue.set(this.myCompany.booker.products.list, 'where', {
          central: true,
          shop: false
        })
      }
    }
  }
}
</script>

<style scoped></style>
