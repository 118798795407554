const getJSONValue = (obj, path) => {
  const pathSegments = path.split('.')

  let value = null
  let lastKey = null
  let stop = false

  if (!pathSegments) {
    return null
  }

  pathSegments.forEach((segment) => {
    if (stop) {
      return value
    }

    if (lastKey) {
      if (lastKey[segment]) {
        value = lastKey[segment]
        lastKey = lastKey[segment]
      } else {
        value = null
        stop = true
      }
    } else if (obj[segment]) {
      lastKey = obj[segment]
    } else {
      value = null
      stop = true
    }
  })

  return value
}

export default {
  getJSONValue,
}
