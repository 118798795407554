<template>
  <div>
    <div class="text-center">
      <v-btn-toggle v-model="myCompany.themes.general.type" mandatory>
        <v-btn value="simplified">
          {{ $t('settings.colors.simplified') }}
        </v-btn>
        <v-btn value="personalized">
          {{ $t('settings.colors.personalized') }}
        </v-btn>
        <v-btn value="advanced">
          {{ $t('settings.colors.advanced') }}
        </v-btn>
      </v-btn-toggle>
    </div>

    <!--   MODE SIMPLIFIE     -->
    <div
      v-if="myCompany.themes.general.type === 'simplified'"
      class="d-flex align-center justify-center flex-wrap"
    >
      <v-row dense align="stretch">
        <v-col
          cols="12"
          md="6"
          v-for="colors in colorsTemplate"
          :key="colors.objectId"
        >
          <v-card class="mt-10 pb-4" @click="setColors(colors)">
            <div class="d-flex align-center mx-3">
              <span class="font-weight-bold">
                {{ colors.title }}
              </span>
              <v-spacer />
              <div
                class="d-flex align-center justify-center"
                style="height: 75px"
              >
                <div
                  :style="{ backgroundColor: colors.headerColor }"
                  class="pastilleLight"
                />
                <div
                  :style="{ backgroundColor: colors.buttonsColor }"
                  class="pastilleLight"
                />
                <div
                  :style="{ backgroundColor: colors.mkpBackgroundColor }"
                  class="pastilleLight"
                />
              </div>
            </div>
            <div class="mx-4" style="border: 1px solid #333333">
              <preview
                :colors="colors"
                :nickname="myCompany.identity.displayName"
              />
            </div>
          </v-card>
          <div class="text-center mt-2">
            <v-icon
              v-if="
                myCompany.themes.general.selectedColorTemplate === colors.id
              "
              color="green"
              size="30"
            >
              mdi-check-circle
            </v-icon>
            <v-icon v-else size="30"> mdi-check-circle </v-icon>
          </div>
        </v-col>
      </v-row>
    </div>

    <!--  MODE PERSONNALISE  -->
    <div v-if="myCompany.themes.general.type === 'personalized'">
      <div class="text-center my-5">
        {{ $t('settings.colors.clickButtonToSelectColor') }}
      </div>

      <v-row>
        <v-col cols="12" md="6" align-self="center">
          <div class="mx-10 mb-7">
            <h4 class="mb-2">
              {{ $t('settings.appearance.backgroundColor') }}
            </h4>
            <div
              class="swatch"
              :style="{
                backgroundColor: myCompany.themes.general.mkpBackgroundColor,
              }"
              @click="
                selectedPicker === 'mkpBackgroundColorPerso'
                  ? (selectedPicker = null)
                  : (selectedPicker = 'mkpBackgroundColorPerso')
              "
            />
            <div style="height: 8px"></div>
            <transition name="onHeight">
              <div v-if="selectedPicker === 'mkpBackgroundColorPerso'">
                <v-color-picker
                  v-model="myCompany.themes.general.mkpBackgroundColor"
                  :swatches="swatches"
                  show-swatches
                  mode="hexa"
                  class="ma-auto"
                  @update:color="changeBackground"
                />

                <v-btn
                  class="d-block mx-auto"
                  dark
                  color="dark"
                  style="width: 250px"
                  @click="selectedPicker = null"
                >
                  Ok
                </v-btn>
              </div>
            </transition>
          </div>
          <div class="mx-10 mb-7">
            <h4 class="mb-2">
              {{
                $t('settings.colors.headerColor') +
                ' / ' +
                $t('settings.colors.footerColor')
              }}
            </h4>
            <div
              class="swatch"
              :style="{
                backgroundColor: myCompany.themes.general.headerColor,
              }"
              @click="
                selectedPicker === 'headerColorPerso'
                  ? (selectedPicker = null)
                  : (selectedPicker = 'headerColorPerso')
              "
            />
            <div style="height: 8px"></div>
            <transition name="onHeight">
              <div v-if="selectedPicker === 'headerColorPerso'">
                <v-color-picker
                  v-model="myCompany.themes.general.headerColor"
                  :swatches="swatches"
                  show-swatches
                  mode="hexa"
                  class="ma-auto"
                  @update:color="changeHeader"
                />

                <v-btn
                  class="d-block mx-auto"
                  dark
                  color="dark"
                  style="width: 250px"
                  @click="selectedPicker = null"
                >
                  Ok
                </v-btn>
              </div>
            </transition>
          </div>
          <div class="mx-10 mb-7">
            <h4 class="mb-2">
              {{ $t('settings.colors.colorsUnderHeaderPerso') }}
            </h4>
            <div
              class="swatch"
              :style="{
                backgroundColor: myCompany.themes.general.colorUnderHeader,
              }"
              @click="
                selectedPicker === 'colorUnderHeaderPerso'
                  ? (selectedPicker = null)
                  : (selectedPicker = 'colorUnderHeaderPerso')
              "
            />
            <div style="height: 8px"></div>
            <transition name="onHeight">
              <div v-if="selectedPicker === 'colorUnderHeaderPerso'">
                <v-color-picker
                  v-model="myCompany.themes.general.colorUnderHeader"
                  :swatches="swatches"
                  show-swatches
                  mode="hexa"
                  class="ma-auto"
                  @update:color="changeUnderHeader"
                />

                <v-btn
                  class="d-block mx-auto"
                  dark
                  color="dark"
                  style="width: 250px"
                  @click="selectedPicker = null"
                >
                  Ok
                </v-btn>
              </div>
            </transition>
          </div>
          <div class="mx-10 mb-7">
            <h4 class="mb-2">Couleur de fond des titres</h4>
            <div
              class="swatch"
              :style="{
                backgroundColor:
                  myCompany.themes.general.headingsBackgroundColor,
              }"
              @click="
                selectedPicker === 'colorHeadingsBackgroundColor'
                  ? (selectedPicker = null)
                  : (selectedPicker = 'colorHeadingsBackgroundColor')
              "
            />
            <div style="height: 8px"></div>
            <transition name="onHeight">
              <div v-if="selectedPicker === 'colorHeadingsBackgroundColor'">
                <v-color-picker
                  v-model="myCompany.themes.general.headingsBackgroundColor"
                  :swatches="swatches"
                  show-swatches
                  mode="hexa"
                  class="ma-auto"
                  @update:color="changeHeadingsBackgroundColor"
                />

                <v-btn
                  class="d-block mx-auto"
                  dark
                  color="dark"
                  style="width: 250px"
                  @click="selectedPicker = null"
                >
                  Ok
                </v-btn>
              </div>
            </transition>
          </div>
          <div class="mx-10 mb-7">
            <h4 class="mb-2">{{ $t('settings.colors.buttonsColor') }}</h4>
            <div
              class="swatch"
              :style="{
                backgroundColor: myCompany.themes.general.buttonsColor,
              }"
              @click="
                selectedPicker === 'buttonsColorPerso'
                  ? (selectedPicker = null)
                  : (selectedPicker = 'buttonsColorPerso')
              "
            />
            <div style="height: 8px"></div>
            <transition name="onHeight">
              <div v-if="selectedPicker === 'buttonsColorPerso'">
                <v-color-picker
                  v-model="myCompany.themes.general.buttonsColor"
                  :swatches="swatches"
                  show-swatches
                  mode="hexa"
                  class="ma-auto"
                  @update:color="changeButtons"
                />

                <v-btn
                  class="d-block mx-auto"
                  dark
                  color="dark"
                  style="width: 250px"
                  @click="selectedPicker = null"
                >
                  Ok
                </v-btn>
              </div>
            </transition>
          </div>
        </v-col>
        <v-col cols="12" md="6" align-self="center">
          <div class="mx-4" style="border: 1px solid #333333">
            <preview
              :colors="myCompany.themes.general"
              :nickname="myCompany.identity.displayName"
              type="general"
            />
          </div>
        </v-col>
      </v-row>
    </div>

    <!--   MODE AVANCE     -->
    <v-tabs
      v-if="myCompany.themes.general.type === 'advanced'"
      v-model="colorTab"
      fixed-tabs
      active-class="font-weight-bold"
    >
      <v-tab class="black--text">
        {{ $t('settings.onlineBooking.general') }}
      </v-tab>
      <v-tab class="black--text">
        {{ $t('settings.appearance.reservation') }}
      </v-tab>
      <v-tab class="black--text">
        {{ $t('settings.colors.underHeader') }}
      </v-tab>
      <v-tab class="black--text">
        {{ $t('settings.colors.numberPersons') }}
      </v-tab>
      <v-tab class="black--text"> {{ $t('settings.colors.events') }} </v-tab>

      <v-tab-item class="my-10" eager>
        <div
          :class="
            $vuetify.breakpoint.smAndDown
              ? ''
              : 'd-flex align-center justify-space-around my-10'
          "
        >
          <div class="text-center">
            <div class="font-weight-bold">
              {{ $t('settings.appearance.homePageBackground') }}
            </div>
            <div class="d-flex align-center justify-center">
              <span>{{ $t('settings.appearance.image') }}</span>
              <v-switch
                inset
                v-model="myCompany.booker.backgroundColor"
                class="ml-3 mr-2"
              />
              <span>{{ $t('settings.appearance.color') }}</span>
            </div>
          </div>
          <div class="text-center">
            <div class="font-weight-bold">
              {{ $t('settings.appearance.homePageTextColor') }}
            </div>

            <div class="d-flex align-center justify-center">
              <div
                style="background-color: white"
                class="pastille"
                @click="myCompany.themes.dark = false"
              />
              <v-switch inset v-model="myCompany.themes.dark" class="mx-5" />
              <div
                style="background-color: black"
                class="pastille"
                @click="myCompany.themes.dark = true"
              />
            </div>
          </div>
        </div>

        <div v-if="!myCompany.booker.backgroundColor" class="mb-5">
          <div class="d-flex align-center justify-center">
            <v-switch
              inset
              v-model="myCompany.booker.backgroundRepeat"
              class="mx-5"
              :label="$t('settings.appearance.repeatMode')"
            />
          </div>
          <div class="image-edit-panel mx-auto" style="height: 400px">
            <images-selector
              v-model="mkpBackgroundImageData"
              single
              height="350px"
              :size="
                myCompany.booker.backgroundRepeat ? [350, 350] : [1920, 1080]
              "
            />
          </div>
        </div>

        <div class="text-center my-2">
          {{ $t('settings.colors.clickButtonToSelectColor') }}
        </div>

        <v-row>
          <v-col cols="12" md="6" align-self="center">
            <div class="mx-10 mb-7">
              <h4 class="mb-2">
                {{ $t('settings.appearance.backgroundColor') }}
              </h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor: myCompany.themes.general.mkpBackgroundColor,
                }"
                @click="
                  selectedPicker === 'mkpBackgroundColor'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'mkpBackgroundColor')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'mkpBackgroundColor'">
                  <v-color-picker
                    v-model="myCompany.themes.general.mkpBackgroundColor"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
            <div class="mx-10 mb-7" v-if="!!myCompany.themes.siteContained">
              <h4 class="mb-2">Couleur de fond (mode conteneurisé)</h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor: myCompany.themes.containedBackgroundColor
                    ? myCompany.themes.containedBackgroundColor.hex
                      ? myCompany.themes.containedBackgroundColor.hex
                      : myCompany.themes.containedBackgroundColor
                    : '#ffffff',
                }"
                @click="
                  selectedPicker === 'containedBackgroundColor'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'containedBackgroundColor')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'containedBackgroundColor'">
                  <v-color-picker
                    v-model="myCompany.themes.containedBackgroundColor"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
            <div class="mx-10 mb-7">
              <h4 class="mb-2">{{ $t('settings.colors.headerColor') }}</h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor: myCompany.themes.general.headerColor,
                }"
                @click="
                  selectedPicker === 'headerColor'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'headerColor')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'headerColor'">
                  <v-color-picker
                    v-model="myCompany.themes.general.headerColor"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
            <div class="mx-10 mb-7">
              <h4 class="mb-2">{{ $t('settings.colors.menuColor') }}</h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor: myCompany.themes.general.menuColor,
                }"
                @click="
                  selectedPicker === 'menuColor'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'menuColor')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'menuColor'">
                  <v-color-picker
                    v-model="myCompany.themes.general.menuColor"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
            <div class="mx-10 mb-7">
              <h4 class="mb-2">Couleur de fond des titres</h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor:
                    myCompany.themes.general.headingsBackgroundColor,
                }"
                @click="
                  selectedPicker === 'headingsBackgroundColor'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'headingsBackgroundColor')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'headingsBackgroundColor'">
                  <v-color-picker
                    v-model="myCompany.themes.general.headingsBackgroundColor"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
            <div class="mx-10 mb-7">
              <h4 class="mb-2">{{ $t('settings.colors.buttonsColor') }}</h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor: myCompany.themes.general.buttonsColor,
                }"
                @click="
                  selectedPicker === 'buttonsColor'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'buttonsColor')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'buttonsColor'">
                  <v-color-picker
                    v-model="myCompany.themes.general.buttonsColor"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
            <div class="mx-10 mb-7">
              <h4 class="mb-2">{{ $t('settings.colors.footerColor') }}</h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor: myCompany.themes.general.footerColor,
                }"
                @click="
                  selectedPicker === 'footerColor'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'footerColor')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'footerColor'">
                  <v-color-picker
                    v-model="myCompany.themes.general.footerColor"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div class="mx-4" style="border: 1px solid #333333">
              <preview
                :colors="myCompany.themes.general"
                :nickname="myCompany.identity.displayName"
                type="general"
              />
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item class="ma-10" eager>
        <div class="text-center mb-2">
          {{ $t('settings.colors.clickButtonToSelectColor') }}
        </div>
        <v-row>
          <v-col cols="12" md="6">
            <div class="mx-10 mb-7">
              <h4 class="mb-2">{{ $t('settings.colors.buttonsColor') }}</h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor: myCompany.themes.general.bookingAccentColor,
                }"
                @click="
                  selectedPicker === 'bookingAccentColor'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'bookingAccentColor')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'bookingAccentColor'">
                  <v-color-picker
                    v-model="myCompany.themes.general.bookingAccentColor"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
            <div class="mx-10 mb-7">
              <h4 class="mb-2">
                {{ $t('settings.colors.reservationColor') }}
              </h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor: myCompany.themes.general.bookingGeneralColor,
                }"
                @click="
                  selectedPicker === 'bookingGeneralColor'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'bookingGeneralColor')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'bookingGeneralColor'">
                  <v-color-picker
                    v-model="myCompany.themes.general.bookingGeneralColor"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
            <div class="mx-10 mb-7">
              <h4 class="mb-2">Couleur de fond du calendrier</h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor:
                    myCompany.themes.general.backgroundBookingCalendar,
                }"
                @click="
                  selectedPicker === 'backgroundBookingCalendar'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'backgroundBookingCalendar')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'backgroundBookingCalendar'">
                  <v-color-picker
                    v-model="myCompany.themes.general.backgroundBookingCalendar"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div style="border: 1px solid #333333">
              <preview
                :colors="myCompany.themes.general"
                :nickname="myCompany.identity.displayName"
                type="reservation"
              />
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item class="ma-10" eager>
        <div class="text-center mb-2">
          {{ $t('settings.colors.clickButtonToSelectColor') }}
        </div>
        <v-row>
          <v-col cols="12" md="6" align-self="center">
            <div class="mx-10 mb-7">
              <h4 class="mb-2">
                {{ $t('settings.colors.textColorUnderHeader') }}
              </h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor: myCompany.themes.general.accentColor,
                }"
                @click="
                  selectedPicker === 'accentColor'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'accentColor')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'accentColor'">
                  <v-color-picker
                    v-model="myCompany.themes.general.accentColor"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
            <div class="mx-10 mb-7">
              <h4 class="mb-2">
                {{ $t('settings.colors.colorUnderHeader') }}
              </h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor: myCompany.themes.general.colorUnderHeader,
                }"
                @click="
                  selectedPicker === 'colorUnderHeader'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'colorUnderHeader')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'colorUnderHeader'">
                  <v-color-picker
                    v-model="myCompany.themes.general.colorUnderHeader"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div class="my-5 text-center">
              <div style="border: 1px solid #333333">
                <preview
                  :colors="myCompany.themes.general"
                  :nickname="myCompany.identity.displayName"
                  type="subHeader"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item class="ma-10" eager>
        <div class="text-center mb-2">
          {{ $t('settings.colors.clickButtonToSelectColor') }}
        </div>
        <v-row>
          <v-col cols="12" md="6" align-self="center">
            <div class="mx-10 mb-7">
              <h4 class="mb-2">Solo</h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor: myCompany.themes.general.buttonSolo,
                }"
                @click="
                  selectedPicker === 'buttonSolo'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'buttonSolo')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'buttonSolo'">
                  <v-color-picker
                    v-model="myCompany.themes.general.buttonSolo"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
            <div class="mx-10 mb-7">
              <h4 class="mb-2">Duo</h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor: myCompany.themes.general.buttonDuo,
                }"
                @click="
                  selectedPicker === 'buttonDuo'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'buttonDuo')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'buttonDuo'">
                  <v-color-picker
                    v-model="myCompany.themes.general.buttonDuo"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
            <div class="mx-10 mb-7">
              <h4 class="mb-2">{{ $t('settings.colors.groups') }}</h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor: myCompany.themes.general.buttonOther,
                }"
                @click="
                  selectedPicker === 'buttonOther'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'buttonOther')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'buttonOther'">
                  <v-color-picker
                    v-model="myCompany.themes.general.buttonOther"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div style="border: 1px solid #333333">
              <preview
                :colors="myCompany.themes.general"
                :nickname="myCompany.identity.displayName"
                type="numberPerson"
              />
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item class="my-10" eager>
        <div class="text-center mb-2">
          {{ $t('settings.colors.clickButtonToSelectColor') }}
        </div>
        <v-row>
          <v-col cols="12" md="6" align-self="center">
            <div class="mx-10 mb-7">
              <h4 class="mb-2">{{ $t('settings.colors.colorUnderHeader') }}</h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor:
                    myCompany.themes.general.backgroundColorEvents,
                }"
                @click="
                  selectedPicker === 'backgroundColorEvents'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'backgroundColorEvents')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'backgroundColorEvents'">
                  <v-color-picker
                    v-model="myCompany.themes.general.backgroundColorEvents"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
            <div class="mx-10 mb-7">
              <h4 class="mb-2">{{ $t('settings.colors.dates') }}</h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor: myCompany.themes.general.datesEvents,
                }"
                @click="
                  selectedPicker === 'datesEvents'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'datesEvents')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'datesEvents'">
                  <v-color-picker
                    v-model="myCompany.themes.general.datesEvents"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
            <div class="mx-10 mb-7">
              <h4 class="mb-2">{{ $t('settings.colors.contentColor') }}</h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor: myCompany.themes.general.bodyEvents,
                }"
                @click="
                  selectedPicker === 'bodyEvents'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'bodyEvents')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'bodyEvents'">
                  <v-color-picker
                    v-model="myCompany.themes.general.bodyEvents"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
            <div class="mx-10 mb-7">
              <h4 class="mb-2">{{ $t('settings.colors.closeBtn') }}</h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor: myCompany.themes.general.closeBtnEvents,
                }"
                @click="
                  selectedPicker === 'closeBtnEvents'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'closeBtnEvents')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'closeBtnEvents'">
                  <v-color-picker
                    v-model="myCompany.themes.general.closeBtnEvents"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
            <div class="mx-10 mb-7">
              <h4 class="mb-2">{{ $t('settings.colors.linkBtn') }}</h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor: myCompany.themes.general.linkBtnEvents,
                }"
                @click="
                  selectedPicker === 'linkBtnEvents'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'linkBtnEvents')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'linkBtnEvents'">
                  <v-color-picker
                    v-model="myCompany.themes.general.linkBtnEvents"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
            <div class="mx-10 mb-7">
              <h4 class="mb-2">{{ $t('settings.colors.numberOfEvents') }}</h4>
              <div
                class="swatch"
                :style="{
                  backgroundColor: myCompany.themes.general.pageEvents,
                }"
                @click="
                  selectedPicker === 'pageEvents'
                    ? (selectedPicker = null)
                    : (selectedPicker = 'pageEvents')
                "
              />
              <div style="height: 8px"></div>
              <transition name="onHeight">
                <div v-if="selectedPicker === 'pageEvents'">
                  <v-color-picker
                    v-model="myCompany.themes.general.pageEvents"
                    :swatches="swatches"
                    show-swatches
                    mode="hexa"
                    class="ma-auto"
                  />

                  <v-btn
                    class="d-block mx-auto"
                    dark
                    color="dark"
                    style="width: 250px"
                    @click="selectedPicker = null"
                  >
                    Ok
                  </v-btn>
                </div>
              </transition>
            </div>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div style="border: 1px solid #333333; border-radius: 5px">
              <preview
                :colors="myCompany.themes.general"
                :nickname="myCompany.identity.displayName"
                type="events"
              />
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import Preview from './helpers/preview'
import ImagesSelector from '../helpers/imagesSelector'

export default {
  name: 'colors',
  components: { ImagesSelector, Preview },
  props: {
    myCompany: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      colorTab: 0,
      selectedPicker: null,
      colorsTemplate: [
        {
          id: 0,
          title: this.$t('settings.colors.default'),
          mkpBackgroundColor: '#FFFFFF',
          headerColor: '#FFFFFF',
          menuColor: '#FFFFFF',
          buttonsColor: '#000000',
          footerColor: '#000000',
          bookingAccentColor: '#FFFFFF',
          bookingGeneralColor: '#FFFFFF',
          accentColor: '#FFFFFF',
          colorUnderHeader: '#000000',
          buttonSolo: '#000000',
          buttonDuo: '#FFFFFF',
          buttonOther: '#D4AF37',
          backgroundColorEvents: '#FFFFFF',
          datesEvents: '#FFFFFF',
          bodyEvents: '#999999',
          closeBtnEvents: '#FFFFFF',
          linkBtnEvents: '#000000',
          pageEvents: '#666666',
          dark: true,
        },
        {
          id: 1,
          title: this.$t('settings.colors.blue'),
          mkpBackgroundColor: '#FFFFFF',
          headerColor: '#91C1DB',
          menuColor: '#91C1DB',
          buttonsColor: '#0E3D63',
          footerColor: '#91C1DB',
          bookingAccentColor: '#006094',
          bookingGeneralColor: '#E7EDEE',
          accentColor: '#000000',
          colorUnderHeader: '#E7EDEE',
          buttonSolo: '#0E3D63',
          buttonDuo: '#FFFFFF',
          buttonOther: '#000000',
          backgroundColorEvents: '#FFFFFF',
          datesEvents: '#FFFFFF',
          bodyEvents: '#999999',
          closeBtnEvents: '#FFFFFF',
          linkBtnEvents: '#0E3D63',
          pageEvents: '#666666',
          dark: true,
        },
        {
          id: 2,
          title: this.$t('settings.colors.green'),
          mkpBackgroundColor: '#FFFFFF',
          headerColor: '#B7CFB1',
          menuColor: '#B7CFB1',
          buttonsColor: '#26492A',
          footerColor: '#B7CFB1',
          bookingAccentColor: '#26492A',
          bookingGeneralColor: '#B7CFB1',
          accentColor: '#000000',
          colorUnderHeader: '#FFFFFF',
          buttonSolo: '#26492A',
          buttonDuo: '#FFFFFF',
          buttonOther: '#000000',
          backgroundColorEvents: '#FFFFFF',
          datesEvents: '#FFFFFF',
          bodyEvents: '#999999',
          closeBtnEvents: '#FFFFFF',
          linkBtnEvents: '#26492A',
          pageEvents: '#666666',
          dark: true,
        },
        {
          id: 3,
          title: this.$t('settings.colors.brown'),
          mkpBackgroundColor: '#FFFFFF',
          headerColor: '#3B2409',
          menuColor: '#3B2409',
          buttonsColor: '#DBC6AF',
          footerColor: '#3B2409',
          bookingAccentColor: '#DBC6AF',
          bookingGeneralColor: '#E6DDD4',
          accentColor: '#000000',
          colorUnderHeader: '#DBC6AF',
          buttonSolo: '#DBC6AF',
          buttonDuo: '#FFFFFF',
          buttonOther: '#3B2409',
          backgroundColorEvents: '#FFFFFF',
          datesEvents: '#FFFFFF',
          bodyEvents: '#999999',
          closeBtnEvents: '#FFFFFF',
          linkBtnEvents: '#DBC6AF',
          pageEvents: '#666666',
          dark: true,
        },
        {
          id: 4,
          title: this.$t('settings.colors.pink'),
          mkpBackgroundColor: '#FFFFFF',
          headerColor: '#DBBCCE',
          menuColor: '#DBBCCE',
          buttonsColor: '#DBBCCE',
          footerColor: '#DBBCCE',
          bookingAccentColor: '#DBBCCE',
          bookingGeneralColor: '#FEF8FF',
          accentColor: '#000000',
          colorUnderHeader: '#FEF8FF',
          buttonSolo: '#FEF8FF',
          buttonDuo: '#FFFFFF',
          buttonOther: '#DBBCCE',
          backgroundColorEvents: '#FFFFFF',
          datesEvents: '#FFFFFF',
          bodyEvents: '#999999',
          closeBtnEvents: '#FFFFFF',
          linkBtnEvents: '#DBBCCE',
          pageEvents: '#666666',
          dark: true,
        },
        {
          id: 5,
          title: 'Smartbooker',
          mkpBackgroundColor: '#FFFFFF',
          headerColor: '#CBBA95',
          menuColor: '#CBBA95',
          buttonsColor: '#2B2B2B',
          footerColor: '#CBBA95',
          bookingAccentColor: '#CBBA95',
          bookingGeneralColor: '#F5F3EF',
          accentColor: '#FFFFFF',
          colorUnderHeader: '#2B2B2B',
          buttonSolo: '#CBBA95',
          buttonDuo: '#FFFFFF',
          buttonOther: '#2B2B2B',
          backgroundColorEvents: '#FFFFFF',
          datesEvents: '#FFFFFF',
          bodyEvents: '#999999',
          closeBtnEvents: '#FFFFFF',
          linkBtnEvents: '#2B2B2B',
          pageEvents: '#666666',
          dark: true,
        },
        {
          id: 6,
          title: this.$t('settings.colors.red'),
          mkpBackgroundColor: '#FFFFFF',
          headerColor: '#8D2222',
          menuColor: '#8D2222',
          buttonsColor: '#2B2B2B',
          footerColor: '#8D2222',
          bookingAccentColor: '#8D2222',
          bookingGeneralColor: '#F5F3EF',
          accentColor: '#000000',
          colorUnderHeader: '#DDC7C7',
          buttonSolo: '#8D2222',
          buttonDuo: '#FFFFFF',
          buttonOther: '#2B2B2B',
          backgroundColorEvents: '#FFFFFF',
          datesEvents: '#FFFFFF',
          bodyEvents: '#999999',
          closeBtnEvents: '#FFFFFF',
          linkBtnEvents: '#2B2B2B',
          pageEvents: '#666666',
          dark: true,
        },
      ],
      swatches: [
        ['#FFFFFF', '#000000', '#999999'],
        ['#FFFF00', '#FF0000', '#0000FF'],
        ['#00FF00', '#FF7F00', '#50026A'],
        ['#fa2c96', '#D4AF37', '#7B3F00'],
      ],
      allColors: [
        'mkpBackgroundColor',
        'headerColor',
        'menuColor',
        'buttonsColor',
        'footerColor',
        'bookingAccentColor',
        'bookingGeneralColor',
        'accentColor',
        'colorUnderHeader',
        'buttonSolo',
        'buttonDuo',
        'buttonOther',
        'backgroundColorEvents',
        'datesEvents',
        'bodyEvents',
        'closeBtnEvents',
        'linkBtnEvents',
        'pageEvents',
      ],
      mkpBackgroundImageData: null,
    }
  },
  created() {
    this.initColors()
  },
  methods: {
    initColors() {
      if (!this.myCompany.themes) {
        Vue.set(this.myCompany, 'themes', {})
      }

      if (typeof this.myCompany.themes.siteContained === 'undefined') {
        Vue.set(this.myCompany.themes, 'siteContained', false)
      }

      if (
        typeof this.myCompany.themes.containedBackgroundColor === 'undefined'
      ) {
        Vue.set(this.myCompany.themes, 'containedBackgroundColor', '#ffffff')
      }

      if (!this.myCompany.themes.general) {
        Vue.set(this.myCompany.themes, 'general', {})
      }

      if (typeof this.myCompany.themes.general.advanced !== 'undefined') {
        Vue.delete(this.myCompany.themes.general, 'advanced')
      }

      if (!this.myCompany.themes.general.type) {
        Vue.set(this.myCompany.themes.general, 'type', 'simplified')
      }

      if (!this.myCompany.themes.general.selectedColorTemplate) {
        Vue.set(this.myCompany.themes.general, 'selectedColorTemplate', null)
      }

      if (!this.myCompany.themes.general.mkpBackgroundColor) {
        Vue.set(this.myCompany.themes.general, 'mkpBackgroundColor', '#FFFFFF')
      }

      if (!this.myCompany.themes.general.headerColor) {
        Vue.set(this.myCompany.themes.general, 'headerColor', '#FFFFFF')
      }

      if (!this.myCompany.themes.general.headingsBackgroundColor) {
        Vue.set(
          this.myCompany.themes.general,
          'headingsBackgroundColor',
          '#ffffff'
        )
      }

      if (!this.myCompany.themes.general.buttonsColor) {
        Vue.set(this.myCompany.themes.general, 'buttonsColor', '#000000')
      }

      if (!this.myCompany.themes.general.footerColor) {
        Vue.set(this.myCompany.themes.general, 'footerColor', '#000000')
      }

      if (!this.myCompany.themes.general.bookingAccentColor) {
        Vue.set(this.myCompany.themes.general, 'bookingAccentColor', '#FFFFFF')
      }

      if (!this.myCompany.themes.general.backgroundBookingCalendar) {
        Vue.set(
          this.myCompany.themes.general,
          'backgroundBookingCalendar',
          '#FFFFFF'
        )
      }

      if (!this.myCompany.themes.general.bookingGeneralColor) {
        Vue.set(this.myCompany.themes.general, 'bookingGeneralColor', '#FFFFFF')
      }

      if (!this.myCompany.themes.general.accentColor) {
        Vue.set(this.myCompany.themes.general, 'accentColor', '#FFFFFF')
      }

      if (!this.myCompany.themes.general.colorUnderHeader) {
        Vue.set(this.myCompany.themes.general, 'colorUnderHeader', '#000000')
      }

      if (!this.myCompany.themes.general.buttonSolo) {
        Vue.set(this.myCompany.themes.general, 'buttonSolo', '#000000')
      }

      if (!this.myCompany.themes.general.buttonDuo) {
        Vue.set(this.myCompany.themes.general, 'buttonDuo', '#FFFFFF')
      }

      if (!this.myCompany.themes.general.buttonOther) {
        Vue.set(this.myCompany.themes.general, 'buttonOther', '#D4AF37')
      }

      if (!this.myCompany.themes.general.menuColor) {
        Vue.set(this.myCompany.themes.general, 'menuColor', '#FFFFFF')
      }

      if (!this.myCompany.themes.general.backgroundColorEvents) {
        Vue.set(
          this.myCompany.themes.general,
          'backgroundColorEvents',
          '#FFFFFF'
        )
      }

      if (!this.myCompany.themes.general.datesEvents) {
        Vue.set(this.myCompany.themes.general, 'datesEvents', '#FFFFFF')
      }

      if (!this.myCompany.themes.general.bodyEvents) {
        Vue.set(this.myCompany.themes.general, 'bodyEvents', '#999999')
      }

      if (!this.myCompany.themes.general.closeBtnEvents) {
        Vue.set(this.myCompany.themes.general, 'closeBtnEvents', '#FFFFFF')
      }

      if (!this.myCompany.themes.general.linkBtnEvents) {
        Vue.set(this.myCompany.themes.general, 'linkBtnEvents', '#000000')
      }

      if (!this.myCompany.themes.general.pageEvents) {
        Vue.set(this.myCompany.themes.general, 'pageEvents', '#666666')
      }

      if (!this.myCompany.booker) {
        Vue.set(this.myCompany, 'booker', {})
      }

      if (typeof this.myCompany.booker.backgroundColor === 'undefined') {
        Vue.set(this.myCompany.booker, 'backgroundColor', true)
      }

      if (typeof this.myCompany.themes.dark === 'undefined') {
        Vue.set(this.myCompany.themes, 'dark', true)
      }

      if (!this.myCompany.themes.general.mkpBackgroundImage) {
        Vue.set(this.myCompany.themes.general, 'mkpBackgroundImage', null)
      }

      this.mkpBackgroundImageData =
        this.myCompany.themes.general.mkpBackgroundImage || null

      this.mkpBackgroundImageData = this.mkpBackgroundImageData
        ? [this.mkpBackgroundImageData]
        : []

      if (typeof this.myCompany.booker.backgroundRepeat === 'undefined') {
        Vue.set(this.myCompany.booker, 'backgroundRepeat', false)
      }
    },
    checkColors() {
      const currentColors = {}
      this.allColors.forEach((name) => {
        currentColors[name] = this.myCompany.themes.general[name]
      })

      let findOne = false
      this.colorsTemplate.forEach((colors) => {
        const colorsToCompare = {}

        this.allColors.forEach((name) => {
          colorsToCompare[name] = colors[name]
        })

        if (_.isEqual(currentColors, colorsToCompare)) {
          this.myCompany.themes.general.selectedColorTemplate = colors.id
          findOne = true
        }
      })

      if (!findOne) {
        this.myCompany.themes.general.selectedColorTemplate = null
      }
    },
    setColors(colors) {
      this.myCompany.themes.general.selectedColorTemplate = colors.id

      this.allColors.forEach((name) => {
        this.myCompany.themes.general[name] = colors[name]
      })

      this.myCompany.themes.dark = colors.dark
    },

    changeBackground() {
      const color = this.myCompany.themes.general.mkpBackgroundColor
      this.myCompany.themes.general.backgroundColorEvents = color
      this.myCompany.themes.general.datesEvents = color
      this.myCompany.themes.general.closeBtnEvents = color

      this.myCompany.themes.general.pageEvents = this.getColor(color)
      this.myCompany.themes.general.bodyEvents = this.getColor(color)
      this.myCompany.themes.dark = this.lightOrDark(color) === 'light'
    },
    changeHeader() {
      const color = this.myCompany.themes.general.headerColor
      this.myCompany.themes.general.menuColor = color
      this.myCompany.themes.general.footerColor = color
      this.myCompany.themes.general.bookingAccentColor = color
      this.myCompany.themes.general.buttonSolo = color
    },
    changeUnderHeader() {
      const color = this.myCompany.themes.general.colorUnderHeader
      this.myCompany.themes.general.bookingGeneralColor = color
      this.myCompany.themes.general.buttonOther = color

      this.myCompany.themes.general.accentColor = this.getColor(color)
    },
    changeHeadingsBackgroundColor() {},
    changeButtons() {
      const color = this.myCompany.themes.general.buttonsColor
      this.myCompany.themes.general.linkBtnEvents = color
      this.myCompany.themes.general.buttonDuo = color
    },

    getColor(background) {
      if (this.lightOrDark(background) === 'light') {
        return '#000000'
      } else {
        return '#FFFFFF'
      }
    },
    lightOrDark(color) {
      let r, g, b
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        )

        r = color[1]
        g = color[2]
        b = color[3]
      } else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +(
          '0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&')
        )

        r = color >> 16
        g = (color >> 8) & 255
        b = color & 255
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

      // Using the HSP value, determine whether the color is light or dark
      if (hsp > 135) {
        return 'light'
      } else {
        return 'dark'
      }
    },
  },
  watch: {
    'myCompany.themes.general': {
      handler: function () {
        this.checkColors()
      },
      deep: true,
    },
    mkpBackgroundImageData() {
      this.$emit('changeMkpBackgroundImageData', this.mkpBackgroundImageData)
    },
  },
}
</script>

<style scoped>
.swatch {
  width: 100%;
  height: 22px;
  border: 1px solid #666;
  border-radius: 2px;
  cursor: pointer;
}

.pastille {
  border: 2px solid #333333;
  border-radius: 100px;
  cursor: pointer;
  height: 30px;
  width: 30px;
}

.pastilleLight {
  border: 1px solid #333333;
  height: 15px;
  width: 40px;
}

.onHeight-enter-active {
  animation: height-up 0.5s;
}

@keyframes height-up {
  0% {
    opacity: 0;
    height: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    height: 432px;
  }
}

.onHeight-leave-active {
  animation: height-down 0.5s;
}

@keyframes height-down {
  0% {
    opacity: 1;
    height: 432px;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}
</style>
