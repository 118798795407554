<template>
  <v-card v-if="linkArticlesShop">
    <v-card-title class="cardTitle">
      {{ $t('shops.shopRelatedItems') }} :
      <span class="font-weight-bold ml-5">
        {{ linkArticlesShop.name }}
      </span>
    </v-card-title>
    <v-divider />

    <v-card-text>
      <div class="d-flex align-center mb-5" style="column-gap: 10px">
        <v-select
          v-model="selectedStatus"
          :items="tabStatus"
          :label="$t('database.tables.status')"
          background-color="white"
          outlined
          hide-details
          dense
          style="width: 175px"
          class="customWidth"
          @change="search"
        >
          <template v-slot:selection>
            <div class="d-flex align-center">
              <v-icon
                :color="
                  selectedStatus === 'all'
                    ? 'black'
                    : selectedStatus === 'onLine'
                    ? 'green'
                    : 'red'
                "
              >
                mdi-checkbox-blank-circle
              </v-icon>
              <span class="ml-2" style="white-space: nowrap">
                {{ $icones[selectedStatus].title }}
              </span>
            </div>
          </template>
          <template v-slot:item="{ item }">
            <v-icon
              :color="
                item === 'all' ? 'black' : item === 'onLine' ? 'green' : 'red'
              "
            >
              mdi-checkbox-blank-circle
            </v-icon>
            <span class="ml-2">{{ $icones[item].title }}</span>
          </template>
        </v-select>

        <v-text-field
          v-model="selectedName"
          :label="$t('other.name')"
          background-color="white"
          outlined
          hide-details
          dense
          @keypress.enter="search"
        />

        <v-text-field
          v-model="selectedPrice"
          :label="$t('other.price')"
          background-color="white"
          outlined
          hide-details
          dense
          @keypress.enter="search"
        />

        <v-text-field
          v-model="selectedDuration"
          :label="$t('database.duration')"
          background-color="white"
          outlined
          hide-details
          dense
          @keypress.enter="search"
        />

        <v-btn dark @click="search">{{ $t('other.research') }}</v-btn>
      </div>

      <v-row no-gutters>
        <v-col cols="6" class="text-center">
          <v-btn
            class="px-15"
            color="primary"
            @click="linkAllArticles"
            elevation="2"
          >
            {{ $t('other.all') }}
          </v-btn>
        </v-col>
        <v-col cols="6" class="text-center">
          <v-btn
            class="px-15"
            color="primary"
            @click="unlinkAllArticles"
            elevation="2"
          >
            {{ $t('other.none') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-card class="ma-6">
        <v-card>
          <v-data-table
            :items="articles"
            :headers="articlesHeaders"
            group-by="family"
            hide-default-footer
            fixed-header
            height="55vh"
            :items-per-page="itemsPerPage"
            :page.sync="pagination"
            @page-count="pageCount = $event"
          >
            <template v-slot:item.status="{ item }">
              <v-icon
                v-if="!isOnline(item)"
                @click="publishArticle(item)"
                color="red"
              >
                mdi-checkbox-blank-circle
              </v-icon>
              <v-icon v-else @click="unpublishArticle(item)" color="green">
                mdi-checkbox-blank-circle
              </v-icon>
            </template>
            <template v-slot:item.name="{ item }">
              {{ controli18n($i18n.locale, item.name) }}
            </template>
            <template v-slot:item.prices="{ item }">
              {{ parseFloat(item.prices).toFixed(2) }}€
            </template>
            <template v-slot:item.booker.duration="{ item }">
              {{ item.booker.duration }}min
            </template>
            <template
              v-slot:[`group.header`]="{ group, toggle, isOpen, items }"
            >
              <td :colspan="articlesHeaders.length + 1" class="grey darken-2">
                <div class="d-inline">
                  <v-btn icon @click="toggle">
                    <v-icon color="white" v-if="isOpen">mdi-chevron-up</v-icon>
                    <v-icon color="white" v-if="!isOpen">
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                  <span
                    v-if="items.length === 1"
                    style="color: white; font-weight: bold"
                  >
                    {{ group ? group : $t('settings.colors.others') }}
                    -
                    {{ items.length }}
                    {{ $t('database.articles') }}
                  </span>
                  <span v-else style="color: white; font-weight: bold">
                    {{ group ? group : $t('settings.colors.others') }} -
                    {{ items.length }}
                    {{ $t('database.articles') }}
                  </span>
                </div>
                <v-divider v-if="!isOpen" dark></v-divider>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-card>

      <div class="d-flex justify-center">
        <v-pagination
          v-if="pageCount > 1"
          v-model="pagination"
          :length="pageCount"
        />
      </div>
      <div class="d-flex justify-center mt-2">
        <div>
          <v-select
            v-model="itemsPerPage"
            :items="selectAbItemsPerPage"
            background-color="white"
            outlined
            hide-details
            dense
            class="customWidth"
          />
        </div>
      </div>

      <v-overlay
        class="d-flex justify-center align-center"
        v-if="loading"
        absolute
      >
        <v-progress-circular indeterminate />
      </v-overlay>
    </v-card-text>

    <v-divider />
    <v-card-actions>
      <v-btn text @click="$emit('close')">
        {{ $t('other.close') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { controli18n } from '@/helpers/langs'
import icones from '@/helpers/icones'

export default {
  name: 'linkArticlesToShop',
  props: {
    linkArticlesShop: {
      type: Object,
      default: () => {}
    },
    onlineArticles: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    articles: [],

    tabStatus: ['all', 'onLine', 'offLine'],
    selectedStatus: 'all',
    selectedName: null,
    selectedPrice: null,
    selectedDuration: null,

    itemsPerPage: 10,
    pagination: 1,
    pageCount: 0
  }),
  computed: {
    articlesHeaders() {
      return [
        {
          text: this.$t('database.tables.status'),
          value: 'status',
          align: 'center',
          divider: true,
          sortable: false
        },
        {
          text: this.$t('other.name'),
          value: 'name',
          align: 'start',
          divider: true,
          sortable: false
        },
        {
          text: this.$t('other.price'),
          value: 'prices',
          align: 'center',
          divider: true,
          sortable: true
        },
        {
          text: this.$t('database.duration'),
          value: 'booker.duration',
          align: 'center',
          divider: true,
          sortable: true
        }
      ]
    },
    selectAbItemsPerPage() {
      return [
        10,
        25,
        50,
        100,
        {
          text: this.$t('other.all'),
          value: -1
        }
      ]
    },
    $icones() {
      return icones().getIcones()
    }
  },
  methods: {
    controli18n,
    isOnline(art) {
      let bool = false
      this.linkArticlesShop.articles.map(article => {
        if (article.artebeauteId === art.artebeauteId) {
          bool = true
        }
      })
      return bool
    },
    publishArticle(art) {
      this.$emit('publish', art)
    },
    unpublishArticle(art) {
      this.$emit('unpublish', art)
    },
    linkAllArticles() {
      this.$emit('publishAll')
    },
    unlinkAllArticles() {
      this.$emit('unpublishAll')
    },
    search() {
      let articles = this.onlineArticles

      if (this.selectedStatus === 'onLine') {
        const articlesToKeep = []
        articles.forEach(art => {
          if (this.isOnline(art)) {
            articlesToKeep.push(art)
          }
        })

        articles = articles.filter(art => articlesToKeep.includes(art))
      } else if (this.selectedStatus === 'offLine') {
        const articlesToKeep = []
        articles.forEach(art => {
          if (!this.isOnline(art)) {
            articlesToKeep.push(art)
          }
        })

        articles = articles.filter(art => articlesToKeep.includes(art))
      }

      if (this.selectedName) {
        articles = articles.filter(art =>
          this.controli18n(this.$i18n.locale, art.name)
            .toUpperCase()
            .includes(this.selectedName.toUpperCase())
        )
      }

      if (this.selectedPrice) {
        articles = articles.filter(art =>
          art.prices.toString().includes(this.selectedPrice)
        )
      }

      if (this.selectedDuration) {
        articles = articles.filter(art =>
          art.booker.duration.toString().includes(this.selectedDuration)
        )
      }

      this.articles = articles
    }
  },
  mounted() {
    this.articles = this.onlineArticles
  },
  watch: {
    onlineArticles(articles) {
      this.articles = articles
      this.search()
    }
  }
}
</script>

<style scoped></style>
