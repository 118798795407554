<template>
  <div>
    <v-card
      v-for="social in Object.keys(myCompany.identity.socialNetworks)"
      :key="social.objectId"
      class="my-5 pa-3"
    >
      <v-row align="stretch">
        <v-col cols="9" md="9 h4-content mt-0">
          <h4 class="d-flex align-center">
            <v-icon size="40" class="mr-3" :color="colors[social]">
              mdi-{{ social.toLowerCase() }}
            </v-icon>
            {{ social[0].toUpperCase() + social.slice(1) }}
          </h4>
          <span />
        </v-col>
        <v-col cols="3" md="3" align-self="center" class="d-flex justify-end">
          <v-switch
            v-model="myCompany.identity.socialNetworks[social].active"
            :label="
              $vuetify.breakpoint.mdAndUp
                ? $t('settings.connectionSettings.activate')
                : ''
            "
          />
        </v-col>
        <v-col cols="12" align-self="center">
          <v-text-field
            v-model="myCompany.identity.socialNetworks[social].url"
            label="Url"
            outlined
            :class="{
              empty:
                myCompany.identity.socialNetworks[social].active &&
                !myCompany.identity.socialNetworks[social].url
            }"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'social',
  props: {
    myCompany: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      colors: {
        facebook: '#4867AA',
        google: '#FABB05',
        youtube: '#FE0002',
        instagram: '#914BC7',
        linkedIn: '#0274B3'
      }
    }
  },
  created() {
    this.initSocial()
  },
  methods: {
    initSocial() {
      if (!this.myCompany.identity) {
        Vue.set(this.myCompany, 'identity', {})
      }

      if (!this.myCompany.identity.socialNetworks) {
        Vue.set(this.myCompany.identity, 'socialNetworks', {})
      }

      if (!this.myCompany.identity.socialNetworks.facebook) {
        Vue.set(this.myCompany.identity.socialNetworks, 'facebook', {
          active: false,
          url: null
        })
      }

      if (!this.myCompany.identity.socialNetworks.google) {
        Vue.set(this.myCompany.identity.socialNetworks, 'google', {
          active: false,
          url: null
        })
      }

      if (!this.myCompany.identity.socialNetworks.youtube) {
        Vue.set(this.myCompany.identity.socialNetworks, 'youtube', {
          active: false,
          url: null
        })
      }

      if (!this.myCompany.identity.socialNetworks.instagram) {
        Vue.set(this.myCompany.identity.socialNetworks, 'instagram', {
          active: false,
          url: null
        })
      }

      if (!this.myCompany.identity.socialNetworks.linkedIn) {
        Vue.set(this.myCompany.identity.socialNetworks, 'linkedIn', {
          active: false,
          url: null
        })
      }
    }
  }
}
</script>

<style scoped></style>
