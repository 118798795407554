<template>
  <div>
    <div v-if="coordonate.lat && coordonate.lng" class="mymap">
      <l-map :zoom="zoom" :center="center"
        ><l-tile-layer :url="url"></l-tile-layer>
        <l-marker :lat-lng="center">
          <l-tooltip
            v-if="myTooltip"
            :options="{ permanent: tooltipPermanent, interactive: true }"
          >
            <div>
              <slot name="toolTipInfo" />
            </div>
          </l-tooltip> </l-marker
      ></l-map>
    </div>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LTooltip } from 'vue2-leaflet'
import { Icon } from 'leaflet'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
  name: 'mapFree',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip
  },
  props: {
    tooltip: { type: Boolean, default: false },
    tooltipPermanent: { type: Boolean, default: false },
    coordonate: {
      type: Object,
      default: () => {
        return {
          lat: 0,
          lng: 0
        }
      }
    }
  },
  data: () => {
    return {
      map: null,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      center: [0, 0],
      markerLatLng: [0, 0],
      mycoordonate: { lattitude: 0, longitude: 0 },
      currentZoom: 11.5,
      myTooltip: false,
      zoom: 17
    }
  },
  watch: {
    coordonate: {
      handler: function(val) {
        console.log('val', val)
        this.center = [val.lat, val.lng]
        this.markerLatLng = [val.lat, val.lng]
      },
      deep: true
    },
    tooltip: {
      handler: function(val) {
        this.myTooltip = val
      },
      deep: true
    }
  },
  mounted() {
    this.center = [this.coordonate.lat, this.coordonate.lng]
    this.markerLatLng = [this.coordonate.lat, this.coordonate.lng]
    this.myTooltip = this.tooltip
    console.log(this.coordonate)
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove()
    }
  },

  methods: {
    initMap() {
      /*  if (this.map) {
        this.map.remove();
      }
      this.map = leaft
        .map("mapId")
        .setView([this.coordonate.lattitude, this.coordonate.longitude], 17);
      leaft
        .tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        })
        .addTo(this.map); */
    }
  }
}
</script>

<style>
.mymap {
  height: 200px;
  width: 100%;
}
.mapAction {
  position: absolute;
  z-index: 10000;
  margin-top: 2px;
  margin-left: 2px;
}
</style>
