<template>
  <div>
    <div class="text-center mb-2">
      {{ $t('settings.refundManagement.configureRefundType') }}
    </div>

    <v-row align="stretch">
      <v-col cols="12 h4-content mt-0">
        <h4>{{ $t('settings.refundManagement.newScenario') }}</h4>
        <span />
      </v-col>
      <v-col cols="12">
        <v-row class="mt-2" v-if="newScenarioStep === 'choose'" dense>
          <v-col cols="12" sm="4" class="text-center">
            <v-btn color="primary" @click="chooseScenarioType('same')">
              {{ $t('settings.refundManagement.theSameDay') }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4" class="text-center">
            <v-btn color="primary" @click="chooseScenarioType('before')">
              {{ $t('settings.refundManagement.beforeXHours') }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4" class="text-center">
            <v-btn color="primary" @click="chooseScenarioType('after')">
              {{ $t('settings.refundManagement.afterXHours') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="mt-3" v-if="newScenarioStep === 'define'">
          <v-col cols="12">
            <v-btn type="button" @click="newScenarioStep = 'choose'" text small>
              <v-icon left> mdi-arrow-left </v-icon>
              {{ $t('other.back') }}
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-sheet color="grey" class="px-2 white--text">
              <strong>{{ $t('settings.refundManagement.refund') }}</strong>
            </v-sheet>
            <v-row class="mt-2">
              <v-col cols="4">
                <v-text-field
                  type="number"
                  step="0.01"
                  v-model="newScenario.refund.fixFees"
                  :label="$t('settings.refundManagement.fixedCosts')"
                  hide-details
                  outlined
                  append-icon="mdi-currency-eur"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  type="number"
                  step="0.01"
                  v-model="newScenario.refund.percentFees"
                  :label="$t('settings.refundManagement.percentageOfFees')"
                  hide-details
                  outlined
                  append-icon="mdi-percent"
                />
              </v-col>
              <v-col cols="4" class="d-flex align-center">
                <v-checkbox
                  v-model="newScenario.refund.maxFeesEnabled"
                  hide-details
                  class="mt-0"
                />
                <v-text-field
                  type="number"
                  step="0.01"
                  v-model="newScenario.refund.maxFees"
                  :label="$t('settings.refundManagement.maximumFees')"
                  hide-details
                  outlined
                  :disabled="!newScenario.refund.maxFeesEnabled"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" style="position: relative">
            <v-sheet color="grey" class="px-2 white--text">
              <strong>{{ $t('settings.refundManagement.credits') }}</strong>
            </v-sheet>
            <v-row class="mt-2">
              <v-col cols="4">
                <v-text-field
                  type="number"
                  step="0.01"
                  v-model="newScenario.credits.fixFees"
                  :label="$t('settings.refundManagement.fixedCosts')"
                  hide-details
                  outlined
                  append-icon="mdi-currency-eur"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  type="number"
                  step="0.01"
                  v-model="newScenario.credits.percentFees"
                  :label="$t('settings.refundManagement.percentageOfFees')"
                  hide-details
                  outlined
                  append-icon="mdi-percent"
                />
              </v-col>
              <v-col cols="4" class="d-flex align-center">
                <v-checkbox
                  v-model="newScenario.credits.maxFeesEnabled"
                  hide-details
                  class="mt-0"
                />
                <v-text-field
                  type="number"
                  step="0.01"
                  v-model="newScenario.credits.maxFees"
                  :label="$t('settings.refundManagement.maximumFees')"
                  hide-details
                  outlined
                  append-icon="mdi-currency-eur"
                  :disabled="!newScenario.credits.maxFeesEnabled"
                />
              </v-col>
            </v-row>
            <div
              style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(255, 255, 255, 0.75);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
            >
              <strong>{{ $t('settings.refundManagement.toComeUp') }}</strong>
            </div>
          </v-col>
          <v-col cols="12" class="d-flex align-center">
            <div v-if="newScenario.type === 'same'">
              {{ $t('settings.refundManagement.cancellationsSameDay') }}
            </div>
            <div
              v-else-if="newScenario.type === 'before'"
              class="d-flex align-center"
            >
              {{ $t('settings.refundManagement.cancellationsLessThan') }}
              <v-text-field
                type="number"
                step="1"
                v-model="newScenario.hours"
                hide-details
                outlined
                class="mx-3"
                dense
                style="width: 80px"
              />
              {{ $t('settings.refundManagement.hoursBeforeAppointment') }}
            </div>
            <div
              v-else-if="newScenario.type === 'after'"
              class="d-flex align-center ml-2"
            >
              {{ $t('settings.refundManagement.cancellationsOver') }}
              <v-text-field
                type="number"
                step="1"
                v-model="newScenario.hours"
                hide-details
                outlined
                class="mx-3"
                dense
                style="width: 80px"
              />
              {{ $t('settings.refundManagement.hoursBeforeAppointment') }}
            </div>
          </v-col>
          <v-col cols="12">
            <v-btn
              type="button"
              @click="addScenario"
              block
              color="primary"
              class="elevation-0"
            >
              <v-icon left v-if="!editingScenario"> mdi-plus </v-icon>
              <v-icon left v-if="editingScenario"> mdi-pencil </v-icon>
              <span v-if="!editingScenario">
                {{ $t('other.add') }}
              </span>
              <span v-if="editingScenario">
                {{ $t('other.edit') }}
              </span>
            </v-btn>
            <v-btn
              type="button"
              @click="cancelEditingScenario"
              small
              v-if="editingScenario"
              block
              class="elevation-0"
            >
              {{ $t('settings.refundManagement.cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row align="stretch">
      <v-col cols="12 h4-content">
        <h4>
          {{ $t('settings.refundManagement.myScenarios') }}
        </h4>
        <span />
      </v-col>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t('settings.refundManagement.priority') }}
                </th>
                <th class="text-left">
                  {{ $t('settings.refundManagement.type') }}
                </th>
                <th class="text-left">
                  {{ $t('settings.refundManagement.refund') }}
                </th>
                <th class="text-left">
                  {{ $t('settings.refundManagement.credits') }}
                </th>
                <th class="text-left"></th>
              </tr>
            </thead>

            <draggable
              v-model="myCompany.booker.scenarios"
              group="scenarios"
              handle=".scenarios-handle"
              tag="tbody"
            >
              <tr
                v-for="(scenario, scenarioIndex) in myCompany.booker.scenarios"
                :key="scenario.id"
              >
                <td>
                  <v-icon class="scenarios-handle">
                    mdi-cursor-move
                  </v-icon>
                  {{ scenarioIndex + 1 }}
                </td>
                <td>
                  <span v-if="scenario.type === 'same'">
                    {{
                      $t('settings.refundManagement.theSameDayOfTheAppointment')
                    }}
                  </span>
                  <span v-else-if="scenario.type === 'before'">
                    {{
                      $t('settings.refundManagement.lessOf', {
                        hours: scenario.hours
                      })
                    }}
                  </span>
                  <span v-else-if="scenario.type === 'after'">
                    {{
                      $t('settings.refundManagement.moreThan', {
                        hours: scenario.hours
                      })
                    }}
                  </span>
                </td>
                <td>
                  <div>
                    <div>
                      {{
                        $t('settings.refundManagement.fixed', {
                          fees: scenario.refund.fixFees
                        })
                      }}
                    </div>
                    <div>
                      {{
                        $t('settings.refundManagement.fee', {
                          percentage: scenario.refund.percentFees
                        })
                      }}
                    </div>
                    <div>
                      <span v-if="scenario.refund.maxFeesEnabled">
                        {{
                          $t('settings.refundManagement.max', {
                            fee: scenario.refund.maxFees
                          })
                        }}
                      </span>
                      <span v-else>
                        {{ $t('settings.refundManagement.noMax') }}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      {{
                        $t('settings.refundManagement.fixed', {
                          fees: scenario.credits.fixFees
                        })
                      }}
                    </div>
                    <div>
                      {{
                        $t('settings.refundManagement.fee', {
                          percentage: scenario.credits.percentFees
                        })
                      }}
                    </div>
                    <span v-if="scenario.credits.maxFeesEnabled">
                      {{
                        $t('settings.refundManagement.max', {
                          fee: scenario.credits.maxFees
                        })
                      }}
                    </span>
                    <span v-else>
                      {{ $t('settings.refundManagement.noMax') }}
                    </span>
                  </div>
                </td>
                <td>
                  <v-btn icon @click="editScenario(scenario)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="removeScenario(scenario)" color="red">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </draggable>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row align="stretch">
      <v-col cols="12 h4-content">
        <h4 class="d-flex align-center">
          {{ $t('other.cancellationConditions') }}
          <span
            v-if="
              myCompany.booker &&
                myCompany.booker.textLegals &&
                !myCompany.booker.textLegals.canceling[$i18n.locale]
            "
            style="width: 15px; height: 15px"
            class="mt-0 ml-5 red rounded-circle"
          />
        </h4>
        <span
          :class="{
            red:
              myCompany.booker &&
              myCompany.booker.textLegals &&
              !myCompany.booker.textLegals.canceling[$i18n.locale]
          }"
        />
      </v-col>
      <v-col cols="12">
        <multilang-editor v-model="myCompany.booker.textLegals.canceling" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import draggable from 'vuedraggable'
import MultilangEditor from '../helpers/multilangEditor'

export default {
  name: 'remboursement',
  components: {
    MultilangEditor,
    draggable
  },
  props: {
    myCompany: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      newScenarioStep: 'choose',
      newScenario: {
        type: 'before',
        hours: 0,
        refund: {
          fixFees: 0,
          percentFees: 0,
          maxFees: 0,
          maxFeesEnabled: false
        },
        credits: {
          fixFees: 0,
          percentFees: 0,
          maxFees: 0,
          maxFeesEnabled: false
        }
      },
      editingScenario: null
    }
  },
  created() {
    this.initRemboursement()
  },
  methods: {
    initRemboursement() {
      if (!this.myCompany.booker) {
        Vue.set(this.myCompany, 'booker', {})
      }

      if (!this.myCompany.booker.scenarios) {
        this.$set(this.myCompany.booker, 'scenarios', [])
      }

      if (!this.myCompany.booker.textLegals) {
        Vue.set(this.myCompany.booker, 'textLegals', {})
      }

      if (!this.myCompany.booker.textLegals.canceling) {
        Vue.set(this.myCompany.booker.textLegals, 'canceling', {})
      }
    },
    chooseScenarioType(type) {
      this.newScenarioStep = 'define'

      this.newScenario = {
        type: type,
        hours: 0,
        refund: {
          fixFees: 0,
          percentFees: 0,
          maxFees: 0,
          maxFeesEnabled: false
        },
        credits: {
          fixFees: 0,
          percentFees: 0,
          maxFees: 0,
          maxFeesEnabled: false
        }
      }
    },
    addScenario() {
      if (this.newScenario.hours < 0) {
        return
      }
      if (this.newScenario.refund.fixFees < 0) {
        return
      }
      if (this.newScenario.refund.percentFees < 0) {
        return
      }
      if (this.newScenario.refund.percentFees > 100) {
        return
      }
      if (this.newScenario.refund.maxFees < 0) {
        return
      }
      if (this.newScenario.credits.fixFees < 0) {
        return
      }
      if (this.newScenario.credits.percentFees < 0) {
        return
      }
      if (this.newScenario.credits.percentFees > 100) {
        return
      }
      if (this.newScenario.credits.maxFees < 0) {
        return
      }

      if (this.editingScenario) {
        const scenarioIndex = this.myCompany.booker.scenarios.findIndex(
          s => s.id === this.editingScenario.id
        )

        this.$set(this.myCompany.booker.scenarios, scenarioIndex, {
          ...this.newScenario
        })

        this.editingScenario = null
      } else {
        this.myCompany.booker.scenarios.push({
          id: (Date.now().toString(32) + Math.random().toString(32)).substr(5),
          ...this.newScenario
        })
      }

      this.newScenario = {
        type: 'before',
        hours: 0,
        refund: {
          fixFees: 0,
          percentFees: 0,
          maxFees: 0,
          maxFeesEnabled: false
        },
        credits: {
          fixFees: 0,
          percentFees: 0,
          maxFees: 0,
          maxFeesEnabled: false
        }
      }
    },
    cancelEditingScenario() {
      this.editingScenario = null

      this.newScenario = {
        type: 'before',
        hours: 0,
        refund: {
          fixFees: 0,
          percentFees: 0,
          maxFees: 0,
          maxFeesEnabled: false
        },
        credits: {
          fixFees: 0,
          percentFees: 0,
          maxFees: 0,
          maxFeesEnabled: false
        }
      }
    },
    editScenario(scenario) {
      this.editingScenario = scenario

      this.newScenario = {
        ...scenario
      }
    },
    removeScenario(scenario) {
      this.myCompany.booker.scenarios = this.myCompany.booker.scenarios.filter(
        s => s.id !== scenario.id
      )
    }
  }
}
</script>

<style scoped></style>
