<template>
  <div>
    <div v-if="moduleEnabled">
      <v-row align="stretch">
        <v-col cols="12 h4-content mt-0">
          <h4>Clients VIP</h4>
          <span />
        </v-col>
        <v-col cols="12">
          <div>
            <v-switch
              inset
              v-model="
                myCompany.booker.modulesSettings.vip.customerVip.freeBooking
              "
              label="Peut réserver sans payer"
            />
          </div>
          <div>
            <v-switch
              inset
              v-model="
                myCompany.booker.modulesSettings.vip.customerVip
                  .employeeSelection
              "
              label="Peut choisir un prestataire"
            />
          </div>
          <div>
            <v-switch
              inset
              v-model="
                myCompany.booker.modulesSettings.vip.customerVip.showPrices
              "
              label="Afficher les tarifs"
              :disabled="
                !myCompany.booker.modulesSettings.vip.customerVip.freeBooking
              "
            />
          </div>
          <div>
            <MultilangEditor
              v-model="
                myCompany.booker.modulesSettings.vip.customerVip.customMessage
              "
              label="Message personnalisé"
              placeholder="Message personnalisé"
            />
          </div>
        </v-col>
      </v-row>

      <v-row align="stretch">
        <v-col cols="12 h4-content mt-0">
          <h4>Clients VIP Plus</h4>
          <span />
        </v-col>
        <v-col cols="12">
          <div>
            <div>
              <v-switch
                inset
                v-model="
                  myCompany.booker.modulesSettings.vip.customerVipPlus
                    .freeBooking
                "
                label="Peut réserver sans payer"
              />
            </div>
            <div>
              <v-switch
                inset
                v-model="
                  myCompany.booker.modulesSettings.vip.customerVipPlus
                    .employeeSelection
                "
                label="Peut choisir un prestataire"
              />
            </div>
            <div>
              <v-switch
                inset
                v-model="
                  myCompany.booker.modulesSettings.vip.customerVipPlus
                    .showPrices
                "
                label="Afficher les tarifs"
                :disabled="
                  !myCompany.booker.modulesSettings.vip.customerVipPlus
                    .freeBooking
                "
              />
            </div>
            <div>
              <MultilangEditor
                v-model="
                  myCompany.booker.modulesSettings.vip.customerVipPlus
                    .customMessage
                "
                label="Message personnalisé"
                placeholder="Message personnalisé"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <p>Ce module n'est pas activé sur votre compte.</p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { havePremiumModule, PREMIUM_MODULES } from '@/helpers/premiumModules'
import MultilangEditor from '@/components/helpers/multilangEditor'

export default {
  name: 'site',
  components: { MultilangEditor },
  props: {
    myCompany: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    moduleEnabled() {
      return havePremiumModule(this.myCompany, PREMIUM_MODULES.VIP)
    },
  },
  created() {
    this.initSite()
  },
  methods: {
    initSite() {
      if (typeof this.myCompany.booker === 'undefined') {
        Vue.set(this.myCompany, 'booker', {})
      }

      if (typeof this.myCompany.booker.modulesSettings === 'undefined') {
        Vue.set(this.myCompany.booker, 'modulesSettings', {})
      }

      if (typeof this.myCompany.booker.modulesSettings.vip === 'undefined') {
        Vue.set(this.myCompany.booker.modulesSettings, 'vip', {})
      }

      if (
        typeof this.myCompany.booker.modulesSettings.vip.customerVip ===
        'undefined'
      ) {
        Vue.set(this.myCompany.booker.modulesSettings.vip, 'customerVip', {})
      }

      if (
        typeof this.myCompany.booker.modulesSettings.vip.customerVip
          .freeBooking === 'undefined'
      ) {
        Vue.set(
          this.myCompany.booker.modulesSettings.vip.customerVip,
          'freeBooking',
          false
        )
      }

      if (
        typeof this.myCompany.booker.modulesSettings.vip.customerVip
          .employeeSelection === 'undefined'
      ) {
        Vue.set(
          this.myCompany.booker.modulesSettings.vip.customerVip,
          'employeeSelection',
          false
        )
      }

      if (
        typeof this.myCompany.booker.modulesSettings.vip.customerVip
          .showPrices === 'undefined'
      ) {
        Vue.set(
          this.myCompany.booker.modulesSettings.vip.customerVip,
          'showPrices',
          true
        )
      }

      if (
        typeof this.myCompany.booker.modulesSettings.vip.customerVip
          .customMessage === 'undefined'
      ) {
        Vue.set(
          this.myCompany.booker.modulesSettings.vip.customerVip,
          'customMessage',
          ''
        )
      }

      if (
        typeof this.myCompany.booker.modulesSettings.vip.customerVipPlus ===
        'undefined'
      ) {
        Vue.set(
          this.myCompany.booker.modulesSettings.vip,
          'customerVipPlus',
          {}
        )
      }

      if (
        typeof this.myCompany.booker.modulesSettings.vip.customerVipPlus
          .freeBooking === 'undefined'
      ) {
        Vue.set(
          this.myCompany.booker.modulesSettings.vip.customerVipPlus,
          'freeBooking',
          false
        )
      }

      if (
        typeof this.myCompany.booker.modulesSettings.vip.customerVipPlus
          .employeeSelection === 'undefined'
      ) {
        Vue.set(
          this.myCompany.booker.modulesSettings.vip.customerVipPlus,
          'employeeSelection',
          false
        )
      }

      if (
        typeof this.myCompany.booker.modulesSettings.vip.customerVipPlus
          .showPrices === 'undefined'
      ) {
        Vue.set(
          this.myCompany.booker.modulesSettings.vip.customerVipPlus,
          'showPrices',
          true
        )
      }

      if (
        typeof this.myCompany.booker.modulesSettings.vip.customerVipPlus
          .customMessage === 'undefined'
      ) {
        Vue.set(
          this.myCompany.booker.modulesSettings.vip.customerVipPlus,
          'customMessage',
          ''
        )
      }
    },
  },
  watch: {
    'myCompany.booker.modulesSettings.vip.customerVip.freeBooking'() {
      if (!this.myCompany.booker.modulesSettings.vip.customerVip.freeBooking) {
        this.myCompany.booker.modulesSettings.vip.customerVip.showPrices = true
      }
    },
    'myCompany.booker.modulesSettings.vip.customerVipPlus.freeBooking'() {
      if (
        !this.myCompany.booker.modulesSettings.vip.customerVipPlus.freeBooking
      ) {
        this.myCompany.booker.modulesSettings.vip.customerVipPlus.showPrices = true
      }
    },
  },
}
</script>

<style scoped>
.notSelectedTab {
  background-color: #cbba95aa !important;
}
.notSelectedTab:hover {
  background-color: #cbba95 !important;
}
</style>
