import axios from 'axios'

const findCoordinatesByAdress = async (country, adress) => {
  const apisCountry = { fr: 'https://api-adresse.data.gouv.fr/search/' }
  if (apisCountry[country]) {
    const request = apisCountry[country]
    const resp = await axios.get(request, {
      params: { q: adress },
      transformRequest: (data, headers) => {
        delete headers.company
        delete headers.authorization
      }
    })
    if (resp.data.features) {
      return resp.data.features
    }
    return null
  } else {
    return '-2'
  }
}
export default {
  findCoordinatesByAdress
}
