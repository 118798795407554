export default {
  monoShop: {
    name: 'MonoShop',
    themes: [
      {
        id: 'default',
        name: 'Thème par défaut',
      },
      {
        id: 'grid',
        name: 'Thème grid',
      },
    ],
  },
  mkpShop: {
    name: 'MkpShop',
    themes: [
      {
        id: 'default',
        name: 'Thème par défaut',
      },
    ],
  },
  categories: {
    name: 'Catégories',
    themes: [
      {
        id: 'default',
        name: 'Thème par défaut',
      },
      {
        id: 'cards',
        name: 'Thème cartes',
      },
    ],
  },
  articles: {
    name: 'Articles',
    themes: [
      {
        id: 'default',
        name: 'Thème par défaut',
      },
      {
        id: 'cards',
        name: 'Thème cartes',
      },
      {
        id: 'text',
        name: 'Thème texte',
      },
      {
        id: 'table',
        name: 'Thème tableau',
      },
    ],
  },
  shop: {
    name: 'Boutique',
    themes: [
      {
        id: 'default',
        name: 'Thème par défaut',
      },
      {
        id: 'large',
        name: 'Thème secondaire',
      },
    ],
  },
  gift: {
    name: 'Bons Cadeaux',
    themes: [
      {
        id: 'default',
        name: 'Thème par défaut',
      },
      {
        id: 'onePage',
        name: 'Thème secondaire',
      },
      // {
      //   id: 'cards',
      //   name: 'Thème cards'
      // }
    ],
  },
  services: {
    name: 'Services',
    themes: [
      {
        id: 'default',
        name: 'Thème par défaut',
      },
      {
        id: 'cards',
        name: 'Thème cartes',
      },
    ],
  },
  contact: {
    name: 'Contact',
    themes: [
      {
        id: 'default',
        name: 'Thème par défaut',
      },
      {
        id: 'landscape',
        name: 'Thème horizontal',
      },
    ],
  },
  multiShop: {
    name: 'Multishop',
    themes: [
      {
        id: 'default',
        name: 'Thème par défaut',
      },
      {
        id: 'grid',
        name: 'Thème grid',
      },
      {
        id: 'grid2',
        name: 'Thème grid 2 ',
      },
    ],
  },
  servicePresentation: {
    name: 'Presentation Service',
    themes: [
      {
        id: 'default',
        name: 'Thème par défaut',
      },
      {
        id: 'whole',
        name: 'Thème image entière',
      },
    ],
  },
}
