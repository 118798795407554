<template>
  <div>
    <v-row>
      <v-col cols="12" class="st-section-title">
        <img src="/assets/images/icons/coupons.png" class="st-section-image" />
        Popups de réduction
      </v-col>
      <v-col cols="12">
        <v-switch
          v-model="myCompany.booker.couponsPopupHome"
          inset
          label="Activer les popups de réduction"
        />
      </v-col>
      <v-col cols="12" v-show="myCompany.booker.couponsPopupHome">
        <v-row>
          <v-col cols="12" class="st-subsection-title"> Textes </v-col>
          <v-col cols="12">
            <multilang-editor v-model="myCompany.booker.couponsPopupText" />
          </v-col>
          <v-col cols="12" class="st-subsection-title"> Images </v-col>
          <v-col cols="12">
            <images-selector
              v-model="popupImg"
              single
              :size="[300, 750]"
              title="Illustration"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-8">
      <v-col cols="12" class="st-section-title">
        <img
          src="/assets/images/icons/optinmonster.png"
          class="st-section-image"
        />
        Optin-Monster
      </v-col>
      <v-col cols="12">
        <v-switch
          v-model="myCompany.booker.optinMonsterEnabled"
          inset
          label="Activer Optin-Monster"
        />
      </v-col>
      <v-col cols="12" v-show="myCompany.booker.optinMonsterEnabled">
        <v-row>
          <v-col cols="12" class="st-subsection-title"> Informations </v-col>
          <v-col cols="12">
            <v-alert color="blue lighten-3">
              Vous avez activé cette option. Les Popups concernant les
              promotions sont donc désactivés.
            </v-alert>
          </v-col>
          <v-col cols="12" class="st-subsection-title"> Intégration </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="myCompany.booker.optinMonsterCode"
              outlined
              label="Code Optin-Monster"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import ImagesSelector from '../helpers/imagesSelector'
import MultilangEditor from '@/components/helpers/multilangEditor'

export default {
  name: 'site',
  components: {
    MultilangEditor,
    ImagesSelector,
  },
  props: {
    myCompany: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      popupImg: [],
    }
  },
  created() {
    this.initSite()
  },
  methods: {
    initSite() {
      if (!this.myCompany.booker) {
        Vue.set(this.myCompany, 'booker', {})
      }

      if (typeof this.myCompany.booker.couponsPopupHome === 'undefined') {
        Vue.set(this.myCompany.booker, 'couponsPopupHome', false)
      }

      if (typeof this.myCompany.booker.couponsPopupText === 'undefined') {
        Vue.set(this.myCompany.booker, 'couponsPopupText', {})
      }

      if (typeof this.myCompany.booker.couponsPopupText === 'string') {
        Vue.set(this.myCompany.booker, 'couponsPopupText', {})
      }

      if (typeof this.myCompany.booker.optinMonsterEnabled === 'undefined') {
        Vue.set(this.myCompany.booker, 'optinMonsterEnabled', false)
      }

      if (typeof this.myCompany.booker.optinMonsterCode === 'undefined') {
        Vue.set(this.myCompany.booker, 'optinMonsterCode', '')
      }

      this.popupImg = this.myCompany.booker.popupImg || null
      this.popupImg = this.popupImg ? [this.popupImg] : []
    },
  },
  watch: {
    popupImg() {
      this.$emit('changePopupImg', this.popupImg)
    },
  },
}
</script>

<style scoped>
.notSelectedTab {
  background-color: #cbba95aa !important;
}
.notSelectedTab:hover {
  background-color: #cbba95 !important;
}
</style>
