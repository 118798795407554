const autocomplete = async (search) => {
  const response = await fetch(
    'https://api.positionstack.com/v1/forward?access_key=' +
      process.env.VUE_APP_GEOCODING_API +
      '&query=' +
      search
  )
  const data = await response.json()

  return data.data
}

export default {
  autocomplete,
}
